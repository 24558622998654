import * as angular from 'angular';
import * as moment from 'moment';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class CopyWeatherDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  public copyWeatherForm: angular.IFormController;

  public invalidSiteIds: number[] = [];
  private siteId: number;
  public siteIds: number[] = [];
  public step: number = 0;
  public recalculateDate: Date;
  public confirmation: boolean = false;
  public minDate: Date;

  private steps = ['AC.SETTINGS.NOMINATE_TARGET_SITES', 'AC.SETTINGS.REPLACE_SITE_WEATHER_PREF'];

  public todaysDate: Date;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    siteId: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.siteId = siteId;

    this.invalidSiteIds.push(siteId);
    this.recalculateDate = new Date();
    this.todaysDate = new Date();
    this.minDate = moment(this.todaysDate).subtract(6, 'months').toDate();
  }

  public copyWeather() {
    const settings = {
      recalculateDate: this.recalculateDate,
      sourceSiteId: this.siteId,
      targetSiteIds: this.siteIds,
    } as fuse.replaceWeatherPreferenceDto;
    this._mdDialog.hide(settings);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public setStep(step: number) {
    this.step = step;
    if (step == 1) {
      this.confirmation = false;
    }
  }

  public stepDescription() {
    return this.steps[this.step];
  }
}

angular.module('app.account').controller('CopyWeatherDialogController', CopyWeatherDialogController);
