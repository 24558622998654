import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { SWANConstants } from '@common/SWANConstants';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';

export class AddAlgorithmDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;

  public algorithms: fuse.algorithmDto[];

  public filterDates: (date: Date) => boolean;
  public minDate = SWANConstants.MinDate;
  public fromDate: Date;
  public valueFunctions = SWANConstants.AlgorithmValueFunctions;
  public algorithmId: number;
  public valueFunction: string;
  public resultMultiplier = 1;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    DayNumberService: DayNumberService,
    algorithms: fuse.algorithmDto[],
    siteAlgorithms: fuse.siteAlgorithmDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;

    this.algorithms = algorithms;

    this.filterDates = (date: Date) => {
      return siteAlgorithms.every((a) => a.localeDate.getTime() != date.getTime());
    };
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public addAlgorithm() {
    const newAlgorithm = {
      algorithmId: this.algorithmId,
      algorithmName: this.algorithms.find((a) => a.id == this.algorithmId).name,
      localeDate: this.fromDate,
      dayNumber: this._dayNumberService.convertCalendarDateToLocaleDayNumber(this.fromDate),
      valueFunction: this.valueFunction,
      resultMultiplier: this.resultMultiplier,
    } as fuse.siteAlgorithmDto;
    this._mdDialog.hide({ newAlgorithm: newAlgorithm });
  }
}

angular.module('app.account').controller('AddAlgorithmDialogController', AddAlgorithmDialogController);
