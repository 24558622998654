// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add-weather-station {
  width: 80%;
  max-width: 1200px;
}
#add-weather-station md-dialog-content {
  padding: 0;
}
#add-weather-station md-input-container {
  margin: 0;
  padding: 0;
}
#add-weather-station label {
  font-weight: bold;
  padding-right: 4px;
  padding-left: 8px;
}
#add-weather-station md-checkbox {
  margin-bottom: 0;
}
#add-weather-station table thead tr {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
#add-weather-station table thead tr th {
  text-align: center;
  padding: 4px 8px;
}
#add-weather-station table thead tr th:first-child {
  text-align: left;
}
#add-weather-station table tbody tr td {
  text-align: center;
  padding: 4px;
}
#add-weather-station table tbody tr td:first-child {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/site/settingsTabs/weather/addWeatherStation-dialog.scss"],"names":[],"mappings":"AACA;EACI,UAAA;EACA,iBAAA;AAAJ;AACI;EACI,UAAA;AACR;AACI;EACI,SAAA;EACA,UAAA;AACR;AACI;EACI,iBAAA;EACA,kBAAA;EACA,iBAAA;AACR;AACI;EACI,gBAAA;AACR;AACI;EACI,YAAA;EACA,oCAAA;AACR;AACI;EACI,kBAAA;EACA,gBAAA;AACR;AACI;EACI,gBAAA;AACR;AACI;EACI,kBAAA;EACA,YAAA;AACR;AACI;EACI,gBAAA;AACR","sourcesContent":["\r\n#add-weather-station {\r\n    width: 80%;\r\n    max-width: 1200px;\r\n    md-dialog-content {\r\n        padding: 0;\r\n    }\r\n    md-input-container {\r\n        margin: 0;\r\n        padding: 0;\r\n    }\r\n    label {\r\n        font-weight: bold;\r\n        padding-right: 4px;\r\n        padding-left: 8px;\r\n    }\r\n    md-checkbox {\r\n        margin-bottom: 0;\r\n    }\r\n    table thead tr {\r\n        color: white;\r\n        background-color: rgba(0, 0, 0, 0.5);\r\n    }\r\n    table thead tr th {\r\n        text-align: center;\r\n        padding: 4px 8px;\r\n    }\r\n    table thead tr th:first-child {\r\n        text-align: left;\r\n    }\r\n    table tbody tr td {\r\n        text-align: center;\r\n        padding: 4px;\r\n    }\r\n    table tbody tr td:first-child {\r\n        text-align: left;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
