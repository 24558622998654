import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { Nutrient } from '@common/nutrients.interface';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { Asset } from 'src/app/_DBContext/Asset';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { ReportService, mySiteDetails } from '@services/account/report.service';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

class SWANSiteNutrientReportBudgetVsActual implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    assetType: '<',
    assetName: '<',
    startDate: '<',
    endDate: '<',
    refreshReport: '<',
  };
  controller = SiteNutrientReportBudgetVsActualsController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/nutrients/reports.budgetvsactuals.component.html';
}

class SiteNutrientReportBudgetVsActualsController extends BaseController {
  public weightUnit: uomUnit;

  private noots: Nutrient[] = [];
  public newnoots: Nutrient[] = [];
  public myDetails: mySiteDetails[] = [];
  public mycsvDetails: any[] = [];
  public arrLogsDataCSVhdr: string[];
  private swanLogo: string;

  private startDate: Date;
  private endDate: Date;
  public titleNoots = [
    'NO3_FORMATTED',
    'OTHER_N',
    'TOTAL_N',
    'P',
    'K',
    'S',
    'Ca',
    'Mg',
    'Na',
    'Cl',
    'Cu',
    'Fe',
    'Mn',
    'Zn',
    'B',
    'Mo',
  ];

  public asset: Asset;
  public assetId: number;
  public assetName: string;
  public assetType: string;

  private _http: angular.IHttpService;
  private _languageService: LanguageService;
  private _reportService: ReportService;

  constructor(
    $scope: angular.IScope,
    $http: angular.IHttpService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    ReportService: ReportService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService
    );

    this._http = $http;
    this._languageService = LanguageService;
    this._reportService = ReportService;

    this.entityManager = DataEntityService.manager;
    this.weightUnit = UnitOfMeasureService.getUnits('Weight/Area', unitSizes.normal);
  }

  $onInit() {
    this._http.get('assets/images/logos/SWAN-Landscape-Logo-Colour.png', { responseType: 'blob' }).then((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.swanLogo = reader.result as string;
      };
      reader.readAsDataURL(res.data as Blob);
    });
  }

  $onChanges(changes) {
    if (changes.refreshReport?.currentValue) {
      this.refreshWeeklyFertilisers();
    }
  }

  public getNutrientWeightUnitDecimalPlace(nutrient_name: string) {
    return nutrient_name == 'Mo' ? 4 : this.weightUnit.decimalPlaces;
  }

  public refreshWeeklyFertilisers() {
    //Set up nutrient header categories
    const noots = [
      this._languageService.instant('NUTR.CHEM.NITRATE_AS_N'),
      this._languageService.instant('NUTR.CHEM.OTHER_N'),
      this._languageService.instant('NUTR.CHEM.TOTAL_N'),
      'P',
      'K',
      'S',
      'Ca',
      'Mg',
      'Na',
      'Cl',
      'Cu',
      'Fe',
      'Mn',
      'Zn',
      'B',
      'Mo',
    ];
    const cats = [
      this._languageService.instant('COMMON.BUDGETED'),
      this._languageService.instant('COMMON.APPLIED'),
      this._languageService.instant('NUTR.RPT.SURPLUS_SHORT'),
    ];
    this.arrLogsDataCSVhdr = ['Site'];
    noots.forEach((noot) => {
      cats.forEach((cat) => {
        const header = `${noot}/${cat}(${this.weightUnit.name})`;
        this.arrLogsDataCSVhdr.push(header);
      });
    });

    this.arrLogsDataCSVhdr = ['NUTRIENT', 'BUDGETED', 'ACTUAL', 'DIFFERENCE', 'PERCENTAGE'].map((t) =>
      this._languageService.instant('COMMON.' + t),
    );

    this.noots.length = 0;
    this.newnoots.length = 0;
    this.myDetails.length = 0;
    this.mycsvDetails.length = 0;
    //newnoots hold values for Group report.
    this._reportService.createReportStructure(this.newnoots);

    // shift the specified dates into the account time (TM: pls check against foriegn TZ's))
    const accountStartDate: Date = angular.copy(this.startDate);

    const accountEndDate: Date = angular.copy(this.endDate);

    const perm =
      this.assetType == 'group' ? ApplicationPrivileges.GroupNutrientReportsView : ApplicationPrivileges.SiteNutrientReportsView;

    this._reportService.generateBudgetVsActualReport(
      this.assetId,
      accountStartDate,
      accountEndDate,
      this.noots,
      this.newnoots,
      this.myDetails,
      this.mycsvDetails,
      'chartdivSiteBudgetActual',
      this.weightUnit,
    );
  }

  public exportReport(theChartDiv) {
    const charts = {};
    let charts_remaining = 1; //ids.length;

    const assetEntity = this.entityManager.getEntityByKey('Asset', this.assetId);
    const siteAsset = assetEntity as Asset;

    for (let i = 0; i < 1; i++) {
      for (let x = 0; x < (AmCharts as any).charts.length; x++) {
        if ((AmCharts as any).charts[x].div) {
          if ((AmCharts as any).charts[x].div.id == theChartDiv) charts[theChartDiv] = (AmCharts as any).charts[x];
        }
      }
    }
    // Trigger export of each chart
    for (const y in charts) {
      if (charts.hasOwnProperty(y)) {
        var chart = charts[y];
        chart['export'].capture({}, () => {
          chart.toJPG({}, (data) => {
            // Save chart data into chart object itself
            chart.setup.chart.exportedImage = data;

            // Reduce the remaining counter
            charts_remaining--;

            // Check if we got all of the charts
            if (charts_remaining == 0) {
              // Yup, we got all of them
              // Let's proceed to putting PDF together

              // Initiliaze a PDF layout
              const layout = {
                // a string or { width: number, height: number }
                pageSize: 'A4',
                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',
                content: [],
              };

              layout.content.push({
                image: this.swanLogo,
                width: 120,
              });

              layout.content.push({
                text: ' ',
                fontSize: 20,
              });

              layout.content.push({
                text: ' ',
                fontSize: 12,
              });

              layout.content.push({
                text: this._languageService.instant('NUTR.RPT.BUDGET_VS_ACTUAL_SITE', {
                  site: siteAsset.Name,
                  unit: this.weightUnit.name,
                }),
                fontSize: 14,
              });

              layout.content.push({
                text: ' ',
                fontSize: 12,
              });

              layout.content.push({
                text:
                  `${this._languageService.instant('COMMON.REPORT_FROM')} ` +
                  DateUtils.Locale.asDateDefault(this.startDate) +
                  ` ${this._languageService.instant('COMMON.TO')} ` +
                  DateUtils.Locale.asDateDefault(this.endDate),
                fontSize: 10,
              });

              layout.content.push({
                text: ' ',
                fontSize: 12,
              });

              layout.content.push({
                text: ' ',
                fontSize: 20,
              });

              layout.content.push({
                image: charts[theChartDiv].exportedImage,
                fit: [723, 400],
                pageBreak: 'after',
              });

              const body = [];
              const widths = [];
              let outputFilename = '';

              if (siteAsset.Name) {
                outputFilename = 'BudgetvsActual_' + siteAsset.Name + '.pdf';
              } else {
                outputFilename = 'BudgetvsActual_Site.pdf';
              }

              widths.push('auto', '7%', '7%', '7%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%');

              // change to water usage table structure
              const hdr = [];
              hdr.push(this._languageService.instant('COMMON.TYPE'));
              hdr.push('NO3_N');
              hdr.push(this._languageService.instant('NUTR.CHEM.OTHER_N'));
              hdr.push(this._languageService.instant('NUTR.CHEM.TOTAL_N'));
              hdr.push('P');
              hdr.push('K');
              hdr.push('S');
              hdr.push('Ca');
              hdr.push('Mg');
              hdr.push('Na');
              hdr.push('Cl');
              hdr.push('Cu');
              hdr.push('Fe');
              hdr.push('Mn');
              hdr.push('Zn');
              hdr.push('B');
              hdr.push('Mo');
              body.push(hdr);

              for (let i = 0; i < this.newnoots.length; i++) {
                const fila = [];
                if (i === 0) fila.push(this._languageService.instant('COMMON.BUDGETED'));
                else if (i === 1) fila.push(this._languageService.instant('COMMON.APPLIED'));
                else if (i === 2) fila.push(this._languageService.instant('COMMON.SURPLUS'));
                fila.push(this.newnoots[i].NO3_N);
                fila.push(this.newnoots[i].OtherN);
                fila.push(this.newnoots[i].TotalN);
                fila.push(this.newnoots[i].P);
                fila.push(this.newnoots[i].K);
                fila.push(this.newnoots[i].S);
                fila.push(this.newnoots[i].Ca);
                fila.push(this.newnoots[i].Mg);
                fila.push(this.newnoots[i].Na);
                fila.push(this.newnoots[i].Cl);
                fila.push(this.newnoots[i].Cu);
                fila.push(this.newnoots[i].Fe);
                fila.push(this.newnoots[i].Mn);
                fila.push(this.newnoots[i].Zn);
                fila.push(this.newnoots[i].B);
                fila.push(this.newnoots[i].Mo);

                body.push(fila);
              }

              layout.content.push({
                text: `${this._languageService.instant('NUTR.RPT.ALL_FIGURES_IN')} ${this.weightUnit.name}`,
                fontSize: 12,
              });

              layout.content.push({
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 1,
                  widths: widths,
                  body: body,
                },
              });

              // Trigger the generation and download of the PDF

              chart['export'].toPDF(layout, (data) => {
                chart.download(data, 'application/pdf', outputFilename);
              });
            }
          });
        });
      }
    }
  }
}

angular.module('app.account').component('swanSiteNutrientReportBudgetVsActual', new SWANSiteNutrientReportBudgetVsActual());
