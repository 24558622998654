import * as angular from 'angular';
import * as moment from 'moment';
import { Feature, FeatureCollection, GeoJSON, Polygon } from 'geojson';
import { AssetClassEnum } from '@indicina/swan-shared/enums/AssetClassEnum';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { GeometryTypeEnum } from '@indicina/swan-shared/enums/GeometryTypeEnum';
import { StatusEnum } from '@indicina/swan-shared/enums/StatusEnum';
import { IGoogleTimeZone } from '@indicina/swan-shared/interfaces/google/IGoogleTimeZone';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { GeoUtils } from '@indicina/swan-shared/utils/GeoUtils';
import { GoogleMapUtils } from '@indicina/swan-shared/utils/GoogleMapUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { NumberUtils } from '@indicina/swan-shared/utils/NumberUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { AssetUtils } from '@indicina/swan-shared/utils/DomainUtils/AssetUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { EntityList } from '@common/EntityList';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes, UnitTypes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { UiHelper } from '@common/helpers/UiHelper';
import { AccountSiteInfo, SiteSummaryModel } from '@common/models/ProviderPackets';
import { IDropListItem, IWaterChartParams, NutrientDashboard } from '@common/models/interfaces';
import { SiteConvertedWaterUsageConverter } from '@common/unitconverters/site-applied-converter';
import { SiteWaterConverter } from '@common/unitconverters/site-water-converter';
import { WeatherConverter } from '@common/unitconverters/weather-converter';
import { CropGraphDialogController } from './settingsTabs/crop/cropgraph-dialog.controller';
import { SiteRolloverDialogController } from './settingsTabs/crop/site-kcRollover-dialog';
import { SiteAreaDialogController } from './settingsTabs/sitearea-dialog';
import { SoilMoistureDialogController } from './waterTabs/soilmoisture-dialog.controller';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { GroupSiteService } from '@services/group-site.service';
import { InitialisationService } from '@services/initialisation.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { MapService } from '@services/map.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { SiteService } from '@services/account/site.service';
import { GroupSettingService } from '@services/account/group-setting.service';
import { IdName, SiteSettingService } from '@services/account/site-setting.service';
import { AccountsService } from '@services/administration/accounts.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController, PostSaveActions } from '../../../../base.controller';
import { Asset } from '../../../../_DBContext/Asset';
import { ObsWeatherRollup } from '../../../../_DBContext/ObsWeatherRollup';
import { Sensor } from '../../../../_DBContext/Sensor';
import { Site } from '../../../../_DBContext/Site';
import { SiteAsset } from '../../../../_DBContext/SiteAsset';
import { SiteDailySummary } from '../../../../_DBContext/SiteDailySummary';
import { SiteSettingBase } from '../../../../_DBContext/SiteSettingBase';
import { SiteSettingsCrop } from '../../../../_DBContext/SiteSettingsCrop';
import { SiteSettingsNutrients } from '../../../../_DBContext/SiteSettingsNutrients';
import { SiteSettingsSoil } from '../../../../_DBContext/SiteSettingsSoil';
import { SiteSettingsWater } from '../../../../_DBContext/SiteSettingsWater';
import { ValueRange } from '../../../../_DBContext/ValueRange';

interface ISiteProfile {
  name: string;
  id: number;
  lat: number;
  lng: number;
  groups: string[];
  waterusage: string;
  water_actual: number;
  water_budget: number;
  croptype: string;
  cropphase: string;
  area_irrigated: number;
  water_budget_start: string;
  water_budget_total: number;
  status: string;
}

enum PrimaryTabEnum {
  Summary,
  Map,
  Weather,
  Water,
  Nutrients,
  Equipment,
  Settings,
  Logs,
}
export class SiteController extends BaseController {
  public FormWaterBudget: angular.IFormController;
  public newSitePoly: boolean = false;
  public totalAreaSpecified: boolean = false;

  public siteAccountInfo: fuse.siteAccountInfoDto;
  private irrigationSeasonStart: Date;
  public siteIrrigationPlanInfo = {} as fuse.sitePlanProfileDto;
  public hasIrrigationPlan: boolean = null;
  public isScheduleApiEnabled = false;
  public assetClass: fuse.assetClassDto;

  private _siteDetails: SiteSummaryModel; //  = SiteDetails.data;
  // individual site detail information - ids the same as above

  public siteEquipments: EntityList<fuse.siteEquipmentDto>;
  public waterMeterCount: number = 0;

  public siteId: number;
  public site_: Site;
  public isSiteActive: boolean;
  public imuSiteList: ISiteProfile[];

  public searchTerm: string = '';
  public reloadCounter = 0;
  public nutrientAssetIds = [];

  public dateRangeFilter: Function;
  public waterBudget: fuse.siteBudgetSummaryDto;
  public waterUsageStatus: string;
  public waterUsageStartDate: Date;
  public waterUsageEndDate: Date;
  //  public waterBudgetId;
  public water_actual: number;
  public water_budget: number;

  public logLevels: IDropListItem[];

  public nutrientsTabEnabled = 0;

  /* Site Settings */
  public siteCrop: SiteSettingsCrop[] = [];
  public siteSoil: SiteSettingsSoil[] = [];
  public siteNoots: SiteSettingsNutrients[] = [];
  public siteWater: SiteSettingsWater[] = [];

  public siteCropIndex: number = 0;
  public siteSoilIndex: number = 0;
  public siteNootsIndex: number = 0;
  public siteWaterIndex: number = 0;
  public siteCropCoeffIndex: number = 0;

  public noEditText = 'AC.SETTINGS.CANNOT_CHANGE_OLD_SETTINGS';

  public asset_: Asset;
  public emitterImage: string;

  private totalSiteIMU: number = 0;
  private siteIMUPolygon: google.maps.Polygon;

  public minDate = SWANConstants.MinDate;
  public dateToday: Date;

  private _goBackDays: number = 50;
  private _goFwdDays: number = 7;
  private adjustedTodayDayNumber: number;
  public filterEffectiveFrom: Date;
  public filterEffectiveTo: Date;

  public sensorsList = [];

  /* MAP */
  public isReadySiteData: angular.IPromise<any>;
  public isFileReadyForUpload: boolean;
  public sitePolygons: google.maps.Polygon[] = [];
  public siteMarkers: google.maps.Marker[] = [];

  private SMBprediction: number = 0;
  public selectedProbeId = -1;
  public availableProbes: IDropListItem[];

  private SMProbeOffset: number;
  private SMProbeFactor: number;
  public SMProbeOffsetString = '';
  public SMProbeFactorString = '';
  public SMProbeChanged = false;
  public isRainfallInfoShowing = false;
  private smTimer: angular.IPromise<void>;

  public selectedWeatherTab: number;
  public selectedIMUSites: ISiteProfile[] = [];

  private equipmentParentAssets: SiteAsset[] = [];
  // public selectedSensor;  // instance of imuEquipmentItems which handles health readings - see HealthSensorSelected

  public AssetStates = ['Active', 'Suspended', 'Archived'];

  public primaryTab: number;
  public settingsTab: number;
  public waterTab: number;

  public filterDatesForCropSettings: (date: Date) => boolean;
  public filterDatesForSoilSettings: (date: Date) => boolean;
  public filterDatesForWaterSettings: (date: Date) => boolean;
  public filterDatesForNutrientSettings: (date: Date) => boolean;

  public wuChart: AmCharts.AmSerialChart; //water usage chart

  /* Nutrients */
  public nutrientChartRollup: AmCharts.AmSerialChart;
  public nutrientDashboard: NutrientDashboard;

  /* Soil moisture setting */
  private smOverrideDaystoCalcFrom: number = 0;

  public infoWindow: google.maps.InfoWindow;

  public siteSettingCropFirstId: number;
  public siteSettingSoilFirstId: number;

  private isSuperUser: boolean;
  public hasSiteSettingsKcvi: boolean;
  public hasIrrigController: boolean;

  // custom changes
  private hasCustomChanges: Function;
  private saveCustomChanges: Function;
  private cancelCustomChanges: Function;

  public isSiteNameExist = false;
  public waterChartParams: IWaterChartParams;
  public weatherData: ObsWeatherRollup[];
  private isWeatherSettingChanged = false;
  private weatherStations: fuse.weatherStationDto[];
  private isKcviSettingChanged = false;
  private isWeatherChartInitialised = false;
  private weatherFromDate: Date = null;
  private isWaterChartInitialised = false;
  private isWaterChartZoomed = false;
  private isSiteSettingFetched = false;

  //older label-only unit handling - should phase these out
  public areaUnit: uomUnit;
  private elevationUnit: uomUnit;
  private weightAreaUnit: uomUnit;
  public fluidDepthUnit: uomUnit;
  public soilUnit: uomUnit;
  private temperatureUnit: uomUnit;
  private velocityUnit: uomUnit;
  private volumeLargeUnit: uomUnit;
  private volumeAreaUnit: uomUnit;
  private volumeAreaLargeUnit: uomUnit;
  private energyAreaDayUnit: uomUnit;

  //these should be superseded by the above, but not all converted
  public _areaNormalUnit: string;
  private _elevationNormalUnit = undefined;
  private _weightAreaNormalUnit = undefined;
  public _fluidDepthNormalUnit: string;
  private fluidDepthNormalUnitDecimal: number;
  public _soilDepthNormalUnit: string;
  private _temperatureNormalUnit = undefined;
  private _velocityNormalUnit = undefined;
  private _volumeLargeUnit = undefined;
  private _volumeAreaNormalUnit = undefined;
  private _volumeAreaLargelUnit = undefined;
  private _energyAreaDayNormalUnit = undefined;

  public kcviReloadCount = 0;
  public waterHistoryReloadCount = 0;
  public waterHistoryRefreshCount = 0; //when click back from other view, need change the valye to force history chart redarw
  private siteKcviChanges: fuse.siteKcviChangesDto;
  private siteWaterChartOption: AmCharts.AmSerialChart;
  private siteWaterChartDataProvider = [] as fuse.soilMoisturePrediction[];
  private siteWaterChart: AmCharts.AmSerialChart;
  private siteWeatherChart: AmCharts.AmSerialChart;
  private datepickerCurrentDayNumber: number;
  private minimumSettingDate = SWANConstants.MinDate;
  private showChartTimer: angular.IPromise<void>;
  public weatherReloadCount = 0;
  public weatherIsNotLoading: boolean = false;
  public acknowledgementMsg;
  public saveCounter = 0;
  public isPredictFinished = false;
  public fertigationEnabled: boolean = false;

  private _http: angular.IHttpService;
  private _interval: angular.IIntervalService;
  private _mdDialog: angular.material.IDialogService;
  private _mdSidenav: angular.material.ISidenavService;
  private _q: angular.IQService;
  private _sce: angular.ISCEService;
  private _state: angular.ui.IStateService;
  private _timeout: angular.ITimeoutService;
  private _accountService: AccountsService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _dupeHandlerService: DupeHandlerService;
  private _groupSettingService: GroupSettingService;
  private _groupSiteService: GroupSiteService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _mapService: MapService;
  private _notifyingService: NotifyingService;
  private _siteService: SiteService;
  private _siteSettingService: SiteSettingService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private httpCanceller: angular.IDeferred<any>;
  private cancelled: boolean;

  private _hasSoilMoistureData = false;
  private _siteAssets: Asset[] = [];
  private map: google.maps.Map;
  private _drawingManager: google.maps.drawing.DrawingManager;
  private zoomStartDate: Date = new Date().addDays(-7);
  private zoomEndDate: Date = this.zoomStartDate.addDays(13);
  private keepUserZoom: boolean = false;

  public resetSlider: (newValues: number[]) => void;
  public updateSlider: (pcts: any) => void;
  private isPolygonError = false;
  private isHealthAreaIntersected = false;

  private polygonEvents = {
    set_at: () => {
      this.onPolygonChanged();
    },
    insert_at: () => {
      this.onPolygonChanged();
    },
    dragend: () => {
      this.infoWindow?.close();

      this.asset_.GeoJSON = JSON.stringify('{}');
      this.asset_.entityAspect.setModified();
    },
    click: (e) => {
      // Get the closest  polygon to mouse click, show info window at closest polygon centroid.
      // At worst case - should always be a IMU polygon.
      if (this.sitePolygons.length) {
        let clsId: number;
        let clsTitle: string;
        let clsStatus: string;
        let clsClass: string;
        let isWithinPolygon = false;

        this.sitePolygons.forEach((polygon) => {
          isWithinPolygon = google.maps.geometry.poly.containsLocation(
            new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()),
            polygon,
          );

          if (isWithinPolygon) {
            clsId = polygon.get('id');
            clsTitle = polygon.get('name');

            if (AssetUtils.isSiteIMU(polygon.get('assetClass'))) {
              clsStatus = this.asset_.Status;
              clsId = this.siteId;
            } else {
              clsStatus = polygon.get('status');
            }

            clsClass = polygon.get('assetClass');
          }
        });

        if (clsId) {
          this.infoWindow?.close();

          const content = this.markerPopup(clsTitle, clsClass, clsStatus);

          this.showInfoWindow(content, e.latLng);

          // Show the right hand side bar on click if == editMode
          try {
            if (this._siteService.isEditMode) {
              // Find the Asset entity
              this.entityManager.fetchEntityByKey('Asset', clsId, true).then((data) => {
                this._mdSidenav('right').close();
                this._siteService.selectedAsset = data.entity as Asset;
                this.reloadCounter++;

                const equipmentType = this._siteService.equipments.find(
                  (a) => a.id == this._siteService.selectedAsset.AssetClassId,
                );

                this._siteService.selectedAssetClass = equipmentType;
                this.assetClass = equipmentType;
                this._mdSidenav('right').open();
                this.asset_.entityAspect.setModified();
              });
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    },
  };

  private markerEvents = {
    click: (event: google.maps.MapMouseEvent, marker: google.maps.Marker) => {
      this.resetSelectedMarkers();

      this.showInfoWindow(marker.get('name'), event.latLng);

      if (!this._siteService.isEditMode) {
        return;
      }

      const assetId = marker.get('id');

      // Find the Asset entity
      this.entityManager.fetchEntityByKey('Asset', assetId, true).then((data) => {
        this._mdSidenav('right').close();
        this._siteService.selectedAsset = data.entity as Asset;
        this.reloadCounter++;

        const equipmentTypeIndex =
          this._siteService.equipments.map((assetClass) => assetClass.id)
          .indexOf(this._siteService.selectedAsset.AssetClassId);
        this._siteService.selectedAssetClass = this._siteService.equipments[equipmentTypeIndex];
        this.assetClass = this._siteService.equipments[equipmentTypeIndex];
        this._mdSidenav('right').open();
      });
    },

    dragend: (_event: google.maps.MapMouseEvent, marker: google.maps.Marker) => {
      const defer = this._q.defer();
      const assetId = marker.get('id');

      this.entityManager.fetchEntityByKey('Asset', assetId, true).then((data) => {
        this._siteService.selectedAsset = data.entity as Asset;
        this._mdSidenav('right').open();
        defer.resolve();
      });

      defer.promise.then(() => {
        const latLng = marker.getPosition();
        const latitude: number = parseFloat(latLng.lat().toFixed(5));
        const longitude: number = parseFloat(latLng.lng().toFixed(5));

        // update the entity marker latitude longitude
        this._siteService.selectedAsset.Latitude = latitude;
        this._siteService.selectedAsset.Longitude = longitude;
        // #region Update Site Polygon Marker

        // For any marker - update the asset GEOJSON
        this._siteAssets.forEach((asset) => {
          if (asset.AssetId === assetId) {
            const assetClass = SWANConstants.assetClasses.find((a) => a.id == asset.AssetClassId);
            const shapeAttrs = {
              assetClass: assetClass.name,
              id: asset.AssetId,
              name: asset.Name,
            };

            // Create new point vector in the data layer
            this.getShapeGeoJSON(new google.maps.Data.Point(latLng), shapeAttrs).then((geoJSON) => {
              asset.GeoJSON = geoJSON;
            });
          }
        });

        if (AssetUtils.ByAssetIds.isSite(this._siteService.selectedAsset.AssetClassId)) {
          this.setSiteCoord(latitude, longitude);
        }

        const dataLayer = new google.maps.Data();

        dataLayer.add({
          geometry: new google.maps.Data.Point(latLng),
          properties: {
            assetClass: marker.get('assetClass'),
            assetClassId: marker.get('assetClassId'),
            id: assetId,
            type: 'marker',
          },
        });

        dataLayer.toGeoJson((data) => {
          this._siteService.selectedAsset.GeoJSON = JSON.stringify(data);
        });

        this.reloadCounter++;
      });
    },
  };

  constructor(
    $http: angular.IHttpService,
    $interval: angular.IIntervalService,
    $mdDialog: angular.material.IDialogService,
    $mdSidenav: angular.material.ISidenavService,
    $q: angular.IQService,
    $sce: angular.ISCEService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    $timeout: angular.ITimeoutService,
    AccountsService: AccountsService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    DupeHandlerService: DupeHandlerService,
    GroupSettingService: GroupSettingService,
    GroupSiteService: GroupSiteService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    MapService: MapService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    SiteService: SiteService,
    SiteSettingService: SiteSettingService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.SiteFull);

    this._http = $http;
    this._interval = $interval;
    this._mdDialog = $mdDialog;
    this._mdSidenav = $mdSidenav;
    this._q = $q;
    this._sce = $sce;
    this._state = $state;
    this._timeout = $timeout;
    this._accountService = AccountsService;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._dupeHandlerService = DupeHandlerService;
    this._groupSettingService = GroupSettingService;
    this._groupSiteService = GroupSiteService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._mapService = MapService;
    this._notifyingService = NotifyingService;
    this._siteService = SiteService;
    this._siteSettingService = SiteSettingService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.amWeatherChart = this.amWeatherChart.bind(this);

    this.entityManager = DataEntityService.manager;
    this.isSuperUser = PermissionService.isSuperUser;
    this.siteId = Number($state.params.id);

    this._dupeHandlerService.setDuplicateType('Site (IMU)');
    this.nutrientDashboard = new NutrientDashboard('Site Nutrients');

    this.weatherFromDate = DayNumberService.daysAgo(358); // 7 days in future - 1 year

    this.updateSlider = (pcts) => {
      this._groupSettingService.updateValues(pcts);
      this.scope.$digest();
    };

    this._fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    this.fluidDepthNormalUnitDecimal = UnitOfMeasureService.getDecimalPlaces('Fluid Depth', unitSizes.normal);
    this._areaNormalUnit = UnitOfMeasureService.getUnitLabel('Area', unitSizes.normal);
    this._elevationNormalUnit = UnitOfMeasureService.getUnitLabel('Elevation', unitSizes.normal);
    this._weightAreaNormalUnit = UnitOfMeasureService.getUnitLabel('Weight/Area', unitSizes.normal);
    this._temperatureNormalUnit = UnitOfMeasureService.getUnitLabel('Temperature', unitSizes.normal);
    this._velocityNormalUnit = UnitOfMeasureService.getUnitLabel('Velocity', unitSizes.normal);
    this._volumeLargeUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.large);
    this._volumeAreaNormalUnit = UnitOfMeasureService.getUnitLabel('Volume/Area', unitSizes.normal);
    this._volumeAreaLargelUnit = UnitOfMeasureService.getUnitLabel('Volume/Area', unitSizes.large);
    this._energyAreaDayNormalUnit = UnitOfMeasureService.getUnitLabel('Solar Radiation', unitSizes.normal);

    this.fluidDepthUnit = UnitOfMeasureService.getUnits('Fluid Depth');
    this.soilUnit = UnitOfMeasureService.getUnits('Soil Depth');
    this.areaUnit = UnitOfMeasureService.getUnits('Area');
    this.elevationUnit = UnitOfMeasureService.getUnits('Elevation');
    this.weightAreaUnit = UnitOfMeasureService.getUnits('Weight/Area');
    this.temperatureUnit = UnitOfMeasureService.getUnits('Temperature');
    this.velocityUnit = UnitOfMeasureService.getUnits('Velocity');
    this.volumeLargeUnit = UnitOfMeasureService.getUnits('Volume', unitSizes.large);
    this.volumeAreaUnit = UnitOfMeasureService.getUnits('Volume/Area');
    this.volumeAreaLargeUnit = UnitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
    this.energyAreaDayUnit = UnitOfMeasureService.getUnits(UnitTypes.SolarRadiation);

    this.httpCanceller = this._q.defer();
    this.getWaterChartParams();
    this.scope['siteForm'] = {};

    this.hasSiteSettingsKcvi = PermissionService.hasSubscription('Kc Vegetation Index');
    this.hasIrrigController = PermissionService.hasSubscription('Irrigation Control');
    this.nutrientAssetIds = [this.siteId];

    const context = LocalStorageUtils.contextData;

    if (isFinite(context.tab1)) {
      this.primaryTab = context.tab1;

      if (isFinite(context.tab2)) {
        this.settingsTab = context.tab2;

        if (UiHelper.isTabActive(['Settings', 'Kcvi'])) {
          this.onTabSelected_SettingsKcvi();
        }

        context.tab2 = undefined;
      }

      if (isFinite(context.tab3)) {
        this.waterTab = context.tab3;
        context.tab3 = undefined;
      }

      context.tab1 = undefined;

      LocalStorageUtils.setContextData(context);
    }

    const promises = [];

    promises.push(this.getAccountDetail(this.accountId));
    promises.push(this.getSiteIrrigationPlanInfo(this.siteId));

    this._q.all(promises).then(() => {
      if (!this.isSuperUser) {
        this.minimumSettingDate = this.irrigationSeasonStart.clone().addYears(-1);
      }
    })
    .catch((reason) => {
      console.log(reason);
    });

    // #region Event Subscriptions
    this._notifyingService.subscribe(NotifyEvents.Map.UploadMapData, $scope, (_event: angular.IAngularEvent, data) => {
      this._mdSidenav('right').close();

      const assetClass = data.equipment as fuse.assetClassDto;

      this.reloadCounter++;
      this.assetClass = assetClass;
      this._siteService.selectedAsset = {} as Asset;
      this._siteService.selectedAssetClass = assetClass;
      this._siteService.selectedAreaMarker = { assetClass: 'Site (IMU)' };

      this._mdSidenav('right').open();
    });

    this._notifyingService.subscribe(NotifyEvents.Map.TotalAreaChanged, $scope, (_event: angular.IAngularEvent, data) => {
      this.totalAreaSpecified = !!data.exactArea;
      this.siteWater[this.siteWaterIndex].CropWaterArea = data.exactArea;
      this.siteNoots[this.siteNootsIndex].CropNutrientArea = data.exactArea;
    });

    this._notifyingService.subscribe(NotifyEvents.App.SaveChanges.Site, $scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });

    this._notifyingService.subscribe(NotifyEvents.Map.AssetClassChanged, this.scope, (_event: angular.IAngularEvent, data) => {
      const equipment = data.equipment;

      if (equipment) {
        this.assetClass = equipment;

        if (this._drawingManager) {
          const currentDrawingMode = this._drawingManager.getDrawingMode();
          const isPanMode = !currentDrawingMode;

          if (AssetUtils.isSiteIMU(equipment.name)) {
            this._siteService.selectedAsset = this.asset_;
          }

          switch (equipment.mappable) {
            case 'Point':
              this._drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
              break;
            case 'Polygon':
              if (!isPanMode)
                this._drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
              break;
            default:
              break;
          }
        }
      }
    });

    this._notifyingService.subscribe(NotifyEvents.Map.DrawingModeChanged, this.scope, (_event: angular.IAngularEvent, data) => {
      const setDrawingMode = !(
        AssetUtils.isSiteIMU(this._siteService.selectedAssetClass?.name) &&
        this._siteService.isSiteGeometryCreated
      );
      switch (data.drawingMode) {
        case 'Point':
          this._drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
          break;
        case 'Polygon': {
          if (setDrawingMode) {
            this._drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
          } else {
            this._drawingManager.setDrawingMode(null);
          }
          break;
        }
        case 'Circle': {
          if (setDrawingMode) {
            this._drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
          } else {
            this._drawingManager.setDrawingMode(null);
          }
          break;
        }
        default:
          this._drawingManager.setDrawingMode(null);
          break;
      }
    });

    this._notifyingService.subscribe(NotifyEvents.Map.EditModeChanged, this.scope, (_event: angular.IAngularEvent, data) => {
      const isEditMode = data.isEditMode;
      const equipment = data.equipment;
      const fullScreenControl = document.querySelector('.gm-fullscreen-control');

      if (fullScreenControl) {
        if (isEditMode) {
          fullScreenControl.classList.add('fullscreen-disabled');
        } else {
          fullScreenControl.classList.remove('fullscreen-disabled');
        }

        // This ensures the full screen control's button position is recalculated based on the custom map control container size.
        this.map.setZoom(this.map.getZoom());
      }

      this.sitePolygons.forEach((polygon) => {
        const isTarget = isEditMode && (polygon.get('assetClass') === equipment?.name);

        polygon.setEditable(isTarget);
        polygon.setDraggable(isTarget);
      });

      this.siteMarkers.forEach((marker) => {
        marker.setDraggable(isEditMode && (!equipment || marker.get('assetClass') === equipment.name));
      });

      if (isEditMode) {
        // Get site settings as otherwise 'aaveChanges' throws an error if they are not present -- To FIX
        this._fetchSiteSettingsData();
      } else {
        this._drawingManager.setDrawingMode(null);
      }
    });

    this._notifyingService.subscribe(NotifyEvents.Map.MapTypeChanged, this.scope, (_event: angular.IAngularEvent, data) => {
      switch (data.mapTypeId) {
        case google.maps.MapTypeId.ROADMAP:
        case google.maps.MapTypeId.TERRAIN:
        case google.maps.MapTypeId.HYBRID:
        case 'NDVI':
        case 'EVI':
        case 'NDWI':
        case 'FNIR':
        case 'OSM':
        case 'OTM':
        case 'ESRI':
          this.map.setMapTypeId(data.mapTypeId);
          break;
      }
    });

    this._notifyingService.subscribe(NotifyEvents.Map.MapDataUploaded, this.scope, (_event: angular.IAngularEvent, data) => {
      this.isFileReadyForUpload = false; // Update flag after upload ended to disable the upload button.
      this.infoWindow?.close();

      this.renderUploadedMapData(data.featureCollection, data.paths);
    });
    // #endregion Event Subscriptions

    this.adjustedTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();

    this.dateToday = new Date();
    this.dateToday.setDate(this.dateToday.getDate());

    // default range for date range filter
    const todayMidnight = moment(new Date()).startOf('day').toDate();
    this.filterEffectiveFrom = todayMidnight;
    this.filterEffectiveTo = moment(this.filterEffectiveFrom).add(6, 'months').toDate(); // six months in the future

    // #region POLYGON Data
    this.sitePolygons = [];
    this._siteService.selectedAreaMarker = null;
    // #endregion POLYGON Data

    // #region MARKERS Data and Events
    this.siteMarkers = [];
    this._siteService.selectedMarker = null;
    // #endregion MARKERS Data and Events

    this._fetchData();

    const getEffectiveSiteSetting = (setting: any): boolean => {
      const getSiteSettingsForType = () => {
        if (setting instanceof SiteSettingsCrop) {
          return this.siteCrop;
        }

        if (setting instanceof SiteSettingsNutrients) {
          return this.siteNoots;
        }

        if (setting instanceof SiteSettingsSoil) {
          return this.siteSoil;
        }

        if (setting instanceof SiteSettingsWater) {
          return this.siteWater;
        }
      };

      const siteSettings = getSiteSettingsForType();
      const currentSiteSettings = ArrayUtils.sortByNumberWithMutation(siteSettings.filter(x => x.Id), (x) => x.DayNumber);
      const taretSiteSettingsIndex = this._siteSettingService.findEffective(this.filterEffectiveFrom, currentSiteSettings);

      return siteSettings[taretSiteSettingsIndex].Id == setting.Id;
    };

    this.dateRangeFilter = (setting) => {
      const inRange: boolean = setting.localDate >= this.filterEffectiveFrom && setting.localDate <= this.filterEffectiveTo;

      if (inRange) {
        return setting;
      }

      // Also include the row in effect at the given start day.
      return getEffectiveSiteSetting(setting);
    };

    this.filterDatesForCropSettings = (date: Date): boolean => {
      return this.filterDates(this.siteCrop, date);
    };

    this.filterDatesForSoilSettings = (date: Date): boolean => {
      return this.filterDates(this.siteSoil, date);
    };

    this.filterDatesForWaterSettings = (date: Date): boolean => {
      return this.filterDates(this.siteWater, date);
    };

    this.filterDatesForNutrientSettings = (date: Date): boolean => {
      return this.filterDates(this.siteNoots, date);
    };

    // Watch for the inclusion of the map.html via ng-include and map container being loaded.
    $scope.$watch(() => document.getElementById('site-google-map'), (isMapContainerLoaded) => {
      if (isMapContainerLoaded) {
        this.isReadySiteData.then(() => {
          this.initMap();
        });
      }
    });

    $scope.$on('$destroy', () => {
      if (this.httpCanceller) {
        this.cancelled = true;
        this.httpCanceller.resolve('User left \'Site\' page');
      }

      this._dataEntityService.installCustomChanges(null, null, null);
    });

    this.emitterImage = `assets/images/sites/emitterloss_${InitialisationService.selectedLanguageCode}.png`;
  }

  public static get URTH_CAST(): string {
    return 'api_key=E44938AD463245A8B721&api_secret=9033D118374D431D8E029D0E85E64BFE&sensor_platform=theia';
  }

  public static get SITE_BASE_Z_IDX(): number {
    return 1;
  }

  public static get SITE_AREA_Z_IDX(): number {
    return 2;
  }

  public static get SITE_MARKER_Z_IDX(): number {
    return 3;
  }

  public get hasDataChanges(): boolean {
    const result = this.isValidEntry() && (this.isWeatherSettingChanged || this.isKcviSettingChanged || this._dataEntityService.hasDataChanges);

    return result;
  }

  public get canUpdateSM(): boolean {
    return this._hasSoilMoistureData && !this._siteService.isNewSite && this.isSiteActive && this.isPredictFinished;
  }

  $onInit() {
    const initialise = (): void => {
      this.searchTerm = '';
      this._siteSettingService.currentAssetId = this.siteId;
      this._siteService.isEditMode = false;
      this._siteSettingService.isGroupSetting = false;

      // this.logtoDate = this._dayNumberService.endOfToday();
      // this.logfromDate = this._dayNumberService.daysAgo(7);
      // this.logSeverity = 3;

      if (this._state.params.viewSchedule) {
        this.viewWaterSchedule();
      } else if (this._state.params.viewMap) {
        this.viewMap();
      }

      this.hasCustomChanges = (): boolean => {
        return !!this.FormWaterBudget?.$dirty;
      };

      this.saveCustomChanges = () => {
        if (!this.hasCustomChanges()) {
          return;
        }

        const savePromises = [];

        savePromises.push(
          this._saveWaterBudget()
            .then((response) => {})
            .catch((error) => {
              this._languageService.error('AC.SITE.MSG.WATER_BUDGET_FAIL');
            }),
        );

        //wait for all promises to resolve
        this._q.all(savePromises);
      };

      this.cancelCustomChanges = () => {
        this.FormWaterBudget?.$setPristine();
      };

      this._dataEntityService.installCustomChanges(this.hasCustomChanges, this.saveCustomChanges, this.cancelCustomChanges);
    };

    initialise();

    this._siteService.isSiteGeometryCreated = false;
    this.nutrientAssetIds.push({ Id: this.siteId, Name: '' } as IdName);

    this.getEquipmentData();
    this.weatherMsg();
  }

  private getWaterChartParams() {
    this.waterChartParams = this._localStorageService.get('waterChartParams') as IWaterChartParams;

    if (!this.waterChartParams) {
      this.waterChartParams = {} as IWaterChartParams;
    }

    if (!this.waterChartParams.soilMoistureUnit) {
      this.waterChartParams.soilMoistureUnit = '%';
      this._localStorageService.set('waterChartParams', this.waterChartParams);
    }
  }

  public handleChangeSoilProbe() {
    this.SMProbeOffset = null;
    this.SMProbeFactor = null;

    const body = {
      assetId: this.selectedProbeId,
      id: this.siteId,
    } as fuse.soilMoistureProbeDto;

    this._http.post(CommonHelper.getApiUrl('site/changeSiteSoilMoistureProbe'), JSON.stringify(body)).then(() => {
      this.updateSoilMoisturePredictions(false);
    });
  }

  public disableAddButton(): boolean {
    if (UiHelper.isTabActive(['Settings', 'Soil']) && this.apf.hasSiteSettingsSoilFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Settings', 'Crop']) && this.apf.hasSiteSettingsCropFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Settings', 'Water']) && this.apf.hasSiteSettingsWaterFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Settings', 'Nutrients']) && this.apf.hasSiteSettingsNutrientsFull) {
      return false;
    }

    return true;
  }

  private onPolygonChanged(): void {
    this.asset_.GeoJSON = JSON.stringify('{}');
    // When a polygon node is moved, need to test all the polygons, see if its a site IMU that's changed - need to update the Site Areas
    // Seems a bit of overkill but only way to get the dynamic result.
    for (let i = 0; i < this.sitePolygons.length; i++) {
      const sitePolygon = this.sitePolygons[i];

      if (i == 0) {
        if (GoogleMapUtils.isSelfIntersected(sitePolygon.getPath().getArray())) {
          this.isPolygonError = true;
          this._languageService.error('AC.SITE.MSG.IMU_INTERSECT_ERROR');

          return;
        } else {
          this.isPolygonError = false;
        }
      } else {
        if (GoogleMapUtils.isSelfIntersected(sitePolygon.getPath().getArray())) {
          this.isHealthAreaIntersected = true;
          this._languageService.error('AC.SITE.MSG.SHA_INTERSECT_ERROR');

          return;
        } else {
          this.isHealthAreaIntersected = false;
        }
      }

      if (sitePolygon) {
        const polygon = this.getDataPolygon(sitePolygon);

        if (AssetUtils.ByAssetIds.isSite(sitePolygon.get('assetClassId'))) {
          // If Site IMU then update area in case its changed.
          try {
            const siteArea = google.maps.geometry.spherical.computeArea(this.getPolyLatLongs(polygon)) / 10000;

            this.asset_.Site.Area = siteArea;
            this.asset_.entityAspect.setModified();

            const polygonCentre = GoogleMapUtils.Polygons.getCentre(polygon);

            this.asset_.Latitude = polygonCentre?.lat;
            this.asset_.Longitude = polygonCentre?.lng;

            if (!this.totalAreaSpecified) {
              if (this.newSitePoly) {
                // If site polygon has just been created, reflect all changes in water/nutrient areas
                this.siteWater[this.siteWaterIndex].CropWaterArea = siteArea;
                this.siteNoots[this.siteNootsIndex].CropNutrientArea = siteArea;
              } else {
                // Test size of watering & nutrient area: if bigger than new site area then they are reduced to equal new site area
                if (this.siteWater[this.siteWaterIndex].CropWaterArea > siteArea) {
                  this.siteWater[this.siteWaterIndex].CropWaterArea = siteArea;
                }
                if (
                  !this.siteNoots[this.siteNootsIndex].CropNutrientArea ||
                  this.siteNoots[this.siteNootsIndex].CropNutrientArea > this.siteWater[this.siteWaterIndex].CropWaterArea
                ) {
                  this.siteNoots[this.siteNootsIndex].CropNutrientArea = this.siteWater[this.siteWaterIndex].CropWaterArea;
                }
              }
            }
          } catch (err) {
            console.log('Error updating site Area');
          }
        } else {
          const asset = this._siteAssets.find((x) => x.AssetId == sitePolygon.get('id'));

          if (asset) {
            try {
              const assetClass = SWANConstants.assetClasses.find((a) => a.id == asset.AssetClassId);
              const shapeAttrs = { assetClass: assetClass.name, id: sitePolygon.get('id'), name: asset.Name };

              this.getShapeGeoJSON(polygon, shapeAttrs).then((shapeJSON) => {
                const polygonCentre = GoogleMapUtils.Polygons.getCentre(polygon);

                asset.Latitude = polygonCentre?.lat;
                asset.Longitude = polygonCentre?.lng;
                asset.GeoJSON = shapeJSON;
              });
            } catch (err) {
              // Error for some reason
            }
          }
        }
      }
    }
  }

  private getPolyLatLongs(polygon: google.maps.Polygon | google.maps.Data.Polygon) {
    if (polygon instanceof google.maps.Polygon) {
      return polygon.getPath().getArray();
    }

    if (polygon instanceof google.maps.Data.Polygon) {
      return polygon.getArray()[0].getArray();
    }

    return null;
  }

  public disableInputs(): boolean {
    if (UiHelper.isTabActive(['Summary']) && this.apf.hasSiteFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Map']) && this.apf.hasSiteMapFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Water', 'Budget']) && this.apf.hasSiteWaterFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Water', 'Applied']) && this.apf.hasSiteWaterPlanAppliedFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Nutrients']) && this.apf.hasSiteNutrientsFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Settings', 'Soil']) && this.apf.hasSiteSettingsSoilFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Settings', 'Crop']) && this.apf.hasSiteSettingsCropFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Settings', 'Water']) && this.apf.hasSiteSettingsWaterFull) {
      return false;
    }

    if (UiHelper.isTabActive(['Settings', 'KCVI']) && this.hasSiteSettingsKcvi) {
      return false;
    }

    if (UiHelper.isTabActive(['Settings', 'Weather']) && this.apf.hasSiteSettingsWeatherFull) {
      return false;
    }

    return this.disableAddButton();
  }

  private amChartSDSDataUpdated(event) {
    this.keepUserZoom = true;
    this.amChartSDSZoomed(event);
  }

  private setMax(chart: AmCharts.AmSerialChart) {
    const max = chart.valueAxes[0].maxRR;
    const minMax = this.fluidDepthUnit.fromBase(2).toFixed(2);
    if (max != null) {
      chart.valueAxes[0].maximum = Math.max(minMax, max);
    }
  }

  private amChartSDSZoomed(event) {
    if (this.siteWaterChart) {
      const chart = this.siteWaterChart;
      if (!this.isWaterChartZoomed) {
        if (event && event.endDate) {
          this.zoomStartDate = (angular.copy(event.endDate) as Date).addDays(-14);
          this.zoomEndDate = angular.copy(event.endDate) as Date;
          this.setMax(chart);
          this.isWaterChartZoomed = true;
          chart.validateNow();
        }
      } else {
        if (this.keepUserZoom) {
          chart.zoomToDates(this.zoomStartDate, this.zoomEndDate);
          this.setMax(chart);
          this.keepUserZoom = false;
          chart.validateNow();
        } else {
          this.zoomStartDate = angular.copy(event.chart.startDate) as Date;
          this.zoomEndDate = angular.copy(event.chart.endDate) as Date;
          const max = chart.valueAxes[0].maxRR;
          if (max != null) {
            const max_threshold = this.fluidDepthUnit.fromBase(2);
            chart.valueAxes[0].maximum = max <= max_threshold ? max_threshold : max;
          }
        }
      }
    }
  }

  private resetSelectedMarkers() {
    this._siteService.selectedAreaMarker = null;
    this._siteService.selectedMarker = null;
    this._siteService.selectedAsset = null;
  }

  // #region TAB Events
  public onTabSelected_Weather() {
    if (!this.isWeatherChartInitialised) {
      this.siteWeatherChart = AmCharts.makeChart('site-weather-chart', this.amWeatherChart()) as AmCharts.AmSerialChart;
      this.refreshWeather();
    }
  }

  public refreshWeather() {
    const weatherChartTimer = this._interval(() => {
      if (this.siteWeatherChart['chartRendered']) {
        this._interval.cancel(weatherChartTimer);
        this.getSiteObservations().then(() => {
          this.isWeatherChartInitialised = true;
          this.siteWeatherChart.validateData();
        });
      }
    }, 500);
  }

  public onTabSelected_Water() {
    if (this.siteWater.length <= 0) {
      this.getSiteSettingWater();
    }
    this._fetchWaterBudget();
  }

  public onTabSelected_Nutrients() {
    //From HTML to enable the nutrients component to get data and display
    this.nutrientsTabEnabled = 1;
  }

  public onTabSelected_SettingsKcvi() {
    if (!this.kcviReloadCount) {
      this.kcviReloadCount = 1;
    }
  }

  public onTabSelected_WaterHistory() {
    if (!this.waterHistoryReloadCount) {
      this.waterHistoryReloadCount = 1;
    } else {
      this.waterHistoryRefreshCount++;
    }
  }

  public onTabSelected_SettingsWeather() {
    if (!this.weatherReloadCount) {
      this.weatherReloadCount = 1;
    }
  }
  // #endregion TAB Events

  private getSiteSettingIndex(dayNumber: number, siteSettings: SiteSettingBase[]): number {
    // siteSettings is sorted by dayNumber (ascend)
    const latestSiteSetting = siteSettings.find((a) => a.DayNumber >= dayNumber);
    if (latestSiteSetting) {
      const latestIndex = siteSettings.findIndex((a) => a.DayNumber >= dayNumber);
      if (latestSiteSetting.DayNumber == dayNumber) {
        return latestIndex;
      } else {
        return latestIndex - 1;
      }
    } else {
      // there is no setting after the dayNumber, use the closest setting
      return siteSettings.length - 1;
    }
  }

  public setSiteCropId(id: number) {
    if (!this.isCropSettingFormValid()) {
      this.showAlert();
      return;
    }

    this.siteCropIndex = this._siteSettingService.findById(id, this.siteCrop);
    this.initSiteSettings();
  }

  public setSiteSoilId(id: number) {
    if (!this.isSoilSettingFormValid()) {
      this.showAlert();
      return;
    }

    this.siteSoilIndex = this._siteSettingService.findById(id, this.siteSoil);
    this.initSiteSettings();
  }

  public setSiteNootsId(id: number) {
    if (!this.isNutrientSettingFormValid()) {
      this.showAlert();
      return;
    }

    this.siteNootsIndex = this._siteSettingService.findById(id, this.siteNoots);
    this.initSiteSettings();
  }

  public setSiteWaterId(id: number) {
    if (!this.isWaterSettingFormValid()) {
      this.showAlert();
      return;
    }

    this.siteWaterIndex = this._siteSettingService.findById(id, this.siteWater);
    this.initSiteSettings();
  }

  public convertIrrigationToHour(siteDailySummary: fuse.soilMoisturePrediction): number | null {
    let irrigation = 0;

    if (siteDailySummary.dayNumber < this.adjustedTodayDayNumber) {
      if (siteDailySummary.irrigation == undefined) {
        return null;
      }

      if (siteDailySummary.irrigation == 0) {
        return 0;
      }

      irrigation = siteDailySummary.irrigation;
    } else {
      if (siteDailySummary.irrigationPlanned == undefined) {
        return null;
      }

      if (siteDailySummary.irrigationPlanned == 0) {
        return 0;
      }

      irrigation = siteDailySummary.irrigationPlanned;
    }

    const siteSettingWater = this.siteIrrigationPlanInfo.siteSettingWaters.find((a) => a.dayNumber <= siteDailySummary.dayNumber);

    if (!siteSettingWater) {
      //site is not setted, but daily summary already has value
      return -1;
    }

    return irrigation / siteSettingWater.irrigationRate;
  }

  public isOvertime(siteDailySummary: fuse.soilMoisturePrediction): boolean {
    const hours = this.convertIrrigationToHour(siteDailySummary);
    return hours == null || hours > 24;
  }

  public soilMoistureClass(item: fuse.soilMoisturePrediction) {
    if (item.soilMoisture > item.smTargetHigh) {
      return 'p-8 md-blue-500-bg';
    }

    if (item.soilMoisture < item.smTargetLow) {
      return 'p-8 md-orange-300-bg';
    }

    return 'p-8 md-green-500-bg';
  }

  private convertIrrigationPlannedToHour(siteDailySummary: fuse.soilMoisturePrediction): number | null {
    if (siteDailySummary.irrigationPlanned == undefined) {
      return null;
    }

    const siteSettingWater = this.siteIrrigationPlanInfo.siteSettingWaters.find((a) => a.dayNumber <= siteDailySummary.dayNumber);

    if (!siteSettingWater) {
      return null;
    }

    return siteDailySummary.irrigationPlanned / siteSettingWater.irrigationRate;
  }

  public setSiteCoord(lat: number, lng: number) {
    if (this.asset_) {
      this.asset_.Latitude = lat;
      this.asset_.Longitude = lng;
      this._mapService.getElevation(this.asset_.Latitude, this.asset_.Longitude).then((elev: number) => {
        this.asset_.Elevation = elev;
      });
      this._mapService.getTimeZone(this.asset_.Latitude, this.asset_.Longitude).then(
        (tz: string) => {
          const tzData = JSON.parse(tz) as IGoogleTimeZone;
          if (tzData.status == 'ZERO_RESULTS') {
            if (this.scope['siteForm'].Latitude && this.scope['siteForm'].Longitude) {
              this.scope['siteForm'].Latitude.$setValidity('invalid', false);
              this.scope['siteForm'].Longitude.$setValidity('invalid', false);
              this.scope['siteForm'].Latitude.$setDirty();
              this.scope['siteForm'].Longitude.$setDirty();
              this.scope['siteForm'].Latitude.$setTouched();
              this.scope['siteForm'].Longitude.$setTouched();
            }
            this._languageService.error('AC.SITE.MSG.LATLONG_INVALID', 'AC.SITE.MSG.LOCATION_ERROR');
          } else {
            if (this.scope['siteForm'].Latitude && this.scope['siteForm'].Longitude) {
              this.scope['siteForm'].Latitude.$setValidity('invalid', true);
              this.scope['siteForm'].Longitude.$setValidity('invalid', true);
            }
          }
          this.asset_.TZStandardName = tz;
        },
        (error) => {
          console.log(error);
        },
      );
      this.reloadCounter++;
    }
  }

  private addGuideToday(chart: AmCharts.AmSerialChart) {
    if (!chart) {
      return;
    }

    const guideLine: AmCharts.Guide = new AmCharts.Guide();
    guideLine.date = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId); // today - now
    guideLine.above = true;
    guideLine.lineColor = '#444444';
    guideLine.lineAlpha = 0.4;
    guideLine.inside = true;
    guideLine.label = DateUtils.Locale.asDateTimeDayAndMonthAndTime(guideLine.date);
    guideLine.labelRotation = 90;
    guideLine.position = 'top';
    guideLine.dashLength = 0;
    if (chart.categoryAxis.addGuide !== undefined) {
      // remove any existing guides
      for (let idx = chart.categoryAxis.guides.length - 1; idx >= 0; idx--) {
        chart.categoryAxis.removeGuide(chart.categoryAxis.guides[idx]);
      }
      chart.categoryAxis.addGuide(guideLine);
    } else {
      //  console.log('check categoryAxis');
    }
    chart.validateNow();
  }

  private getEquipmentData() {
    if (!this.siteEquipments) {
      this._http
        .get(CommonHelper.getApiUrl(`site/getSiteEquipment/${this.siteId}`), {
          timeout: this.httpCanceller.promise,
        })
        .then(
          (response) => {
            if (!response.data) {
              this._languageService.whoops();
            } else {
              const result = response.data as fuse.siteEquipmentDto[];
              this.processSensors(result, this.siteId);
            }
          },
          (error) => {
            if (!this.cancelled) {
              this._languageService.whoops();
              this.processSensors([], this.siteId);
            }
          },
        );
    }
  }

  public processSensors(data: fuse.siteEquipmentDto[], siteId: number) {
    this.siteEquipments = new EntityList(data);
    this.siteEquipments.statusFilters = { Active: true, Archived: false, Suspended: false };
    this.waterMeterCount = this.siteEquipments.entities.filter((a) => a.assetClassName == AssetClassNameEnum.WaterFlowMeter).length;
    this.availableProbes = [];

    const soilMoistureAssetClass = SWANConstants.assetClasses.find(
      (a) => a.name.toLowerCase() == AssetClassNameEnum.SoilMoisture.toLowerCase(),
    );

    this.siteEquipments.entities.forEach((sensor) => {
      sensor['icon'] = this.getEquipmentIcon(sensor);

      if (sensor.assetClassId == soilMoistureAssetClass.id && sensor.status != 'Archived') {
        for (let i = 0; i < sensor.parentIds.length; i++) {
          if (sensor.parentIds[i] == siteId) {
            this.availableProbes.push({
              name: sensor.name,
              id: sensor.id,
            });
            break;
          }
        }
      }
    });
  }

  // GO TO Equipment details page
  public gotoEquipment(assetId: number): void {
    const equip = this.siteEquipments.entities.find((a) => a.id == assetId);

    LocalStorageUtils.updateContextData((context) => {
      context.assetId = assetId;
    });

    this._state.go('app.account.equipments.detail', { id: assetId });
  }

  private updateSoilMoisturePredictions(updateDB: boolean) {
    if (this.siteWaterChartDataProvider) {
      let startArrayDayNumber: number;

      if (this.siteWaterChartDataProvider.length) {
        startArrayDayNumber = this.siteWaterChartDataProvider[0].dayNumber;
      } else {
        const todayDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(new Date());
        startArrayDayNumber = todayDayNumber - this._goBackDays;
      }

      const irrigationArray = [] as string[];
      const fertigationArray = [] as string[];
      const irrigationLockArray = [] as string[];

      if (!this.siteWaterChartDataProvider.length) {
        irrigationArray.push('-1');
        fertigationArray.push('-2');
        irrigationLockArray.push('-1');
      } else {
        this.siteWaterChartDataProvider.forEach((item) => {
          if (item.dayNumber >= this.adjustedTodayDayNumber) {
            if (item.irrigationPlanned != null) {
              irrigationArray.push(item.irrigationPlanned.toFixed(2));
            } else {
              irrigationArray.push('0');
            }
          } else {
            if (item.irrigation != null) {
              irrigationArray.push(item.irrigation.toFixed(2));
            } else {
              irrigationArray.push('0');
            }
          }
          if (item.fertigationPlanned != null) {
            fertigationArray.push(item.fertigationPlanned.toFixed(2));
          } else {
            fertigationArray.push('0');
          }
          if (item.irrigationLocked != null) {
            irrigationLockArray.push(item.irrigationLocked ? '1' : '0');
          } else {
            irrigationLockArray.push('0');
          }
        });
      }

      this.updateSoilMoistureBalance(
        this.siteId,
        this.selectedProbeId,
        startArrayDayNumber,
        irrigationArray.join(),
        fertigationArray.join(),
        irrigationLockArray.join(),
        updateDB,
      ).then(() => {
        if (updateDB) {
          this.getSiteSummaryPacket(this.asset_.AssetId);
        }
      });
    }
  }

  private updateSoilMoistureBalance(
    siteId: number,
    probeId: number,
    startArrayDayNumber: number,
    irrigationArray: string,
    fertigationArray: string,
    irrigationLockArray: string,
    applyToDB: boolean,
  ): angular.IPromise<void> {
    this.SMBprediction++;
    this.isPredictFinished = false;

    const params = {
      AssetId: siteId,
      ProbeId: probeId,
      StartDayNumber: startArrayDayNumber,
      IrrigationArray: irrigationArray,
      IrrigationLockArray: this.SMBprediction == 1 ? '-1' : irrigationLockArray,
      FertigationArray: this.SMBprediction == 1 ? '-2' : fertigationArray,
      ApplyToDB: applyToDB,
      seq: this.SMBprediction,
    };

    return this._http.get(CommonHelper.getApiUrl('user/predictSM'), { params: params, timeout: this.httpCanceller.promise }).then((data) => {
      const response = data.data as fuse.soilMoistureModel;

      if (response.seq !== this.SMBprediction) {
        return;
      }

      this.SMProbeFactor = response.smProbeFactor;
      this.SMProbeOffset = response.smProbeOffset;
      this.SMProbeChanged = false;

      if (!response.data) {
        return;
      }

      // update the entities
      response.data.forEach((d) => {
        if (d.dayNumber >= this.adjustedTodayDayNumber) {
          d.irrigation = 0;
        }
      });

      this.keepUserZoom = true;
      this.siteWaterChartDataProvider = response.data.map((x) => ({
        ...x,
        dayDate: this._dayNumberService.convertYMDToLocalMidnight(x.dayDisplayYMD),
        irrigationPlanned: NumberUtils.round(x.irrigationPlanned, 4),
        saturationPoint: NumberUtils.round(x.saturationPoint, 1),
        soilMoisture: NumberUtils.round(x.soilMoisture, 1),
        wiltingPoint: NumberUtils.round(x.wiltingPoint, 1),
      }));

      this.siteWaterChartDataProvider.forEach((daily) => {
        if (this.hasIrrigationPlan === false && daily.fertigationPlanned == -1) {
          daily.fertigationPlanned = 0; // should only happen if site has been recently removed from irrigation plan, but just in case
        }

        if (daily.fertigationPlanned != -1 && daily.irrigationPlanned < daily.fertigationPlanned) {
          daily.isValid = false;
        } else {
          daily.isValid = true;
        }
      });

      this.soilMoistureProbeChanged();
    },
    (err) => {
      if (!this.cancelled) {
        this._languageService.warning('AC.SITE.MSG.PREDICTION_ERROR', 'COMMON.WARNING', { err: err.message });
      }
    })
    .finally(() => {
      this.isPredictFinished = true;
    });
  }

  /*  fetch the given observation (breeze.entities) for the site */
  private getSiteObservations(): angular.IPromise<any> {
    this.weatherIsNotLoading = false;

    const obsEntityName: string = 'ObsWeatherRollup';
    const defer = this._q.defer();

    // get a list of sensors used by the site
    const strDateTo = this.weatherFromDate.clone();
    strDateTo.addDays(365);
    const params = {
      AssetId: this.siteId,
      DayFrom: this._dayNumberService.convertDateToLocaleDayNumber(this.weatherFromDate, 'UTC'),
      DayTo: this._dayNumberService.convertDateToLocaleDayNumber(strDateTo, 'UTC'),
    };
    // do a partial expand on the server side (limit to given dates)
    breeze.EntityQuery.from('ObsWeatherRollup')
      .withParameters(params)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.weatherData = (data.results as ObsWeatherRollup[]).map((obs) => {
          obs.dayDate = this._dayNumberService.convertYMDToLocalMidnight(obs.dayDisplayYMD);

          return obs;
        });

        this.siteWeatherChart.dataProvider = WeatherConverter.convert(this._unitOfMeasureService, this.weatherData, unitSizes.normal);
        defer.resolve(this.weatherData);
        this.weatherIsNotLoading = true;
      })
      .catch((error) => {
        this._languageService.error('AC.SITE.MSG.COULD_NOT_LOAD', 'COMMON.ERROR', { name: obsEntityName, msg: error.message });
        defer.reject();
      });

    return defer.promise;
  }

  /*  fetch the given observation (breeze.entities) for the site */
  private getSiteDailySummaries(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    // get the Data for the given entity
    breeze.EntityQuery.from('SiteDailySummary')
      .withParameters({ AssetId: this.siteId })
      .using(this.entityManager)
      .execute()
      .then(
        (data) => {
          this.siteWaterChartDataProvider =
            (data.results as SiteDailySummary[]).map((obs) => ({
              dayDate: this._dayNumberService.convertYMDToLocalMidnight(obs.dayDisplayYMD),
              dayDisplayYMD: obs.dayDisplayYMD,
              dayNumber: obs.dayNumber,
              irrigation: obs.dayNumber >= this.adjustedTodayDayNumber ? 0 : obs.Irrigation,
              irrigationBudget: obs.IrrigationBudget,
              irrigationPlanned: NumberUtils.round(obs.IrrigationPlanned, 4),
              soilMoisture: obs.SoilMoisture,
            } as fuse.soilMoisturePrediction));

          defer.resolve();
        },
        () => {
          defer.reject();
        },
      );

    return defer.promise;
  }

  // WEATHER
  public viewWeather() {
    this.primaryTab = PrimaryTabEnum.Weather;
  }

  // SOIL MOISTURE [SITE -> WATER -> PLAN]
  public viewSoilMoisture() {
    if (!this.apf.hasSiteWaterView) {
      return;
    }

    this.primaryTab = PrimaryTabEnum.Water;
    this.waterTab = 1;
  }

  // ALERTS
  public viewAlerts() {
    this.primaryTab = PrimaryTabEnum.Logs;
  }

  // WATER RESOURCING
  public viewWaterUsage() {
    this.primaryTab = PrimaryTabEnum.Water;
    this.waterTab = 0;
  }

  // WATER Schedule
  public viewWaterSchedule() {
    this.primaryTab = PrimaryTabEnum.Water;
    this.waterTab = 1;
  }

  // Nutrients
  public viewNutrients() {
    this.primaryTab = PrimaryTabEnum.Nutrients;
  }

  // MAP
  public viewMap() {
    this.primaryTab = PrimaryTabEnum.Map;
  }

  public addNewSetting() {
    let newSettingDayNumber = this.adjustedTodayDayNumber + 1; // tomorrow daynumber
    if (UiHelper.isTabActive(['Settings', 'Soil'])) {
      if (!this.isSoilSettingFormValid()) {
        this.showAlert();
        return;
      }

      // get next available date
      while (this.siteSoil.some((a) => a.DayNumber == newSettingDayNumber)) {
        newSettingDayNumber++;
      }

      const currSoilEffectiveRec = this.siteSoil[this.siteSoilIndex];
      const newSoilRec = this._groupSettingService.addNewSetting('SoilSettings', currSoilEffectiveRec);

      newSoilRec.localDate = this._dayNumberService.convertDayNumberToDate(newSettingDayNumber);
      newSoilRec.DayNumber = newSettingDayNumber;

      this.entityManager.addEntity(newSoilRec);
      this.siteSoil.push(newSoilRec);

      this.siteSoil = ArrayUtils.sortByNumberWithMutation(this.siteSoil, (x) => x.DayNumber);

      this.setSiteSoilId(newSoilRec.Id);
    } else if (UiHelper.isTabActive(['Settings', 'Crop'])) {
      if (!this.isCropSettingFormValid()) {
        this.showAlert();
        return;
      }
      while (this.siteCrop.some((a) => a.DayNumber == newSettingDayNumber)) {
        newSettingDayNumber++;
      }
      const newCropRec: SiteSettingsCrop = this._groupSettingService.addNewSetting('CropSettings', this.siteCrop[this.siteCropIndex]);
      newCropRec.localDate = this._dayNumberService.convertDayNumberToDate(newSettingDayNumber);
      newCropRec.DayNumber = newSettingDayNumber;
      this.entityManager.addEntity(newCropRec);

      this.siteCrop.push(newCropRec);
      this.siteCrop = ArrayUtils.sortByNumberWithMutation(this.siteCrop, (x) => x.DayNumber);
     
      this.setSiteCropId(newCropRec.Id);
    } else if (UiHelper.isTabActive(['Settings', 'Water'])) {
      if (!this.isWaterSettingFormValid()) {
        this.showAlert();
        return;
      }

      while (this.siteWater.some((a) => a.DayNumber == newSettingDayNumber)) {
        newSettingDayNumber++;
      }

      const newWaterRec: SiteSettingsWater = this._groupSettingService.addNewSetting('WaterSettings', this.siteWater[this.siteWaterIndex]);
      newWaterRec.localDate = this._dayNumberService.convertDayNumberToDate(newSettingDayNumber);
      newWaterRec.DayNumber = newSettingDayNumber;
      this.entityManager.addEntity(newWaterRec);

      this.siteWater.push(newWaterRec);
      this.siteWater = ArrayUtils.sortByNumberWithMutation(this.siteWater, (x) => x.DayNumber);

      this.setSiteWaterId(newWaterRec.Id);
    } else if (UiHelper.isTabActive(['Settings', 'Nutrients'])) {
      if (!this.isNutrientSettingFormValid()) {
        this.showAlert();
        return;
      }

      while (this.siteNoots.some((a) => a.DayNumber == newSettingDayNumber)) {
        newSettingDayNumber++;
      }

      const nootProps = ['CropNutrientArea', 'BudgetFertiliserId', 'Comment', 'AssetId', 'SamplePointList'];
      const obj = this._siteSettingService.copyProps(this.siteNoots[this.siteNootsIndex], nootProps);
      const assetType: any = this.entityManager.metadataStore.getEntityType('SiteSettingsNutrients');
      const newSettingNutRec: SiteSettingsNutrients = assetType.createEntity(obj);
      newSettingNutRec.localDate = this._dayNumberService.convertDayNumberToDate(newSettingDayNumber);
      newSettingNutRec.DayNumber = newSettingDayNumber;
      this.entityManager.addEntity(newSettingNutRec);

      this.siteNoots.push(newSettingNutRec);
      this.siteNoots = ArrayUtils.sortByNumberWithMutation(this.siteNoots, (x) => x.DayNumber);

      this.setSiteNootsId(newSettingNutRec.Id);
    }
    // ensure the record is visible
    const newSettingDate = this._dayNumberService.convertDayNumberToDate(newSettingDayNumber);
    if (this.filterEffectiveTo <= newSettingDate) {
      this.filterEffectiveTo = newSettingDate.addDays(1);
    }
  }

  private filterDates(settings: SiteSettingBase[], date: Date): boolean {
    if (this.datepickerCurrentDayNumber == null) {
      return true;
    }

    if (date < this.minimumSettingDate) {
      return false;
    }

    const dayNumber = this._dayNumberService.convertCalendarDateToLocaleDayNumber(date);

    if (dayNumber == this.datepickerCurrentDayNumber) {
      return true;
    }

    if (settings.some((a) => a.DayNumber == dayNumber)) {
      return false;
    }

    return true;
  }

  public canEditDate(date: Date): boolean {
    if (!this.isSuperUser && moment(date).isBefore(moment(this.irrigationSeasonStart).subtract(1, 'year'))) {
      return false;
    }

    return true;
  }

  public updateSettingDate(type: string, row: SiteSettingBase) {
    // row.EffectiveDate has already changed upon entry to this routine
    row.DayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(row.localDate);

    this.datepickerCurrentDayNumber = row.DayNumber;

    switch (type) {
      case 'soil':
        this.siteSoil = ArrayUtils.sortByNumberWithMutation(this.siteSoil, (x) => x.DayNumber);
        this.setSiteSoilId(row.Id);
        break;
      case 'crop':
        this.siteCrop = ArrayUtils.sortByNumberWithMutation(this.siteCrop, (x) => x.DayNumber);
        this.setSiteCropId(row.Id);
        break;
      case 'water':
        this.siteWater = ArrayUtils.sortByNumberWithMutation(this.siteWater, (x) => x.DayNumber);
        this.setSiteWaterId(row.Id);
        break;
      case 'nutrient':
        this.siteNoots = ArrayUtils.sortByNumberWithMutation(this.siteNoots, (x) => x.DayNumber);
        this.setSiteNootsId(row.Id);
        break;
    }
    // ensure the record is visible (3479)
    if (this.filterEffectiveFrom) {
      // have we moved past the end of the filter?
      if (this.filterEffectiveTo <= row.localDate) {
        // adjust the filter to show the record
        this.filterEffectiveTo = new Date(row.localDate.valueOf()).addDays(1);
      }
      // have we moved it before the start of the filter?
      if (this.filterEffectiveFrom >= row.localDate) {
        // adjust the filter to show the record
        this.filterEffectiveFrom = new Date(row.localDate.valueOf()).addDays(-1);
      }
    }
  }

  private initMap() {
    const mapOptions = {
      cameraControl: false,
      center: new google.maps.LatLng(-26, 131),
      mapTypeControl: false,
      mapTypeControlOptions: {
        mapTypeIds: null,
      },
      mapTypeId: google.maps.MapTypeId.HYBRID,
      rotateControl: false,
      streetViewControl: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'water',
          stylers: [{ visibility: 'simplified' }, { lightness: 50 }],
        },
      ],
      tilt: 0,
      zoom: 5,
      zoomControl: true,
    } as google.maps.MapOptions;

    const configureCustomMapControls = (): void => {
      const addControl = (position: google.maps.ControlPosition, elementId): void => {
        this.map.controls[position].push(document.getElementById(elementId));
      };

      addControl(google.maps.ControlPosition.TOP_LEFT, 'map-search-placeholder')
      addControl(google.maps.ControlPosition.TOP_RIGHT, 'map-control-placeholder');

      GoogleMapUtils.UI.configureMapSearchInput(this.map, 'map-search-input');
    };

    const configureMapLayers = () =>{
      // Configure built-in map layers.
      const mapTypeIds = [];

      for (const type in google.maps.MapTypeId) {
        mapTypeIds.push(google.maps.MapTypeId[type]);
      }

      mapTypeIds.push('NDVI');

      mapOptions.mapTypeControlOptions.mapTypeIds = mapTypeIds;

      // Configure additional map layers.
      this.map.mapTypes.set(
        'NDVI',
        new google.maps.ImageMapType({
          maxZoom: 15,
          name: 'NDVI',
          tileSize: new google.maps.Size(256, 256),
          getTileUrl: (coord, zoom) => {
            return `https://tile-a.urthecast.com/v1/ndvi/${zoom}/${coord.x}/${coord.y}?${SiteController.URTH_CAST}`;
          },
        }),
      );

      this.map.mapTypes.set(
        'EVI',
        new google.maps.ImageMapType({
          maxZoom: 15,
          name: 'EVI',
          tileSize: new google.maps.Size(256, 256),
          getTileUrl: (coord, zoom) => {
            return `https://tile-a.urthecast.com/v1/evi/${zoom}/${coord.x}/${coord.y}?${SiteController.URTH_CAST}`;
          },
        }),
      );

      this.map.mapTypes.set(
        'NDWI',
        new google.maps.ImageMapType({
          maxZoom: 15,
          name: 'NDWI',
          tileSize: new google.maps.Size(256, 256),
          getTileUrl: (coord, zoom) => {
            return `https://tile-a.urthecast.com/v1/ndwi/${zoom}/${coord.x}/${coord.y}?${SiteController.URTH_CAST}`;
          },
        }),
      );

      this.map.mapTypes.set(
        'FNIR',
        new google.maps.ImageMapType({
          maxZoom: 15,
          name: 'False-color NIR',
          tileSize: new google.maps.Size(256, 256),
          getTileUrl: (coord, zoom) => {
            return `https://tile-a.urthecast.com/v1/false-color-nir/${zoom}/${coord.x}/${coord.y}?${SiteController.URTH_CAST}`;
          },
        }),
      );

      this.map.mapTypes.set(
        'OTM',
        new google.maps.ImageMapType({
          maxZoom: 18,
          name: 'OpenTopoMap',
          tileSize: new google.maps.Size(256, 256),
          getTileUrl: (coord, zoom) => {
            return `http://tile.opentopomap.org/${zoom}/${coord.x}/${coord.y}.png`;
          },
        }),
      );

      this.map.mapTypes.set(
        'ESRI',
        new google.maps.ImageMapType({
          maxZoom: 18,
          name: 'ESRI World Imagery',
          tileSize: new google.maps.Size(256, 256),
          getTileUrl: (coord, zoom) => {
            return `http://fuse.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/${zoom}/${coord.y}/${coord.x}.jpg`
            ;
          },
        }),
      );
    };

    const configureLocatorButton = () => {
      const locatorButtonDiv = document.createElement('div');
      const locatorButton = document.createElement('button');

      locatorButton.style.backgroundColor = '#fff';
      locatorButton.style.border = 'none';
      locatorButton.style.outline = 'none';
      locatorButton.style.width = '28px';
      locatorButton.style.height = '28px';
      locatorButton.style.borderRadius = '2px';
      locatorButton.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
      locatorButton.style.cursor = 'pointer';
      locatorButton.style.marginRight = '10px';
      locatorButton.style.padding = '0';
      locatorButton.title = this._languageService.instant('COMMON.YOUR_LOCATION');
      locatorButton.translate = true;

      locatorButtonDiv.appendChild(locatorButton);

      const locatorBtnImage = document.createElement('div');

      locatorBtnImage.style.margin = '5px';
      locatorBtnImage.style.width = '18px';
      locatorBtnImage.style.height = '18px';
      locatorBtnImage.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png)';
      locatorBtnImage.style.backgroundSize = '180px 18px';
      locatorBtnImage.style.backgroundPosition = '0 0';
      locatorBtnImage.style.backgroundRepeat = 'no-repeat';

      locatorButton.appendChild(locatorBtnImage);

      google.maps.event.addListener(this.map, 'center_changed', () => {
        locatorBtnImage.style['background-position'] = '0 0';
      });

      let locatorMarker: google.maps.Marker = null;

      locatorButton.addEventListener('click', () => {
        let imgX = '0';

        const animationInterval = setInterval(() => {
          // Updating of the sprite's (image) 'x' value on interval mimics the image changing colour (e.g., black/grey alternating).
          imgX = imgX === '-18' ? '0' : '-18';
          locatorBtnImage.style['background-position'] = `${imgX}px 0`;
        }, 200);

        // Delay the execution to allow visibility of locator button animation (i.e., image changing colour).
        setTimeout(() => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              clearInterval(animationInterval);

              locatorMarker?.setMap(null);

              const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

              locatorMarker = new google.maps.Marker({
                animation: google.maps.Animation.DROP,
                icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                map: this.map,
                position: latLng,
              });

              this.map.setCenter(latLng);

              // Delay the image colour change of the locator button to ensure the 'center_changed' event is completed (after the 'setCenter' call),
              // so that we achieve the desired image colour upon immediate completion of the locator action.
              setTimeout(() => {
                locatorBtnImage.style['background-position'] = '-144px 0'; // This 'x' value represents 'blue' image of the sprite image.
              }, 200);
            });
          } else {
            clearInterval(animationInterval);
            locatorBtnImage.style['background-position'] = '0 0';
          }
        }, 1000);
      });

      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(locatorButtonDiv);
    };

    const configureDrawingManager = () => {
      this._drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.CIRCLE,
          ],
        },
        map: this.map,
      });

      google.maps.event.addListener(this._drawingManager, 'overlaycomplete', (e: google.maps.drawing.OverlayCompleteEvent) => {
        this.resetSelectedMarkers();

        this._notifyingService.notify(NotifyEvents.Map.DrawingModeSelected, { drawingMode: 'Hand' });

        const assetClassName = this.assetClass.name;
        const shapeType = e.type;
        const marker = e.overlay as google.maps.Marker;

        let zIndex = 0;

        // 1. Get shape X,Y - based on shapeType ('marker', 'polygon' or 'circle')
        // 2. If not shape, test to see if within an IMU Site polygon
        // 3. Add Record to Asset Table
        // 4. Add Sensor Record - based on assetClass
        // 5. Define new object - based on shapeType
        // 6. Push to siteAsset

        if (shapeType === 'marker') {
          this._mdSidenav('right').open();

          if (!this.assetClass) {
            marker.setMap(null);

            this._languageService.warning('AC.SITE.MSG.CLASS_REQUIRED');

            return;
          }

          zIndex = SiteController.SITE_MARKER_Z_IDX;

          // #region Add Record to Asset Table
          const latLng = marker.getPosition();
          const shapeGeom = new google.maps.Data.Point(latLng);
          const latitude = parseFloat(latLng.lat().toFixed(5));
          const longitude = parseFloat(latLng.lng().toFixed(5));

          this._mapService.getTimeZone(latitude, longitude).then(
            (tz: string) => {
              const tzData = JSON.parse(tz) as IGoogleTimeZone;

              if (tzData.status == 'ZERO_RESULTS') {
                marker.setMap(null);

                this._mdSidenav('right').close();
                this._languageService.error('AC.SITE.MSG.LOCATION_INVALID');

                return;
              }

              const assetType = this.entityManager.metadataStore.getEntityType('Asset') as breeze.EntityType;
              const newAsset = assetType.createEntity() as Asset;

              this.entityManager.addEntity(newAsset);

              newAsset.OwnerAccountId = this.accountId;
              newAsset.AssetClassId = this.assetClass.id;
              newAsset.Latitude = latitude;
              newAsset.Longitude = longitude;
              newAsset.Status = 'Active';
              newAsset.TZStandardName = tz;

              const newMarker = new google.maps.Marker({
                icon: {
                  url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.assetClass.iconURL),
                },
                map: this.map,
                position: latLng,
                zIndex: zIndex,
              });

              const markerContent = this.markerPopup(assetClassName, assetClassName, newAsset.Status, true);

              newMarker.setValues({
                AssetClass: this.assetClass,
                Elevation: newAsset.Elevation,
                assetClass: assetClassName,
                assetClassId: this.assetClass.id,
                geoJSON: newAsset.GeoJSON,
                id: newAsset.AssetId,
                isNew: true,
                name: markerContent,
                paths: null,
                status: newAsset.Status,
                timeZoneName: tzData.timezonename,
              });

              this.attachMarkerEvents(newMarker);

              const promises: angular.IPromise<void>[] = [];

              const shapeAttrs = {
                assetClass: assetClassName,
                id: newAsset.AssetId,
                name: newAsset.Name,
              };

              promises.push(
                this.getShapeGeoJSON(shapeGeom, shapeAttrs).then((shapeJSON) => {
                  newAsset.GeoJSON = shapeJSON;
                  newMarker.set('geoJSON', shapeJSON);
                }),
              );

              promises.push(
                this._mapService.getElevation(newAsset.Latitude, newAsset.Longitude).then((elev: number) => {
                  // Round it here to one decimal.
                  newAsset.Elevation = NumberUtils.round(elev, 1);
                  newMarker.set('Elevation', newAsset.Elevation);
                }),
              );

              this._q.all(promises).then(() => {
                this._siteAssets.push(newAsset);
                this._siteService.selectedMarker = newMarker;
                this._siteService.selectedAsset = newAsset;
                this.reloadCounter++;

                this.siteMarkers.push(newMarker);

                // Add a sensor record.
                if (this.assetClass.hasSensor) {
                  switch (assetClassName) {
                    case 'Water Sample Point':
                      this.createWaterSamplePoint(newAsset.AssetId);
                      break;
                    case 'Site Health Point':
                      this.getShapeGeoJSON(shapeGeom, shapeAttrs).then((shapeJSON) => {
                        this.createSensorWithValueRange(newAsset.AssetId, assetClassName, shapeJSON);
                      });
                      break;
                    case 'Water Flow Meter':
                    case 'Weather Station':
                    case 'Soil Moisture':
                    case 'Stem Water Potential Meter':
                      this.getShapeGeoJSON(shapeGeom, shapeAttrs).then((shapeJSON) => {
                        this.createSensorWithValueRange(newAsset.AssetId, assetClassName, shapeJSON);
                      });
                      break;
                    default:
                      break;
                  }
                }

                const weatherStationClass = SWANConstants.assetClasses.find((a) => a.name == 'Weather Station');
                const defaultPriority = newAsset.AssetClassId == weatherStationClass.id ? null : 1;
                const siteAssetType: any = this.entityManager.metadataStore.getEntityType('SiteAsset');

                this.selectedIMUSites.forEach((site) => {
                  // Pairing the selectedIMUSite id with the sensor.
                  // Add parent (Site) to child (new Asset) to see on map.
                  const siteAssetInitialValues = {
                    ParentAssetId: site.id,
                    ChildAssetId: newAsset.AssetId, // Sensor Asset Id
                    DataInputId: 1, // Default for IMU Management
                    Coefficient: 1,
                    Priority: 1,
                    Factor: 1,
                  };
                  const newSiteAsset = siteAssetType.createEntity(siteAssetInitialValues);

                  this.entityManager.addEntity(newSiteAsset);
                  this.equipmentParentAssets.push(newSiteAsset);

                  // We don't want weather stations to be linked.
                  if (this.assetClass.id !== weatherStationClass.id) {
                    // Add parent (new asset) to child (site) to see on map.
                    const pcAssetInitialValues = {
                      ParentAssetId: newAsset.AssetId, // Sensor Asset Id
                      ChildAssetId: site.id,
                      DataInputId: this.assetClass.dataInputId,
                      Coefficient: 1,
                      Priority: defaultPriority,
                      Factor: 1,
                    };

                    const pcSiteAsset = siteAssetType.createEntity(pcAssetInitialValues);

                    this.entityManager.addEntity(pcSiteAsset);
                    this.equipmentParentAssets.push(pcSiteAsset);
                  }
                });

                if (this.asset_.AssetId > 0) {
                  this.asset_.entityAspect.setModified();
                }

                // NOTE: clear the original overlay because we use our custom marker
                marker.setMap(null);
              });
            },
            (error) => {
              console.log(error);
            },
          );
          // #endregion
        }

        if (shapeType === 'circle' || shapeType === 'polygon') {
          let circle: google.maps.Circle;
          let polygon: google.maps.Polygon;
          let paths: google.maps.LatLng[];
          let newAssetDataInput = 1;

          // Variables for polygon & circle creation.
          let polygonName = '';
          let isExceedMaxIMU = false;
          let isExceedSiteArea = false;
          let isHealthAreaOutside = false;

          const strDate = new Date().toISOString();

          if (shapeType === 'circle') {
            circle = e.overlay as google.maps.Circle;
            // Convert circle straight to polygon
            const gDataPolygon: google.maps.Data.Polygon = this._mapService.generateGeoJSONCircle(
              circle.getCenter(),
              circle.getRadius(),
              30,
            );

            paths = gDataPolygon.getArray()[0].getArray();
          } else { // shapeType === 'polygon'
            polygon = e.overlay as google.maps.Polygon;
            paths = [];

            polygon.getPath().forEach((item) => {
              const isNewLatLng = !paths.find(p => p.lat() === item.lat() && p.lng() === item.lng());

              if (isNewLatLng) {
                const lat = item.lat();
                const lng = item.lng();

                paths.push(new google.maps.LatLng(lat, lng));
              }
            });

            const isValidPolygon = paths.length >= 3;

            if (!isValidPolygon) {
              marker.setMap(null);

              this._languageService.warning('AC.SITE.MSG.POLYGON_ERROR');

              return;
            }
          }

          this._mdSidenav('right').open();

          const newPolygon = new google.maps.Polygon({ paths: paths });
          const polygonCentre = GoogleMapUtils.Polygons.getCentre(newPolygon);

          // Check asset location.
          this._mapService.getTimeZone(polygonCentre.lat, polygonCentre.lng).then(
            (tz: string) => {
              let isInvalidLocation = false;
              let stroke: any = {};
              let fill: any = {};
              const tzData = JSON.parse(tz) as IGoogleTimeZone;
              const newPolygonPath = newPolygon.getPath();

              if (tzData.status == 'ZERO_RESULTS') {
                isInvalidLocation = true;
              } else {
                if (AssetUtils.isSiteIMU(assetClassName)) {
                  this.newSitePoly = true;
                  this.asset_.Paths = paths;

                  polygonName = this.asset_.Name.trim();

                  let siteArea = 0;

                  if (shapeType === 'circle') {
                    siteArea = (Math.PI * circle.getRadius() * circle.getRadius()) / 10000;
                  } else if (shapeType === 'polygon') {
                    siteArea = google.maps.geometry.spherical.computeArea(newPolygonPath.getArray()) / 10000;

                    if (GoogleMapUtils.isSelfIntersected(newPolygonPath.getArray())) {
                      this.isPolygonError = true;
                      this._languageService.error('AC.SITE.MSG.IMU_INTERSECT_ERROR');
                    } else {
                      this.isPolygonError = false;
                    }
                  }

                  google.maps.event.addListener(newPolygonPath, 'set_at', this.polygonEvents.set_at);
                  google.maps.event.addListener(newPolygonPath, 'insert_at', this.polygonEvents.insert_at);

                  newPolygon.addListener('dragend', this.polygonEvents.dragend);
                  newPolygon.addListener('click', this.polygonEvents.click);

                  isExceedSiteArea = siteArea > 10000;

                  if (this.totalSiteIMU + 1 > 1) {
                    isExceedMaxIMU = true;
                  }

                  if (!isExceedSiteArea) {
                    this.site_.Area = siteArea;
                    this.siteWater[this.siteWaterIndex].CropWaterArea = siteArea;
                    this.siteNoots[this.siteNootsIndex].CropNutrientArea = siteArea;
                  }

                  this.asset_.Latitude = polygonCentre.lat;
                  this.asset_.Longitude = polygonCentre.lng;

                  zIndex = SiteController.SITE_BASE_Z_IDX;

                  stroke = {
                    color: '#795548',
                    opacity: 0.8,
                    weight: 2,
                  };

                  fill = {
                    color: '#D7CCC8',
                    opacity: 0.2,
                  };

                  newAssetDataInput = 1;

                  this._mdSidenav('right').close();
                } else if (AssetUtils.isSiteHealthArea(assetClassName)) {
                  polygonName = this._languageService.instant('AC.SITE.MAPS.NEW_HEALTH_AREA', { date: strDate });

                  if (!this.siteIMUPolygon) {
                    isHealthAreaOutside = true;
                  } else {
                    newPolygonPath.forEach((vertex) => {
                      if (!google.maps.geometry.poly.containsLocation(vertex, this.siteIMUPolygon)) {
                        isHealthAreaOutside = true;
                      }
                    });

                    if (!isHealthAreaOutside) {
                      if (GoogleMapUtils.isSelfIntersected(newPolygonPath.getArray())) {
                        this.isHealthAreaIntersected = true;
                      } else {
                        this.isHealthAreaIntersected = false;
                      }
                    }
                  }

                  zIndex = SiteController.SITE_AREA_Z_IDX;

                  stroke = {
                    color: '#4CAF50',
                    opacity: 0.8,
                    weight: 2,
                  };

                  fill = {
                    color: '#C8E6C9',
                    opacity: 0.2,
                  };

                  // Trigger something to enable save changes but it doesn't seem to be adding the item as a Asset (Sensor)
                  // this.siteWater[this.siteWaterIndex].CropWaterArea = siteHealthArea > this.site_.Area ? this.site_.Area : siteHealthArea;
                  this.asset_.Name = this.asset_.Name + ' '; //Something needs to trigger Save Button
                  this.asset_.Name = this.asset_.Name.trim();
                  newAssetDataInput = 3;

                  google.maps.event.addListener(newPolygonPath, 'set_at', this.polygonEvents.set_at);
                  google.maps.event.addListener(newPolygonPath, 'insert_at', this.polygonEvents.insert_at);
                }
              }

              const showWarning = (translationKey: string) => {
                circle?.setMap(null);
                polygon?.setMap(null);

                this._mdSidenav('right').close();

                this._languageService.warning(translationKey);
              };

              if (isExceedMaxIMU) {
                showWarning('AC.SITE.MSG.MAX_ONE_IMU')
                return;
              }

              if (isExceedSiteArea) {
                showWarning('AC.SITE.MSG.IMU_AREA_ERROR');
                return;
              }

              // global checks
              if (isHealthAreaOutside) {
                showWarning('AC.SITE.MSG.EXISTING_AREA_ERROR');
                return;
              }

              if (isInvalidLocation) {
                showWarning('AC.SITE.MSG.LOCATION_INVALID');
                return;
              }

              if (this.isHealthAreaIntersected) {
                this._languageService.error('AC.SITE.MSG.SHA_INTERSECT_ERROR');
              }

              this.asset_.entityAspect.setModified();

              // Create the new entity.
              let newEntityId = 0;

              if (AssetUtils.isSiteWaterArea(assetClassName) || AssetUtils.isSiteHealthArea(assetClassName)) {
                const assetType = this.entityManager.metadataStore.getEntityType('Asset') as breeze.EntityType;
                const newAsset = assetType.createEntity() as Asset;

                newAsset.Name = '';
                newAsset.OwnerAccountId = this.accountId;
                newAsset.AssetClassId = this.assetClass.id;
                newAsset.Latitude = parseFloat(polygonCentre?.lat.toFixed(5));
                newAsset.Longitude = parseFloat(polygonCentre?.lng.toFixed(5));
                newAsset.Status = 'Active';

                const shapeAttrs = { assetClass: assetClassName, id: newAsset.AssetId, name: newAsset.Name };

                this.getShapeGeoJSON(this.getDataPolygon(newPolygon), shapeAttrs).then(
                  (shapeJSON) => {
                    // now add a sensor record
                    if (!this.assetClass.hasSensor) {
                      return;
                    }

                    newAsset.GeoJSON = shapeJSON;

                    this.createSensorWithValueRange(newAsset.AssetId, assetClassName, shapeJSON);
                  },
                );

                this.entityManager.addEntity(newAsset);
                this.asset_.entityAspect.setModified();

                this._mapService.getTimeZone(newAsset.Latitude, newAsset.Longitude).then(
                  (tz: string) => {
                    newAsset.TZStandardName = tz;
                  },
                  (error) => {
                    console.log(error);
                  },
                );

                this._mapService.getElevation(newAsset.Latitude, newAsset.Longitude).then((elev: number) => {
                  newAsset.Elevation = 0.1 * Math.round(elev * 10);
                });

                this._siteAssets.push(newAsset);
                this._siteService.selectedAsset = newAsset;

                //Pass the new entity ID for use in creating polygons, GeoJson Ids, etc
                newEntityId = newAsset.AssetId;
              }

              let newId = newEntityId;

              if (AssetUtils.isSiteIMU(assetClassName)) {
                newId = this.siteId;
                this._siteService.isSiteGeometryCreated = true;
                this._siteService.siteGeometryArea =
                  google.maps.geometry.spherical.computeArea(newPolygonPath.getArray()) / 10000;
              }

              // make the newId the entity id (except for site)
              newPolygon.set('id', newId);
              newPolygon.set('name', polygonName);
              newPolygon.set('isNew', true);
              newPolygon.set('assetClassId', this.assetClass.id);
              newPolygon.set('latitude', parseFloat(polygonCentre?.lat.toFixed(5)));
              newPolygon.set('longitude', parseFloat(polygonCentre?.lng.toFixed(5)));
              newPolygon.set('assetClass', assetClassName);
              newPolygon.set('fillColor', fill.color);
              newPolygon.set('fillOpacity', fill.opacity);
              newPolygon.set('strokeColor', stroke.color);
              newPolygon.set('strokeOpacity', stroke.opacity);
              newPolygon.set('strokeWeight', stroke.weight);
              newPolygon.set('editable', this._siteService.isEditMode);
              newPolygon.set('draggable', this._siteService.isEditMode);

              // Add Site Area (Health, Water) Polygons
              if (AssetUtils.isSiteWaterArea(assetClassName) || AssetUtils.isSiteHealthArea(assetClassName)) {
                const shapeAttrs = { assetClass: assetClassName, id: newId, name: polygonName };

                this.getShapeGeoJSON(this.getDataPolygon(newPolygon), shapeAttrs).then((shapeJSON) => {
                  // now add a sensor record
                    newPolygon.set('geoJSON', shapeJSON);

                  for (let idx2 = 0; idx2 < this.selectedIMUSites.length; idx2++) {
                    // Pairing the selectedIMUSite id with the sensor
                    const sId = this.asset_.AssetId; // this.selectedIMUSites[idx2].id;
                    const siteAssetInitialValues = {
                      ParentAssetId: sId, // Site - Asset Id
                      ChildAssetId: newId, // Sensor Asset Id
                      DataInputId: 1, // Default for IMU Management
                      Coefficient: 1,
                      Priority: 1,
                      Factor: 1,
                    };

                    const siteAssetType: any = this.entityManager.metadataStore.getEntityType('SiteAsset');
                    const newSiteAsset = siteAssetType.createEntity(siteAssetInitialValues);

                    this.entityManager.addEntity(newSiteAsset);
                    this.equipmentParentAssets.push(newSiteAsset);
                  }

                  // dataInput is the newAsset.AssetClass.DataInputId
                  for (let idx4 = 0; idx4 < this.selectedIMUSites.length; idx4++) {
                    // Pairing the selectedIMUSite id with the sensor
                    const sId = this.asset_.AssetId; // this.selectedIMUSites[idx4].id;
                    const pcAssetInitialValues = {
                      ParentAssetId: newId, // Sensor Asset Id
                      ChildAssetId: sId, // Site - Asset Id
                      DataInputId: newAssetDataInput, // dataInput is the new newAsset.AssetClass.DataInputId
                      Coefficient: 1,
                      Priority: 1,
                      Factor: 1,
                    };

                    const pcAssetType: any = this.entityManager.metadataStore.getEntityType('SiteAsset');
                    const pcSiteAsset = pcAssetType.createEntity(pcAssetInitialValues);

                    this.entityManager.addEntity(pcSiteAsset);
                    this.equipmentParentAssets.push(pcSiteAsset);
                  }
                });
              }

              if (AssetUtils.isSiteIMU(assetClassName)) {
                this.setSiteCoord(parseFloat(polygonCentre?.lat.toFixed(5)), parseFloat(polygonCentre?.lng.toFixed(5)));
                this.totalSiteIMU += 1;
                this.siteIMUPolygon = newPolygon;
              }

              // Remove any remaining shapes created by drawing manager.
              circle?.setMap(null);
              polygon?.setMap(null);

              // Add newly created polygon shape to the map (NOTE: Both circle and polygon overlays are converted to polygon shape).
              newPolygon.setMap(this.map);

              this.sitePolygons.push(newPolygon);
            },
            (error) => {
              console.log(error);
            },
          );
        }
      });
    };

    const mapContainer = document.getElementById('site-google-map');

    if (!this.map) {
      this.map = new google.maps.Map(mapContainer, mapOptions);

      this._siteService.setSiteMap(this.map);

      configureCustomMapControls();
      configureMapLayers();
      configureDrawingManager();
      configureLocatorButton();
    }

    this.loadSiteDataToMap();
  }

  private getSitePolygonFeature(): Feature<Polygon> {
    const geoJSON = JSON.parse(this.asset_.GeoJSON) as FeatureCollection;

    if (!geoJSON) {
      return null;
    }

    const siteFeature = geoJSON.features.find((f) =>
      f.geometry.type === GeometryTypeEnum.Polygon
      && (AssetUtils.ByAssetIds.isSite(f.properties.assetClassId))
    );

    return siteFeature as Feature<Polygon>;
  }

  private loadSiteDataToMap(): void {
    if (!this.asset_) {
      return;
    }

    const clearMap = (): void => {
      // Clear polygons.
      this.sitePolygons.forEach((polygon) => {
        polygon.setMap(null);
      });

      this.sitePolygons = [];

      // Clear markers.
      this.siteMarkers.forEach((marker) => {
        marker.setMap(null);
      });

      this.siteMarkers = [];
    };

    const centreMapAtSiteLocationOrDefault = () => {
      let mapCenterLat: number = 0;
      let mapCenterLng: number = 0;
      let countAccepted: number = 0;
      let zoom = 5;

      // Find neighbouring IMU(s) to generate average bounding location
      const querySite = new breeze.EntityQuery('Sites').withParameters({ accountId: this.accountId, SiteId: this.siteId });

      this.entityManager.executeQuery(querySite).then(
        (data) => {
          const latlngbounds = new google.maps.LatLngBounds();

          for (let i = 0; i < data.results.length; i++) {
            const coord: any = data.results[i];
            const isCoordDefaultLatLon: boolean = !coord.GeoJSON;

            if (!isCoordDefaultLatLon && coord.Status == StatusEnum.Active) {
              latlngbounds.extend(new google.maps.LatLng(coord.Latitude, coord.Longitude));
              countAccepted++;
              mapCenterLat += coord.Latitude;
              mapCenterLng += coord.Longitude;
            }
          }

          if (countAccepted) {
            mapCenterLat /= countAccepted;
            mapCenterLng /= countAccepted;
          }

          if (!this.asset_.GeoJSON) {
            // new site?
            if (countAccepted > 1) {
              // Show main map of extents of all sites.
              this.map.fitBounds(latlngbounds);
            } else if (countAccepted == 1) {
              // centre on existing single (valid) site
              this.map.setCenter(new google.maps.LatLng(mapCenterLat, mapCenterLng));
              this.map.setZoom(15);
            } else {
              //centre map on the account default lat/lng
              this.map.setCenter(new google.maps.LatLng(this.siteAccountInfo.defaultLatitude, this.siteAccountInfo.defaultLongitude));
              this.map.setZoom(15);
            }
          } else {
            const siteFeature = this.getSitePolygonFeature();
            let usedPolygonToFit = 0;

            if (siteFeature) {
              const bounds = new google.maps.LatLngBounds();

              for (let j = 0; j < siteFeature.geometry.coordinates[0].length; j++) {
                const coord = siteFeature.geometry.coordinates[0][j];
                const point = new google.maps.LatLng(coord[1], coord[0]);

                bounds.extend(point);
              }

              this.map.fitBounds(bounds);
              usedPolygonToFit = 1;
            }

            // No bounds calculated - use the map center
            if (!usedPolygonToFit) {
              mapCenterLat = parseFloat(this.asset_.Latitude.toFixed(5));
              mapCenterLng = parseFloat(this.asset_.Longitude.toFixed(5));
              zoom = 16;

              this.map.setCenter(new google.maps.LatLng(mapCenterLat, mapCenterLng));
              this.map.setZoom(zoom);
            }
          }
        },
        (error) => {
          console.log('Error: Could not load Account Sites latLng (' + error.message + ')');
        },
      );
    }

    clearMap();

    this.loadSiteGeoJson();
    this.loadSiteChildAssets();

    centreMapAtSiteLocationOrDefault();
  }

  private createWaterSamplePoint(newAssetId: number) {
    const sensorType = this.entityManager.metadataStore.getEntityType('Sensor') as breeze.EntityType;
    const newSensor = sensorType.createEntity() as Sensor;
    newSensor.AssetId = newAssetId;
    this.entityManager.addEntity(newSensor);
  }

  private createSensorWithValueRange(newAssetId: number, assetClassName: string, geoJSON: string) {
    if (AssetUtils.isSiteHealthPoint(assetClassName) || AssetUtils.isSiteHealthArea(assetClassName)) {
      const valueRangeType: any = this.entityManager.metadataStore.getEntityType('ValueRange');
      this.siteAccountInfo.healthIndexDataInputs.forEach((dataInput) => {
        const irrigationStart = moment(
          new Date(Date.UTC(2010, this.siteAccountInfo.irrigationSeasonStartMonth - 1, 1, 0, 0, 0, 0)),
        ).toDate();
        const irrigationEnd = moment(irrigationStart).add(1, 'years').subtract(1, 'days').toDate();
        const defaultReadingValueRange = this.siteAccountInfo.defaultValueRanges.find(
          (a) => a.attribute == 'Reading' && a.dataInputId == dataInput.dataInputId,
        );
        const newReadingValueRange: ValueRange = valueRangeType.createEntity();
        newReadingValueRange.AssetId = newAssetId;
        newReadingValueRange.Property = 'ObsHealthIndex';
        newReadingValueRange.Attribute = 'Reading';
        newReadingValueRange.ReadingHigh = !defaultReadingValueRange ? 0.8 : defaultReadingValueRange.readingHigh;
        newReadingValueRange.ReadingLow = !defaultReadingValueRange ? 0.6 : defaultReadingValueRange.readingLow;
        newReadingValueRange.EffectiveFrom = moment(irrigationStart).toDate();
        newReadingValueRange.DataInputId = dataInput.dataInputId;
        newReadingValueRange.DayNumber = this._dayNumberService.convertDateToLocaleDayNumber(
          moment(newReadingValueRange.EffectiveFrom).toDate(),
          'UTC',
        );
        this.entityManager.addEntity(newReadingValueRange);
        const newReadingValueRangeEnd: ValueRange = valueRangeType.createEntity();
        newReadingValueRangeEnd.AssetId = newAssetId;
        newReadingValueRangeEnd.Property = 'ObsHealthIndex';
        newReadingValueRangeEnd.Attribute = 'Reading';
        newReadingValueRangeEnd.ReadingHigh = !defaultReadingValueRange ? 0.8 : defaultReadingValueRange.readingHigh;
        newReadingValueRangeEnd.ReadingLow = !defaultReadingValueRange ? 0.6 : defaultReadingValueRange.readingLow;
        newReadingValueRangeEnd.EffectiveFrom = moment(irrigationEnd).toDate();
        newReadingValueRangeEnd.DataInputId = dataInput.dataInputId;
        newReadingValueRangeEnd.DayNumber = this._dayNumberService.convertDateToLocaleDayNumber(
          moment(newReadingValueRangeEnd.EffectiveFrom).toDate(),
          'UTC',
        );
        this.entityManager.addEntity(newReadingValueRangeEnd);
        const defaultVariabilityValueRange = this.siteAccountInfo.defaultValueRanges.find(
          (a) => a.attribute == 'Variability' && a.dataInputId == dataInput.dataInputId,
        );
        const newVariabilityValueRange: ValueRange = valueRangeType.createEntity();
        newVariabilityValueRange.AssetId = newAssetId;
        newVariabilityValueRange.Property = 'ObsHealthIndex';
        newVariabilityValueRange.Attribute = 'Variability';
        newVariabilityValueRange.ReadingHigh = !defaultReadingValueRange ? 0.3 : defaultVariabilityValueRange.readingHigh;
        newVariabilityValueRange.ReadingLow = !defaultReadingValueRange ? 0.2 : defaultVariabilityValueRange.readingLow;
        newVariabilityValueRange.EffectiveFrom = moment(irrigationStart).toDate();
        newVariabilityValueRange.DataInputId = dataInput.dataInputId;
        newVariabilityValueRange.DayNumber = this._dayNumberService.convertDateToLocaleDayNumber(
          moment(newVariabilityValueRange.EffectiveFrom).toDate(),
          'UTC',
        );
        this.entityManager.addEntity(newVariabilityValueRange);
        const newVariabilityValueRangeEnd: ValueRange = valueRangeType.createEntity();
        newVariabilityValueRangeEnd.AssetId = newAssetId;
        newVariabilityValueRangeEnd.Property = 'ObsHealthIndex';
        newVariabilityValueRangeEnd.Attribute = 'Variability';
        newVariabilityValueRangeEnd.ReadingHigh = !defaultReadingValueRange ? 0.3 : defaultVariabilityValueRange.readingHigh;
        newVariabilityValueRangeEnd.ReadingLow = !defaultReadingValueRange ? 0.2 : defaultVariabilityValueRange.readingLow;
        newVariabilityValueRangeEnd.EffectiveFrom = moment(irrigationEnd).toDate();
        newVariabilityValueRangeEnd.DataInputId = dataInput.dataInputId;
        newVariabilityValueRangeEnd.DayNumber = this._dayNumberService.convertDateToLocaleDayNumber(
          moment(newVariabilityValueRangeEnd.EffectiveFrom).toDate(),
          'UTC',
        );
        this.entityManager.addEntity(newVariabilityValueRangeEnd);
      });
    } else {
      const sensorType = this.entityManager.metadataStore.getEntityType('Sensor') as breeze.EntityType;
      const newSensor = sensorType.createEntity() as Sensor;
      newSensor.AssetId = newAssetId;
      this.entityManager.addEntity(newSensor);
    }
  }

  private markerPopup(name: string, cls: string, stat: string, isNew: boolean = false) {
    cls = this._languageService.instant('DB_VALUES.ASSETCLASS.' + cls);
    stat = this._languageService.instant('COMMON.' + stat);

    if (isNew) {
      name = this._languageService.instant('AC.SITE.MAPS.NEW', { name: cls });
    }

    const content = this._languageService.instant('AC.SITE.MAPS.POPUP', { name: name, class: cls, status: stat });

    return content;
  }

  private loadSiteGeoJson() {
    const siteFeature = this.getSitePolygonFeature();

    if (!siteFeature) {
        this._siteService.isSiteGeometryCreated = false;
        this.totalSiteIMU = 0;

        return;
    }

    const paths = (siteFeature.geometry as Polygon).coordinates[0].map(coord => ({ lat: coord[1], lng: coord[0] }));

    this.asset_.Paths = paths;

    const polygonOptions = {
      zIndex: 0,
    } as google.maps.PolygonOptions;

    polygonOptions.paths = paths;
    polygonOptions.strokeColor = '#795548';
    polygonOptions.strokeOpacity = 0.8;
    polygonOptions.strokeWeight = 4;
    polygonOptions.fillColor = '#D7CCC8';
    polygonOptions.fillOpacity = 0.2;
    polygonOptions.zIndex = SiteController.SITE_BASE_Z_IDX;

    this.siteIMUPolygon = new google.maps.Polygon(polygonOptions);
    this.totalSiteIMU += 1;
    this._siteService.siteGeometryArea = google.maps.geometry.spherical.computeArea(paths) / 10000;
    this._siteService.isSiteGeometryCreated = true;

    const sitePolygon = new google.maps.Polygon({
      draggable: this._siteService.isEditMode,
      editable: this._siteService.isEditMode,
      fillColor: polygonOptions.fillColor,
      fillOpacity: polygonOptions.fillOpacity,
      paths: paths,
      strokeColor: polygonOptions.strokeColor,
      strokeOpacity: polygonOptions.strokeOpacity,
      strokeWeight: polygonOptions.strokeWeight,
      zIndex: polygonOptions.zIndex,
    });

    const polygonCentre = GoogleMapUtils.Polygons.getCentre(sitePolygon);

    sitePolygon.set('id', this.site_.AssetId);

    this.asset_.Paths = paths;

    google.maps.event.addListener(sitePolygon.getPath(), 'set_at', this.polygonEvents.set_at);
    google.maps.event.addListener(sitePolygon.getPath(), 'insert_at', this.polygonEvents.insert_at);
    //google.maps.event.addListener(areaPolygon.getPath(), 'remove_at', this.onPolygonEvents.dragend);
    sitePolygon.addListener('dragend', this.polygonEvents.dragend);
    sitePolygon.addListener('click', this.polygonEvents.click);

    sitePolygon.set('assetClass', siteFeature.properties.assetClass);
    sitePolygon.set('assetClassId', siteFeature.properties.assetClassId);
    sitePolygon.set('isNew', false);
    sitePolygon.set('latitude', polygonCentre.lat);
    sitePolygon.set('longitude', polygonCentre.lng);
    sitePolygon.set('name', this.site_.Asset.Name);
    sitePolygon.set('title', siteFeature.properties.assetClass);

    sitePolygon.setMap(this.map);

    this.sitePolygons.push(sitePolygon);
  }

  private loadSiteChildAssets() {
    this.asset_.ChildAssets.forEach((x) => {
      const siteAsset = x.ChildAsset;

      if (siteAsset.Status !== 'Active') {
        return;
      }

      const assetClass = SWANConstants.assetClasses.find((a) => a.id == siteAsset.AssetClassId);

      if (assetClass.mappable === 'Polygon') {
        const polygonOptions = {} as google.maps.PolygonOptions;

        polygonOptions.zIndex = SiteController.SITE_BASE_Z_IDX;

        // geoJSON data now needs to be stored as {} in database, not as [] (but required for polygon drawing).
        let geoJSON: Feature<Polygon>;

        try {
          geoJSON =
            (siteAsset.GeoJSON.substring(1, 0) == '{'
              ? JSON.parse('[' + siteAsset.GeoJSON + ']')
              : JSON.parse(siteAsset.GeoJSON))[0];
        } catch (err) {
          console.log(err);
        }

        if (geoJSON) {
          const setPolygonOptions = (): void => {
            if (![AssetClassEnum.SiteHealthArea, AssetClassEnum.SiteWaterArea].includes(siteAsset.AssetClassId)) {
              return;
            }

            const isSiteHealthArea = AssetUtils.ByAssetIds.isSiteHealthArea(siteAsset.AssetClassId);

            polygonOptions.fillColor = isSiteHealthArea ? '#008000' : '#B3E5FC';
            polygonOptions.fillOpacity = 0.2;
            polygonOptions.strokeColor = isSiteHealthArea ? '#008000' : '#03A9F4';
            polygonOptions.strokeOpacity = 0.8;
            polygonOptions.strokeWeight = 2;
            polygonOptions.zIndex = SiteController.SITE_AREA_Z_IDX;
          };

          // Try/catch block just in case the geoJSON is stuffed up and cannot be read.
          try {
            const latLngData = GoogleMapUtils.Polygons.toLatLngArrays(geoJSON);

            latLngData.forEach((setOfPolygons) => {
              setOfPolygons.forEach((polygonCoords) => {
                this.asset_.Paths = polygonCoords;
                setPolygonOptions();

                const childPolygon = new google.maps.Polygon({
                  draggable: this._siteService.isEditMode,
                  editable: this._siteService.isEditMode,
                  fillColor: polygonOptions.fillColor,
                  fillOpacity: polygonOptions.fillOpacity,
                  paths: polygonCoords,
                  strokeColor: polygonOptions.strokeColor,
                  strokeOpacity: polygonOptions.strokeOpacity,
                  strokeWeight: polygonOptions.strokeWeight,
                  zIndex: polygonOptions.zIndex,
                });

                const polygonCentre = GoogleMapUtils.Polygons.getCentre(childPolygon);

                google.maps.event.addListener(childPolygon.getPath(), 'set_at', this.polygonEvents.set_at);
                childPolygon.addListener('insert_at', this.polygonEvents.insert_at);
                childPolygon.addListener('dragend', this.polygonEvents.dragend);
                childPolygon.addListener('click', this.polygonEvents.click);

                childPolygon.set('assetClass', siteAsset.AssetClass.Name);
                childPolygon.set('assetClassId', siteAsset.AssetClassId);
                childPolygon.set('id', siteAsset.AssetId);
                childPolygon.set('isNew', false);
                childPolygon.set('latitude', polygonCentre.lat);
                childPolygon.set('longitude', polygonCentre.lng);
                childPolygon.set('name', siteAsset.Name);
                childPolygon.set('status', siteAsset.Status);
                childPolygon.set('title', siteAsset.Name);

                childPolygon.setMap(this.map);

                this.sitePolygons.push(childPolygon);
              });
            });
          } catch (err) {
            console.log('Error reading geoJSON: ' + siteAsset.Name + ', Id: ' + siteAsset.AssetId);
          }
        }
      } else if (assetClass.mappable === 'Point') {
        const newMarker = new google.maps.Marker({
          icon: {
            url: `assets/icons/mapicons/${assetClass.id}${siteAsset.Status}.svg`,
          },
          position: new google.maps.LatLng(siteAsset.Latitude, siteAsset.Longitude),
          map: this.map,
          zIndex: SiteController.SITE_MARKER_Z_IDX,
        });

        const markerContent = this.markerPopup(siteAsset.Name, assetClass.name, siteAsset.Status);

        newMarker.setValues({
          AssetClass: assetClass,
          Elevation: siteAsset.Elevation,
          assetClass: assetClass.name,
          assetClassId: siteAsset.AssetClassId,
          geoJSON: siteAsset.GeoJSON,
          id: siteAsset.AssetId,
          isNew: false,
          name: markerContent,
          status: siteAsset.Status,
          timeZoneName: '',
        });

        this.attachMarkerEvents(newMarker);

        this.siteMarkers.push(newMarker);
      }
    });
  }

  public rejectChanges() {
    if (this.scope['siteForm'].Latitude) {
      this.scope['siteForm'].Latitude.$setValidity('invalid', true);
    }
    if (this.scope['siteForm'].Longitude) {
      this.scope['siteForm'].Longitude.$setValidity('invalid', true);
    }

    // Clear variables
    this._siteService.selectedAsset = null;
    this._mdSidenav('right').close();
    if (this.isWeatherSettingChanged) {
      this.weatherReloadCount++;
      this.isWeatherSettingChanged = false;
    }
    if (this.isKcviSettingChanged) {
      this.kcviReloadCount++; // reload settings-kcvi only if there are KCVI changes
      this.isKcviSettingChanged = false;
    }
    this._dataEntityService.hasDirtyCustomForm = false;
    this._dataEntityService.rejectChanges();
    this._siteService.isEditMode = false;
    this._siteService.selectedAssetClass = null;
    this._notifyingService.notify(NotifyEvents.Map.EditModeChanged, {
      isEditMode: this._siteService.isEditMode,
      equipment: this._siteService.selectedAssetClass,
    });

    this.reloadCounter++;
    this._fetchWaterBudget();
    this.isScheduleApiEnabled = this.asset_.EnableScheduleApi;

    this.refreshSettingsFromCache();
    this.isSiteNameExist = false;

    this.siteCropIndex = this.getSiteSettingIndex(this.adjustedTodayDayNumber, this.siteCrop);
    this.siteSoilIndex = this.getSiteSettingIndex(this.adjustedTodayDayNumber, this.siteSoil);
    this.siteWaterIndex = this.getSiteSettingIndex(this.adjustedTodayDayNumber, this.siteWater);
    this.siteNootsIndex = this.getSiteSettingIndex(this.adjustedTodayDayNumber, this.siteNoots);
    this._timeout(() => {
      this.initSiteSettings();
    }, 1500);

    // Issue 282: Refresh Soil Moisture Chart / Table data on Cancel
    const todayDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(new Date());
    const startArrayDayNumber = todayDayNumber - this._goBackDays;

    this.updateSoilMoistureBalance(this.asset_.AssetId, this.selectedProbeId, startArrayDayNumber, '-1', '-2', '-1', false);

    this.loadSiteDataToMap();

    this.assetClass = null;
    this._notifyingService.notify(NotifyEvents.Map.EquipmentReset, {});
    this._groupSettingService.initRangeArray();
    this.resetSlider?.(this._groupSettingService.rangeValues); // NOTE: 'resetSlider' function could be undefined if not passed down from parent react component.
    this.isPolygonError = false;
    this.isHealthAreaIntersected = false;
  }

  public canCancelChanges(): boolean {
    const result = this.isWeatherSettingChanged || this.isKcviSettingChanged || this._dataEntityService.hasDataChanges;

    return result;
  }

  private isValidEntry(): boolean {
    let result = true;
    if (this.isSiteSettingFetched) {
      result =
        ((angular.isDefined(this._siteSettingService.siteCrop) &&
          angular.isDefined(this._siteSettingService.siteCrop.PhaseCropCoefficient) &&
          (this._siteSettingService.siteCrop.PhaseCropCoefficient == null ||
            (this._siteSettingService.siteCrop.PhaseCropCoefficient != null &&
              angular.isNumber(this._siteSettingService.siteCrop.PhaseCropCoefficient)))) ||
          angular.isUndefined(this._siteSettingService.siteCrop)) &&
        ((angular.isDefined(this._siteSettingService.siteWater) &&
          (this._siteSettingService.siteWater.CropWaterArea == null ||
            angular.isNumber(this._siteSettingService.siteWater.CropWaterArea)) &&
          angular.isNumber(this._siteSettingService.siteWater.SprinklerLossConstantA) &&
          angular.isNumber(this._siteSettingService.siteWater.IrrigationApplicationOneHour_mm)) ||
          angular.isUndefined(this._siteSettingService.siteWater)) &&
        (angular.isUndefined(this._groupSettingService.siteSoil) ||
          (this._groupSettingService.siteSoil != null &&
            angular.isDefined(this._groupSettingService.siteSoil.DrainageCoefficient) &&
            angular.isNumber(this._groupSettingService.siteSoil.DrainageCoefficient) &&
            angular.isNumber(this._groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm) &&
            angular.isNumber(this._groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm) &&
            angular.isNumber(this._groupSettingService.siteSoil.WorkingSoilDepthBottom_mm) &&
            !this._groupSettingService.siteSoil['depthsError'])) &&
        ((angular.isDefined(this._siteSettingService.siteNoot) &&
          (this._siteSettingService.siteNoot.CropNutrientArea == null ||
            angular.isNumber(this._siteSettingService.siteNoot.CropNutrientArea))) ||
          angular.isUndefined(this._siteSettingService.siteNoot)) &&
        (!this.FormWaterBudget || this.FormWaterBudget.$valid);
    }
    return result;
  }

  private refreshSettingsFromCache() {
    this.siteCrop = ArrayUtils.sortByNumberWithMutation(this.entityManager.getEntities('SiteSettingsCrop') as SiteSettingsCrop[], (x) => x.DayNumber);
    this.siteSoil = ArrayUtils.sortByNumberWithMutation(this.entityManager.getEntities('SiteSettingsSoil') as SiteSettingsSoil[], (x) => x.DayNumber);
    this.siteNoots = ArrayUtils.sortByNumberWithMutation(this.entityManager.getEntities('SiteSettingsNutrients') as SiteSettingsNutrients[], (x) => x.DayNumber);
    this.siteWater = ArrayUtils.sortByNumberWithMutation(this.entityManager.getEntities('SiteSettingsWater') as SiteSettingsWater[], (x) => x.DayNumber);

    if (this.siteCropIndex >= this.siteCrop.length) {
      this.siteCropIndex = this.siteCrop.length - 1;
    }

    if (this.siteSoilIndex >= this.siteSoil.length) {
      this.siteSoilIndex = this.siteSoil.length - 1;
    }

    if (this.siteNootsIndex >= this.siteNoots.length) {
      this.siteNootsIndex = this.siteNoots.length - 1;
    }

    if (this.siteWaterIndex >= this.siteWater.length) {
      this.siteWaterIndex = this.siteWater.length - 1;
    }
  }

  public saveChanges(postSaveActions: PostSaveActions = null) {
    let isValidChanges = true;

    if (this.isSiteNameExist || this.asset_.Name == '') {
      this._languageService.error('AC.SITE.MSG.SITE_NAME_ERROR');
      isValidChanges = false;
      this.primaryTab = PrimaryTabEnum.Summary;
    }
  
    if (this.asset_.Status == StatusEnum.Active) {
      if (!this.site_.Area) {
        this._languageService.warning('AC.SITE.MSG.DRAW_SITE_AREA');
        isValidChanges = false;
      }
  
      if (
        this.siteNoots.length &&
        this.siteNootsIndex != -1 &&
        !this.siteNoots[this.siteNootsIndex].CropNutrientArea &&
        angular.isDefined(this.siteNoots[this.siteNootsIndex].CropNutrientArea)
      ) {
        this._languageService.warning('AC.SITE.MSG.NUTRIENT_AREA_RANGE');
        isValidChanges = false;
      }

      if (this.siteWater.length && this.siteWaterIndex != -1 && !this.siteWater[this.siteWaterIndex].CropWaterArea) {
        this._languageService.warning('AC.SITE.MSG.WATER_AREA_RANGE');
        isValidChanges = false;
      }
    }

    if (this.isWeatherSettingChanged) {
      if (
        this.weatherStations.some(
          (a) =>
            a.isETOPriorityConflict ||
            a.isHumidityPriorityConflict ||
            a.isRainfallPriorityConflict ||
            a.isSolarRadiationPriorityConflict ||
            a.isTemperaturePriorityConflict ||
            a.isWindspeedPriorityConflict,
        )
      ) {
        this._languageService.error('AC.SITE.MSG.WEATHER_SETTING_CONFLICTS');
        isValidChanges = false;
      }
    }

    if (this.isKcviSettingChanged) {
      let isKcviSettingValid = true;

      if (this.siteKcviChanges.dataProviderId == null) {
        this._languageService.error('AC.SITE.MSG.KCVI_PROVIDER_EMPTY');
        isKcviSettingValid = false;
      }

      if (
        !this.siteKcviChanges.healthAssets.some((a) => a.isSelected) ||
        !this.siteKcviChanges.healthAssets.some((a) => a.isSelected && a.status == 'Active')
      ) {
        this._languageService.error('AC.SITE.MSG.KCVI_ASSETS_EMPTY');
        isKcviSettingValid = false;
      }

      if (!this.siteKcviChanges.siteAlgorithms.length) {
        this._languageService.error('AC.SITE.MSG.KCVI_ALGORITHM_EMPTY');
        isKcviSettingValid = false;
      }

      if (!isKcviSettingValid) {
        isValidChanges = false;
      }
    }

    if (!this.isSoilSettingFormValid()) {
      this._languageService.error('AC.SITE.MSG.FIX_SOIL');
      isValidChanges = false;
    }

    if (!this.isCropSettingFormValid()) {
      this._languageService.error('AC.SITE.MSG.FIX_CROP');
      isValidChanges = false;
    }

    if (!this.isWaterSettingFormValid()) {
      this._languageService.error('AC.SITE.MSG.FIX_WATER');
      isValidChanges = false;
    }

    if (!this.isNutrientSettingFormValid()) {
      this._languageService.error('AC.SITE.MSG.FIX_NUTRIENT');
      isValidChanges = false;
    }

    const isDailySummaryInvalid = this.siteWaterChartDataProvider.some((a) => !a.isValid);

    if (isDailySummaryInvalid) {
      isValidChanges = false;
      this._languageService.error('AC.SITE.MSG.FERT_EXCEEDS_IRRIG');
    }

    if (this.isPolygonError) {
      isValidChanges = false;
      this._languageService.error('AC.SITE.MSG.IMU_INTERSECT_ERROR');
    }

    if (this.isHealthAreaIntersected) {
      isValidChanges = false;
      this._languageService.error('AC.SITE.MSG.SHA_INTERSECT_ERROR');
    }

    if (!isValidChanges) {
      return;
    }

    const promises = [] as angular.IPromise<void>[];

    if (this.isWeatherSettingChanged) {
      promises.push(this.updateSiteWeatherStations(this.weatherStations));
    }

    if (this.isKcviSettingChanged) {
      promises.push(this.updateSiteKcviSettings());
    }

    this._q.all(promises).then(() => {
      //  #region Observation Values Manual Override
      //  Set quality factor = 2 for values manual override
      const modifiedObsWeatherRollups = this.entityManager.getEntities('ObsWeatherRollup', breeze.EntityState.Modified);

      modifiedObsWeatherRollups.forEach((obs) => {
        const obsEntity = obs as ObsWeatherRollup;

        if (obsEntity.dayNumber <= this.adjustedTodayDayNumber) {
          obsEntity.QualityFactor = 2;
        }
      });

      // Is this a new Site? - we will need to initialise the SiteDailySummary (once we've created the DB entities)
      const shouldCalculateSMB: boolean = this.asset_.AssetId < 0;
      const savePromises = [];
      const dataLayer = new google.maps.Data();

      // #region Site Markers
      // Save Site Markers to Data Layer for GeoJson conversion
      for (let i = 0; i < this.siteMarkers.length; i++) {
        const marker = this.siteMarkers[i];

        if (marker.get('isNew')) {
          const latLng = marker.getPosition();

          dataLayer.add({
            geometry: new google.maps.Data.Point(latLng),
            properties: {
              assetClass: marker.get('assetClass'),
              assetClassId: marker.get('assetClassId'),
              id: marker.get('id'),
              latitude: latLng.lat(),
              longitude: latLng.lng(),
              name: marker.get('name'),
              type: 'marker',
            },
          });
        }
      }
      // #endregion Site Markers

      // #region Site Polygons
      for (let i = 0; i < this.sitePolygons.length; i++) {
        const sitePolygon = this.sitePolygons[i];

        if (sitePolygon) {
          const polygon = this.getDataPolygon(sitePolygon);

          let spNewId = sitePolygon.get('id');
          let spNewName = sitePolygon.get('name');

          for (let index = 0; index < this._siteAssets.length; index++) {
            const siteAsset = this._siteAssets[index];

            try {
              const assetClass = SWANConstants.assetClasses.find((a) => a.id === siteAsset.AssetClassId);

              if (assetClass.mappable !== 'Polygon') {
                continue;
              }

              const geoJSON = JSON.parse(siteAsset.GeoJSON) as Feature<Polygon>;

              if ([geoJSON.properties.id, siteAsset.AssetId].includes(sitePolygon.get('id'))) {
                spNewId = siteAsset.AssetId;
                spNewName = siteAsset.Name;

                const polygonCentre = GoogleMapUtils.Polygons.getCentre(polygon);

                siteAsset.Latitude = NumberUtils.round(polygonCentre.lat, 5);
                siteAsset.Longitude = NumberUtils.round(polygonCentre.lng, 5);

                const shapeAttrs = { assetClass: assetClass.name, id: siteAsset.AssetId, name: siteAsset.Name };
                const polygonPromise = this.getShapeGeoJSON(polygon, shapeAttrs).then((shapeJSON) => {
                  siteAsset.GeoJSON = shapeJSON;
                });

                savePromises.push(polygonPromise);

                break;
              }
            } catch (err) {
              // Error for some reason
              console.warn(`Error occurred while preparing update of the site's asset geo data! [AssetId: ${siteAsset.AssetId}] - ${err}`);
            }
          };

          dataLayer.add({
            geometry: polygon,
            properties: {
              assetClass: sitePolygon.get('assetClass'),
              assetClassId: sitePolygon.get('assetClassId'),
              id: spNewId,
              name: spNewName,
              type: 'area',
            },
          });
        }
      }

      const defer = this._q.defer<GeoJSON>();

      // NOTE: DataLayer at this stage contains markers and polygons.
      dataLayer.toGeoJson((data) => {
        defer.resolve(data as GeoJSON);
      });

      savePromises.push(defer.promise);

      defer.promise.then((siteGeoJSON: GeoJSON) => {
        const latestGeoJSONString = JSON.stringify(siteGeoJSON);

        if (this.asset_.GeoJSON !== latestGeoJSONString) {
          if (!latestGeoJSONString) {
            throw new Error(`Site's GeoJSON is empty! [AssetId: ${this.siteId}]`);
          }

          // Update Site GeoJSON.
          this.asset_.GeoJSON = latestGeoJSONString;
        }
      });
      // #endregion Site Polygons

      this._q.all(savePromises).then(() => {
        this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(() => {
          this._siteService.isNewSite = false;
          this.saveCounter++;

          if (this.siteCrop.length) {
            this.siteSettingCropFirstId = this.siteCrop[0].Id;
          }

          if (this.siteSoil.length) {
            this.siteSettingSoilFirstId = this.siteSoil[0].Id;
          }

          this._dataEntityService.hasDirtyCustomForm = false;
          this._languageService.showSaveSuccess();

          // Clear variables
          this._siteService.selectedAsset = null;
          this._mdSidenav('right').close();

          if (shouldCalculateSMB) {
            // only set if saving a new asset
            this._languageService.info('AC.SITE.MSG.CALCULATING_SUMMARY');

            const todayDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(new Date());
            const startArrayDayNumber = todayDayNumber - this._goBackDays;

            // initialise watering application array
            const numDays: number = this._goBackDays + this._goFwdDays;
            const irrigationArray: string[] = [];

            for (let idx = 0; idx < numDays; idx++) {
              irrigationArray.push('0');
            }

            this.updateSoilMoistureBalance(
              this.asset_.AssetId,
              this.selectedProbeId,
              startArrayDayNumber,
              irrigationArray.join(),
              '-2',
              '-1',
              false,
            )?.then(() => {
              this.saveChanges(); // again
              // update the summary data
              this.getSiteSummaryPacket(this.asset_.AssetId);
            });
          } else {
            // update the summary data
            this.getSiteSummaryPacket(this.asset_.AssetId);
          }

          // refetch equipment data
          this.getEquipmentData();

          // refetch the water budget
          //this._fetchWaterBudget();

          this.FormWaterBudget.$setPristine();
          this.assetClass = null;
          this._siteService.isEditMode = false;
          this._siteService.selectedAssetClass = null;

          this._notifyingService.notify(NotifyEvents.Map.EditModeChanged, {
            isEditMode: this._siteService.isEditMode,
            equipment: this._siteService.selectedAssetClass,
          });

          this._notifyingService.notify(NotifyEvents.Map.EquipmentReset, {});

          if (super.executeAnyPostSaveActions(postSaveActions)) {
            return;
          }
        },
        (error) => {
          this._dupeHandlerService.setDuplicateType(this._siteService.selectedAssetClass?.name ?? 'Site (IMU)');
          this._dupeHandlerService.showError(error);
        });
      },
      (error) => {
        if (error.entityErrors) {
          error.entityErrors.forEach((error) => {
            this._languageService.info(error.errorMessage);
          });
        }
      });

      // mark all markers and polygons isNew to false
      this.siteMarkers.forEach((marker) => {
        marker.set('isNew', false);
      });

      this.sitePolygons.forEach((polygon) => {
        polygon.set('isNew', false);
      });
    });
  }

  public gotoSites() {
    this._groupSiteService.setKeepFilter(true);
    this._state.go('app.account.sites');
  }

  public gotoSiteDetail(site: AccountSiteInfo) {
    if (this.httpCanceller) {
      this.cancelled = true;
      this.httpCanceller.resolve('New Site Selected');
    }

    LocalStorageUtils.updateContextData((context) => {
      context.siteId = site.id;
      context.assetId = site.id;
      context.tab1 = this.primaryTab;
      context.tab2 = this.settingsTab;
      context.tab3 = this.waterTab;
    });

    this._state.go('app.account.sites.detail', { id: site.id, viewSchedule: false, viewMap: false });
  }

  public tableUpdateSM(siteDailySummary: fuse.soilMoisturePrediction, isPast: boolean, fname: string) {
    if (fname == 'irrigation') {
      const hours = this.convertIrrigationToHour(siteDailySummary);

      if (hours == null) {
        this._languageService.warning('AC.SITE.MSG.INVALID_IRRIG');
        siteDailySummary.irrigation = 0;
      }

      if (hours > 24) {
        this._languageService.warning('AC.SITE.MSG.EXCEEDS_MAX');
        siteDailySummary.irrigation = this.getSiteMaximumIrrigationValue(siteDailySummary);
      }
    } else {
      const hours = this.convertIrrigationPlannedToHour(siteDailySummary);

      if (hours == null) {
        this._languageService.warning('AC.SITE.MSG.INVALID_PLANNED');
        siteDailySummary.irrigationPlanned = 0;
      }

      if (hours > 24) {
        this._languageService.warning('AC.SITE.MSG.EXCEEDS_MAX');
        siteDailySummary.irrigationPlanned = this.getSiteMaximumIrrigationValue(siteDailySummary);
      }
    }

    this.keepUserZoom = true;
    this._timeout.cancel(this.smTimer);

    this.smTimer = this._timeout(() => {
      if (this.siteWaterChart) {
        if (isPast) {
          if (fname == 'planned') {
            this._languageService.warning('AC.SITE.MSG.SAVING_HISTORICAL');
          }
          if (fname == 'irrigation') {
            this._languageService.info('AC.SITE.MSG.HISTORY_UPDATE_WARNING');
          }
        } else {
          const dataProviderItem = this.siteWaterChartDataProvider.find((a) => a.dayNumber == siteDailySummary.dayNumber);
          if (fname == 'irrigation') {
            dataProviderItem.irrigationPlanned = dataProviderItem.irrigation;
          }
        }

        // get the SiteSummarySettings out of the cache for update
        const query = breeze.EntityQuery.from('SiteDailySummary').orderBy('dayNumber');
        const obs = this.entityManager.executeQueryLocally(query) as SiteDailySummary[]; // query the cache (synchronous)

        if (this.siteWaterChartDataProvider.length) {
          let lastIdx = 0;
          for (let jdx = 0; jdx < obs.length; jdx++) {
            const siteDayNumber = obs[jdx].dayNumber;
            // Find the corresponding prediction - possibly could be more efficient as both should be 1:1 by day
            for (let idx = lastIdx; idx < this.siteWaterChartDataProvider.length; idx++) {
              // Chart data is already a string YYYY-MM-DD
              if (this.siteWaterChartDataProvider[idx].dayNumber == siteDayNumber) {
                // has Irrigation Changed
                if (Math.abs(obs[jdx].Irrigation - this.siteWaterChartDataProvider[idx].irrigation) > 0.005) {
                  obs[jdx].Irrigation = this.siteWaterChartDataProvider[idx].irrigation;
                  // Status == 0 if calc'd from Budget, 1 actuals, 2 manual
                  // Only update for values that are entered in the past.
                  //Future Values should only be allowed to be entered though planned irrigation
                  if (obs[jdx].dayNumber < this.adjustedTodayDayNumber) {
                    // before now, must be an actual
                    obs[jdx].IrrigationStatus = 1;
                  }
                }
                // has IrrigationPlanned Changed?
                if (Math.abs(obs[jdx].IrrigationPlanned - this.siteWaterChartDataProvider[idx].irrigationPlanned) > 0.005) {
                  obs[jdx].IrrigationPlanned = this.siteWaterChartDataProvider[idx].irrigationPlanned;
                  // Status == 0 if calc'd from Budget, 1 actuals, 2 manual
                  if (!isPast) {
                    // after now, so set status to planned
                    obs[jdx].IrrigationStatus = 2;
                  } else {
                    // (Bug 4275 20180112.01)
                    // in the past - do not change Status, but DO update planned
                    if (fname == 'planned') {
                      // Trigger a save of historical Planned to recalc the Actuals
                      //this.entityManager.saveChanges([Obs[jdx]]);
                    }
                  }
                }
                // Write in the predicted soilMoistureBalance -
                if (Math.abs(obs[jdx].SoilMoisture - this.siteWaterChartDataProvider[idx].soilMoisture) > 0.005) {
                  obs[jdx].SoilMoisture = this.siteWaterChartDataProvider[idx].soilMoisture;
                }
                lastIdx = idx + 1;
              }
            }
          }
        }

        //theChart.validateData();
        // this.pendingSMBupdate = true;
        this._languageService.info('AC.SITE.MSG.UPDATING_PREDICTIONS');
        this.updateSoilMoisturePredictions(false);
        this.checkDailySummaryTable();

        if (siteDailySummary.irrigationPlanned < siteDailySummary.fertigationPlanned) {
          this._languageService.error('AC.SITE.MSG.FERT_EXCEEDS_IRRIG');
        }
      }
    }, 500);
  }

  public checkUploadFile(): void {
    const fInput: any = document.getElementById('file');
    const file = fInput.files[0];

    this.isFileReadyForUpload = file?.name.endsWith('.kml') || file?.name.endsWith('json');
  }

  public uploadKmlOrGeoJSON() {
    return GeoUtils.uploadKmlOrGeoJSON(this._notifyingService);
  }

  private renderUploadedMapData(featureCollection: FeatureCollection, paths: google.maps.LatLng[]) {
    const siteFeature = featureCollection.features[0];
    const center = GoogleMapUtils.getCentre(siteFeature);

    this._mapService.getTimeZone(center.lat, center.lng).then(
      (tz: string) => {
        const tzData = JSON.parse(tz) as IGoogleTimeZone;

        if (tzData.status == 'ZERO_RESULTS') {
          this._languageService.error('AC.SITE.MSG.INVALID_COORDS', 'AC.SITE.MSG.LOCATION_ERROR');
          return;
        }

        this._siteService.selectedAsset.TZStandardName = tz;

        // Set styling for Site (IMU) / Water Area
        let stroke: any = {};
        let fill: any = {};
        let isNew: boolean = true;

        if (AssetUtils.isSiteIMU(this.assetClass.name)) {
          stroke = { color: '#795548', opacity: 0.8, weight: 2 };
          fill = { color: '#D7CCC8', opacity: 0.2 };

          if (this.totalSiteIMU) {
            isNew = false;
          } else {
            this.totalSiteIMU += 1;
          }
        } else if (AssetUtils.isSiteWaterArea(this.assetClass.name)) {
          stroke = { color: '#03A9F4', opacity: 0.8, weight: 2 };
          fill = { color: '#B3E5FC', opacity: 0.2 };
        } else if (AssetUtils.isSiteHealthArea(this.assetClass.name)) {
          stroke = { color: '#008000', opacity: 0.8, weight: 2 };
          fill = { color: '#008000', opacity: 0.2 };
        }

        // #region New Polygon
        // the following NEW ID auto generation logic does not make sense at all! what if the total asset is more than 30000 records?
        // the following NEW ID is temporary ID on Google map data layer, what if we need to find an asset object based on this ID ? it cannot be found on Asset table
        const newId = 30000 + this.sitePolygons.length + 1;
        const newPolygon = new google.maps.Polygon({
          draggable: this._siteService.isEditMode,
          editable: this._siteService.isEditMode,
          fillColor: fill.color,
          fillOpacity: fill.opacity,
          paths: paths,
          strokeColor: stroke.color,
          strokeOpacity: stroke.opacity,
          strokeWeight: stroke.weight,
          zIndex: 2,
        });

        newPolygon.setValues({
          assetClass: this.assetClass.name,
          assetClassId: this.assetClass.id,
          id: newId,
          isNew: isNew,
          latitude: parseFloat(center.lat.toFixed(5)),
          longitude: parseFloat(center.lng.toFixed(5)),
          name: '',
        });

        const newPolygonPath = newPolygon.getPath();

        google.maps.event.addListener(newPolygonPath, 'set_at', this.polygonEvents.set_at);
        google.maps.event.addListener(newPolygonPath, 'insert_at', this.polygonEvents.insert_at);

        newPolygon.addListener('dragend', this.polygonEvents.dragend);
        newPolygon.addListener('click', this.polygonEvents.click);

        if (!isNew) {
          for (let i = 0; i < this.sitePolygons.length; i++) {
            const sitePolygon = this.sitePolygons[i];

            if (sitePolygon.get('assetClassId') === this.assetClass.id) {
              const polygon = this.sitePolygons[i];

              polygon.setMap(null);
              newPolygon.setMap(this.map);

              this.sitePolygons[i] = newPolygon;
              break;
            }
          }
        } else {
          this.site_.Asset.GeoJSON = JSON.stringify(featureCollection);
          newPolygon.setMap(this.map);
          this.sitePolygons.push(newPolygon);
        }

        if (AssetUtils.isSiteIMU(this.assetClass.name)) {
          this._siteService.selectedAsset = this.entityManager.getEntityByKey('Asset', this.siteId) as Asset;
          this.siteIMUPolygon = newPolygon;
          this.site_.Area = google.maps.geometry.spherical.computeArea(newPolygonPath.getArray()) / 10000;
          this._siteService.siteGeometryArea = this.site_.Area;
          this.siteWater[this.siteWaterIndex].CropWaterArea = this.site_.Area;
          this.siteNoots[this.siteNootsIndex].CropNutrientArea = this.site_.Area;

          this._mapService.getElevation(center.lat, center.lng).then((data) => {
            // update the entity marker latitude longitude
            this._siteService.selectedAsset.Latitude = center.lat;
            this._siteService.selectedAsset.Longitude = center.lng;
            this._siteService.selectedAsset.Elevation = parseFloat(data.toFixed(5));

            this._siteService.isSiteGeometryCreated = true;
            const latLngBounds = new google.maps.LatLngBounds();
            this.siteIMUPolygon.getPaths().forEach((obj) => {
              obj.getArray().forEach((t) => {
                latLngBounds.extend(t);
              });
            });
            this.map.fitBounds(latLngBounds);
            const polygonCentre = GoogleMapUtils.Polygons.getCentre(this.siteIMUPolygon);
            this.asset_.Latitude = polygonCentre?.lat;
            this.asset_.Longitude = polygonCentre?.lng;
            this._notifyingService.notify(NotifyEvents.Map.DrawingModeSelected, { drawingMode: 'Hand' });
          });
        } else {
          const latLngBounds = new google.maps.LatLngBounds();
          latLngBounds.extend(
            new google.maps.LatLng(parseFloat(center.lat.toFixed(5)), parseFloat(center.lng.toFixed(5))),
          );
          this.map.fitBounds(latLngBounds);
        }
        // #endregion
      },
      (error) => {
        console.log(error);
      },
    );
  }

  private showInfoWindow(content: string, position: google.maps.LatLng): void {
    this.infoWindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, -36),
      content: content,
    });

    this.infoWindow.setPosition(position);
    this.infoWindow.open(this.map);
  }

  private attachMarkerEvents(marker: google.maps.Marker): void {
    Object.keys(this.markerEvents).forEach((key) => {
      marker.addListener(key, (event) => this.markerEvents[key](event, marker));
    });
  }

  private initSiteSettings() {
    if (this.siteSoil.length && this.siteSoilIndex != -1) {
      this._notifyingService.notify(NotifyEvents.Site.Settings.Soil.Base, {
        setting: this.siteSoil[this.siteSoilIndex],
        readonly: !this.canEditDate(this.siteSoil[this.siteSoilIndex].localDate),
      });
    }

    if (this.siteCrop.length && this.siteCropIndex != -1) {
      this._notifyingService.notify(NotifyEvents.Site.Settings.Crop.Base, {
        setting: this.siteCrop[this.siteCropIndex],
        readonly: !this.canEditDate(this.siteCrop[this.siteCropIndex].localDate),
      });
    }

    if (this.siteWater.length && this.siteWaterIndex != -1) {
      this._notifyingService.notify(NotifyEvents.Site.Settings.Water.Base, {
        setting: this.siteWater[this.siteWaterIndex],
        readonly: !this.canEditDate(this.siteWater[this.siteWaterIndex].localDate),
      });
    }

    if (this.siteNoots.length && this.siteNootsIndex != -1) {
      this._notifyingService.notify(NotifyEvents.Site.Settings.Nutirients.Base, {
        setting: this.siteNoots[this.siteNootsIndex],
        readonly: !this.canEditDate(this.siteNoots[this.siteNootsIndex].localDate),
      });
    }
  }

  private getSiteSummaryPacket(siteId: number): angular.IPromise<breeze.QueryResult> {
    const defer = this._q.defer<breeze.QueryResult>();
    const params = { siteId: siteId };

    this._http
      .get(CommonHelper.getApiUrl('user/getSitePacket'), { params: params, timeout: this.httpCanceller.promise })
      .then(
        (response) => {
          if (!response.data) {
            this._languageService.whoops();
          } else {
            this._siteDetails = response.data as SiteSummaryModel;
            this.fertigationEnabled = this._siteDetails.data.fertigation_enabled;

            this._timeout(() => {
              this.loadWaterUsageChart();
              this.loadNutrientDisplay();
            }, 500);
          }

          return defer.resolve();
        },
        (error) => {
          if (!this.cancelled) {
            this._languageService.whoops();
          }
          return defer.reject();
        },
      );

    return defer.promise;
  }

  private loadSiteProfiles(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl('site/getAccountSiteProfiles')).then(
      (response) => {
        const data = response.data as fuse.siteProfileDto[];
        this.imuSiteList = data.map((d) => {
          return {
            name: d.name,
            id: d.siteId,
            status: d.status,
          } as ISiteProfile;
        });
        defer.resolve();
      },
      () => defer.reject(),
    );

    return defer.promise;
  }

  private _fetchData() {
    this._dataEntityService.clear();

    this._q.all([
      this.loadSiteProfiles(),
      this._http.get(CommonHelper.getApiUrl('user/AccountSiteSummary'), {
        params: { accountId: this.accountId, assetId: this.siteId },
        timeout: this.httpCanceller.promise,
      })
    ]).then(
      (response) => {
        const siteSummary = response[1].data as fuse.accountSiteSummaryModel;

        if (!siteSummary) {
          this._languageService.whoops();
        } else {
          let site = this.imuSiteList.find((s) => s.id == this.siteId);

          if (!site) {
            site = {} as ISiteProfile;
            site.id == this.siteId;
          }

          if (siteSummary.sites?.length) {
            const siteData = siteSummary.sites[0];

            site.area_irrigated = siteData.area_irrigated;
            site.croptype = siteData.croptype;
            site.cropphase = siteData.cropphase;
            site.groups = ArrayUtils.sortStrings(siteData.groups);
            site.water_budget_start = siteData.water_budget_startdate;
            site.water_budget_total = siteData.water_budget_total;
            site.waterusage = siteData.waterusage_status;
            site.water_budget = siteData.water_budget;
            site.water_actual = siteData.water_actual;

            this._hasSoilMoistureData = !!siteData.soilmoisture?.length;
            this.waterUsageStatus = siteData.waterusage_status;
            this.water_actual = siteData.water_actual;
            this.water_budget = siteData.water_budget;

            this.selectedIMUSites.push(site);
          }
        }
      },
      (error) => {
        if (!this.cancelled) {
          console.log('Error: Could not load AccountSiteSummary (' + JSON.stringify(error) + ')');
        }
      },
    );

    // Get the site asset, and expand to get all related info
    const getSiteAssetPromise = () =>
      breeze.EntityQuery.from('getAsset')
      .withParameters({ AssetId: this.siteId })
      .expand('ChildAssets.ChildAsset.AssetClass') //Needed for assets to display on map
      .using(this.entityManager)
      .execute().then((data) => {
        const firstRecord: any = data.results[0];
        this.asset_ = firstRecord;
        this._siteAssets = this.asset_.ChildAssets.map((x) => x.ChildAsset) as Asset[];
        this.isSiteActive = this.asset_?.Status == 'Active';
        this.isScheduleApiEnabled = this.asset_.EnableScheduleApi;

        if (this.asset_.GeoJSON) {
          try {
            const geoJSON = JSON.parse(this.asset_.GeoJSON);

            if (geoJSON?.features) {
              try {
                JSON.parse(this.asset_.TZStandardName) as IGoogleTimeZone;
              } catch (e) {
                this.setSiteCoord(this.asset_.Latitude, this.asset_.Longitude);
              }
            }
          } catch (e) {
            //do nothing
          }
        }
      },
      (error) => {
        console.log('Error: Could not load Asset (' + error.message + ')');
      });

    // Get Site information
    const getSitePromise = () => 
      breeze.EntityQuery.from('getSites')
      .withParameters({ AssetId: this.siteId })
      .using(this.entityManager)
      .execute().then((data) => {
        this.site_ = data.results[0] as Site;

        if (this.site_.Area == null) {
          this._siteService.isNewSite = true;
        } else {
          this._siteService.isNewSite = false;
        }

        this.selectedProbeId = this.site_.SMPAssetId;
      },
      (error) => {
        console.log('Error: Could not load Site entity (' + error.message + ')');
      });

    this.isReadySiteData = this._q.all([
      this._mapService.isGoogleMapsJSApiLoaded,
      getSiteAssetPromise(),
      getSitePromise(),
    ]);

    this.getSiteSummaryPacket(this.siteId);
  }

  private _fetchSiteSettingsData() {
    const promisesGroup2 = []; // Site Constants and Site Crop Coefficients

    this._siteSettingService.loadWaterBudget(this.accountId, this.siteId, this.httpCanceller.promise);

    // and the site constants stored in the SiteSettingsTables (Water, Crop, Nutrients and Soil)
    const siteWaterPromise = breeze.EntityQuery.from('getSiteSettingsWater')
      .withParameters({ AssetId: this.siteId })
      .using(this.entityManager)
      .execute();

    siteWaterPromise.then(
      (data) => {
        // set up the parameters for the settings tab
        this.siteWater = ArrayUtils.sortByNumberWithMutation((data.results as SiteSettingsWater[]).filter((x) => !!x), (x) => x.DayNumber);
      },
      (error) => {
        console.log('Error: Could not load Site entity (' + error.message + ')');
      },
    );

    promisesGroup2.push(siteWaterPromise);

    const siteSoilPromise = breeze.EntityQuery.from('getSiteSettingsSoil')
      .withParameters({ AssetId: this.siteId })
      .using(this.entityManager)
      .execute();

    siteSoilPromise.then(
      (data) => {
        // set up the parameters for the settings tab
        this.siteSoil = ArrayUtils.sortByNumberWithMutation((data.results as SiteSettingsSoil[]).filter((a) => !!a), (x) => x.DayNumber);
        this.siteSettingSoilFirstId = this.siteSoil[0].Id;
      },
      (error) => {
        console.log('Error: Could not load Site entity (' + error.message + ')');
      },
    );

    promisesGroup2.push(siteSoilPromise);

    const siteCropPromise = breeze.EntityQuery.from('getSiteSettingsCrop')
      .withParameters({ AssetId: this.siteId })
      .using(this.entityManager)
      .execute();

    siteCropPromise.then(
      (data) => {
        // set up the parameters for the settings tab
        this.siteCrop = ArrayUtils.sortByNumberWithMutation((data.results as SiteSettingsCrop[]).filter((a) => !!a), (x) => x.DayNumber);
        this.siteSettingCropFirstId = this.siteCrop[0].Id;
      },
      (error) => {
        console.log('Error: Could not load Site entity (' + error.message + ')');
      },
    );

    promisesGroup2.push(siteCropPromise);

    const defer = this._q.defer();

    breeze.EntityQuery.from('getSiteSettingsNutrients')
      .withParameters({ AssetId: this.siteId })
      .using(this.entityManager)
      .execute()
      .then(
        (data) => {
          if (!data.results?.length) {
            this._siteSettingService.addRequiredSiteSettings(this.siteId, 'Nutrients').then((data) => {
              this.siteNoots.push(data);
              defer.resolve();
            });
          } else {
            // set up the parameters for the settings tab
            this.siteNoots = ArrayUtils.sortByNumberWithMutation(data.results as SiteSettingsNutrients[], (x) => x.DayNumber);

            defer.resolve();
          }
        },
        (error) => {
          console.log('Error: Could not load Site entity (' + error.message + ')');
        },
      );

    promisesGroup2.push(defer.promise);

    this._q.all(promisesGroup2).then(() => {
      /// default to the settings in effect today
      this.siteCropIndex = this.getSiteSettingIndex(this.adjustedTodayDayNumber, this.siteCrop);
      this.siteSoilIndex = this.getSiteSettingIndex(this.adjustedTodayDayNumber, this.siteSoil);
      this.siteWaterIndex = this.getSiteSettingIndex(this.adjustedTodayDayNumber, this.siteWater);
      this.siteNootsIndex = this.getSiteSettingIndex(this.adjustedTodayDayNumber, this.siteNoots);

      if (!this.siteNoots[this.siteNootsIndex].CropNutrientArea) {
        this._languageService.info('AC.SITE.MSG.NUTRIENT_AREA_UNDEFINED');
        this.siteNoots[this.siteNootsIndex].CropNutrientArea = this.siteWater[this.siteWaterIndex].CropWaterArea;
        this._languageService.info('AC.SITE.MSG.POPULATE_NUTRIENT_AREA');
      }

      this._timeout(() => {
        this.isSiteSettingFetched = true;
        this.initSiteSettings();
      }, 1500);

      //this._fetchWaterBudget();
    });
  }

  private getSiteSettingWater() {
    // and the site constants stored in the SiteSettingsTables (Water, Crop, Nutrients and Soil)
    const siteWaterPromise = breeze.EntityQuery.from('getSiteSettingsWater')
      .withParameters({ AssetId: this.siteId })
      .using(this.entityManager)
      .execute();
    siteWaterPromise.then(
      (data) => {
        // set up the parameters for the settings tab
        this.siteWater = ArrayUtils.sortByNumberWithMutation(data.results as SiteSettingsWater[], (x) => x.DayNumber);
      },
      (error) => {
        console.log('Error: Could not load Site entity (' + error.message + ')');
      },
    );
  }

  public openSMOverrideDialog(soilMoisturePrediction: fuse.soilMoisturePrediction) {
    if (this.isReadOnly) {
      return;
    }

    //get number of days to recalc SMP from
    const firstDate = new Date();
    const secondDate = soilMoisturePrediction.dayDate;
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    this.smOverrideDaystoCalcFrom = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
    if (this.smOverrideDaystoCalcFrom < 7) {
      this.smOverrideDaystoCalcFrom = 7;
    }
    this._mdDialog
      .show({
        controller: SoilMoistureDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/waterTabs/soilmoisture-dialog.html',
        parent: angular.element(document.body),
        //    multiple : true,
        clickOutsideToClose: true,
        locals: {
          item: soilMoisturePrediction,
          siteId: this.siteId,
          hasPreviousChanges: this.canCancelChanges(),
        },
      })
      .then((update) => {
        if (update) {
          this.retriggerSMPafterPopup();
        }
      });
  }

  private retriggerSMPafterPopup() {
    this.updateSoilMoisturePredictions(true);
  }

  private loadWaterUsageChart() {
    const unit_label = this.volumeLargeUnit.name;

    const convertedData = SiteConvertedWaterUsageConverter.convert(
      this._unitOfMeasureService,
      this._siteDetails.data.waterusage_readings,
      unitSizes.large,
    );

    this.wuChart = (AmCharts as any).makeChart('water-usage-chart', {
      theme: 'light',
      type: 'serial',
      dataProvider: convertedData,
      autoresize: false,
      autoTransform: true,
      autoDisplay: true,
      legend: {
        useGraphSettings: true,
        horizontalGap: 1,
        position: 'bottom',
      },
      valueAxes: [
        {
          position: 'right',
          title: this._languageService.instant('AC.SITE.RUNNING_TOTALS', { units: unit_label }),
          minimum: 0,
          id: 'axisRunning',
        },
        {
          position: 'left',
          title: this._languageService.instant('AC.SITE.WEEKLY_TOTALS', { units: unit_label }),
          minimum: 0,
          id: 'axisPeriod',
        },
      ],
      startDuration: 0,
      graphs: [
        this.plot(
          'budgetRunning',
          'COMMON.RUNNING_BUDGET',
          UnitTypes.Volume,
          {
            bullet: 'round',
            bulletAlpha: 0,
            fillAlphas: 0.1,
            lineAlpha: 0.9,
            type: 'line',
            lineColor: '#27aae0',
            lineThickness: 3,
            valueAxis: 'axisRunning',
          },
          unitSizes.large,
        ),
        this.plot(
          'actualRunning',
          'COMMON.RUNNING_ACTUAL',
          UnitTypes.Volume,
          {
            bullet: 'round',
            bulletAlpha: 0,
            fillAlphas: 0.1,
            lineAlpha: 0.9,
            type: 'line',
            clustered: false,
            lineColor: '#1A237E',
            lineThickness: 3,
            valueAxis: 'axisRunning',
          },
          unitSizes.large,
        ),
        this.plot(
          'budget',
          'COMMON.BUDGET',
          UnitTypes.Volume,
          {
            fillAlphas: 0.9,
            lineAlpha: 0.2,
            type: 'column',
            fillColors: '#0084CA',
            valueAxis: 'axisPeriod',
          },
          unitSizes.large,
        ),
        this.plot(
          'actual',
          'COMMON.ACTUAL',
          UnitTypes.Volume,
          {
            fillAlphas: 0.9,
            lineAlpha: 0.2,
            type: 'column',
            clustered: false,
            columnWidth: 0.5,
            fillColors: '#1A237E',
            valueAxis: 'axisPeriod',
          },
          unitSizes.large,
        ),
      ],
      plotAreaFillAlphas: 0.1,
      categoryField: 'dateDisplay',
      categoryAxis: {
        gridPosition: 'start',
        axisAlpha: 0,
        gridAlpha: 0,
        position: 'left',
        labelRotation: 90,
        fontSize: 12,
        autoWrap: true,
      },
    });

    const chartElement = document.getElementById('water-usage-chart');

    if (chartElement) {
      chartElement.style.width = '100%';
      chartElement.style.height = '360px';
      this.wuChart.validateData();
    }
  }

  private loadNutrientDisplay() {
    // rebuild .nutrients.DisplayData =
    this.nutrientDashboard.reInit(this._siteDetails.data.nutrientInfo);
    const chartDiv: string = 'nutrients-site-chart';
    // NUTRIENTS Chart
    const unitText = this.weightAreaUnit.name;

    this.nutrientChartRollup = (AmCharts as any).makeChart(chartDiv, {
      theme: 'light',
      type: 'serial',
      dataProvider: this.nutrientDashboard.DisplayData,
      valueAxes: [
        {
          position: 'left',
          title: unitText,
        },
      ],
      legend: {
        useGraphSettings: true,
        horizontalGap: 1,
        position: 'bottom',
      },
      startDuration: 0,
      graphs: [
        {
          //"balloonText": "Planned [[category]] to date: <b>val kg/ha</b>",
          fillAlphas: 0.9,
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.PLANNED'),
          type: 'column',
          valueField: 'planned',
          fillColors: '#67b7dc',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return this._languageService.instant('AC.SITE.PLANNED_TO_DATE', {
                noot: graphDataItem.category,
                val: this.weightAreaUnit.fromBaseText(val, 1),
              });
            } else {
              return '';
            }
          },
        },
        {
          fillAlphas: 0.9,
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.APPLIED'),
          type: 'column',
          clustered: false,
          columnWidth: 0.5,
          valueField: 'applied',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return this._languageService.instant('AC.SITE.APPLIED_TO_DATE', {
                noot: graphDataItem.category,
                val: this.weightAreaUnit.fromBaseText(val, 1),
              });
            } else {
              return '';
            }
          },
        },
      ],
      plotAreaFillAlphas: 0.1,
      categoryField: 'element',
      categoryAxis: {
        gridPosition: 'start',
      },
    });
    if (document.getElementById(chartDiv)) {
      document.getElementById(chartDiv).style.width = '100%';
      document.getElementById(chartDiv).style.height = '240px';
      this.nutrientChartRollup.validateData();
    }
  }

  private _fetchWaterBudget() {
    const defer = this._q.defer();

    this._http.get(CommonHelper.getApiUrl('data/sites/GetSiteWaterBudgetSeasonStatus'), {
        params: { siteId: this.siteId },
        timeout: this.httpCanceller.promise,
      })
      .then(
        (response) => {
          if (!response.data) {
            // No budget is applied for the season
          } else {
            const siteBudget = response.data as fuse.siteBudgetSummaryDto;

            siteBudget.siteMonths = siteBudget.siteMonths.map((m) => {
              m['budgetDate'] = this._dayNumberService.convertDayNumberToDate(m.dayNumber);

              return m;
            });

            this.waterBudget = siteBudget;
            this.waterUsageStartDate = this._dayNumberService.convertDayNumberToDate(siteBudget.seasonStartDayNumber);
            this.waterUsageEndDate = this._dayNumberService.convertDayNumberToDate(siteBudget.seasonEndDayNumber);
          }

          this.FormWaterBudget?.$setPristine();

          defer.resolve();
        },
        (error) => {
          defer.reject();

          if (!this.cancelled) {
            this._languageService.whoops();
          }
        },
      );

    return defer.promise;
  }

  private _saveWaterBudget() {
    const defer = this._q.defer();
    if (!this.waterBudget || !this.waterBudget.siteMonths) {
      defer.resolve();
      return defer.promise;
    }
    this._http.post(CommonHelper.getApiUrl('data/sites/SaveSiteMonthsBudget'), this.waterBudget.siteMonths).then(
      () => {
        defer.resolve();
      },
      (error) => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  public closeSiteMapSideNav() {
    this._mdSidenav('right').close();
    this._siteService.selectedAsset = null;
  }

  // shapeGeom should be a google.maps.data.* object
  private getShapeGeoJSON(shapeGeom, shapeAttrs): angular.IPromise<string> {
    const defer = this._q.defer<string>();

    try {
      const dataLayer = new google.maps.Data();

      dataLayer.add({
        geometry: shapeGeom,
        properties: shapeAttrs,
      });

      dataLayer.toGeoJson((data) => {
        // data is a feature collection - we want the first feature only
        if ((data as any).features?.length == 1) {
          defer.resolve(JSON.stringify((data as any).features[0]));
        } else {
          defer.reject('Could not get shape from dataLayer');
        }
      });
    } catch (err) {
      let ms = 'Could not get shape geoJSON ';

      if (err?.message) {
        ms = ms + err.message;
      }

      console.log(ms);

      defer.reject(ms);
    }

    return defer.promise;
  }

  private getDataPolygon(sitePolygon): google.maps.Data.Polygon {
    const outerCoords = [];
    // const innerCoords2 = [];
    const paths = sitePolygon.getPath().getArray();

    for (let pathIdx = 0; pathIdx < paths.length; pathIdx++) {
      const tmpLatitude = paths[pathIdx].lat();
      const tmpLongitude = paths[pathIdx].lng();

      outerCoords.push({ lat: tmpLatitude, lng: tmpLongitude });
    }

    return new google.maps.Data.Polygon([outerCoords]);
  }

  public deleteSiteSettingCrop(row: SiteSettingsCrop): void {
    const confirm = this._languageService
      .confirm()
      .title('AC.SETTINGS.REMOVE_CROP')
      .htmlContent('AC.SETTINGS.REMOVE_TEXT', { date: DateUtils.Locale.asDateDefault(row.localDate) })
      .multiple(true)
      .ariaLabel('COMMON.REMOVE')
      .ok('COMMON.REMOVE')
      .cancel('COMMON.CANCEL');
    this._languageService.show(confirm).then(() => {
      row.entityAspect.setDeleted();
      let idx = this.siteCrop.indexOf(row);
      this.siteCrop.splice(idx, 1);
      if (idx > this.siteCrop.length) {
        idx = this.siteCrop.length;
      }
      //set former row as modified
      idx = idx >= 1 ? idx - 1 : 0;
      const formerRow = this.siteCrop[idx];
      this.setSiteCropId(formerRow.Id);
      formerRow.entityAspect.setModified();
    });
  }

  public deleteSiteSettingSoil(row: SiteSettingsSoil): void {
    const confirm = this._languageService
      .confirm()
      .title('AC.SETTINGS.REMOVE_SOIL')
      .htmlContent('AC.SETTINGS.REMOVE_TEXT', { date: DateUtils.Locale.asDateDefault(row.localDate) })
      .multiple(true)
      .ariaLabel('COMMON.REMOVE')
      .ok('COMMON.REMOVE')
      .cancel('COMMON.CANCEL');
    this._languageService.show(confirm.dialog).then(() => {
      row.entityAspect.setDeleted();
      let idx = this.siteSoil.indexOf(row);
      this.siteSoil.splice(idx, 1);
      if (idx > this.siteSoil.length) {
        idx = this.siteSoil.length;
      }
      //set former row as modified
      idx = idx >= 1 ? idx - 1 : 0;
      const formerRow = this.siteSoil[idx];
      this.setSiteSoilId(formerRow.Id);
      formerRow.entityAspect.setModified();
    });
  }

  public UpdateSMProbe(action: string, direct: string): void {
    const maximumModulate = 10;
    const minimumModulate = 0.1;
    const modulateStep = 0.1;
    const maximumOffset = 500;
    const minimumOffset = -500;
    const offsetStep = 1;
    this.SMProbeChanged = true;
    if (action == 'Modulate') {
      const roundValue = Math.round(this.SMProbeFactor * 10) / 10;
      const oldFactor = this.SMProbeFactor;
      if (direct == 'Up') {
        this.SMProbeFactor = roundValue >= maximumModulate ? maximumModulate : roundValue + modulateStep;
      } else if (direct == 'Down') {
        this.SMProbeFactor = roundValue <= minimumModulate ? minimumModulate : roundValue - modulateStep;
      }
      // if modulate is changed, the shift will be changed
      const oriProbeValues = this.siteWaterChartDataProvider.filter((a) => a.soilProbe != null).map((a) => a.soilProbe);
      const maxValue = Math.max(...oriProbeValues);
      const minValue = Math.min(...oriProbeValues);
      const oriMiddle = (maxValue + minValue) / 2;
      const newMiddle = oriMiddle * this.SMProbeFactor;
      const oldMiddle = oriMiddle * oldFactor;
      const middleDelta = newMiddle - oldMiddle;
      this.SMProbeOffset = Math.round(this.SMProbeOffset - middleDelta);
      if (this.SMProbeOffset > maximumOffset) {
        this.SMProbeOffset = maximumOffset;
      }
      if (this.SMProbeOffset < minimumOffset) {
        this.SMProbeOffset = minimumOffset;
      }
    }
    if (action == 'Shift') {
      const roundValue = Math.round(this.SMProbeOffset);
      if (direct == 'Up') {
        this.SMProbeOffset = roundValue >= maximumOffset ? maximumModulate : roundValue + offsetStep;
      } else if (direct == 'Down') {
        this.SMProbeOffset = roundValue <= minimumOffset ? minimumOffset : roundValue - offsetStep;
      }
    }
    this.soilMoistureProbeChanged();
  }

  private soilMoistureProbeChanged() {
    this.keepUserZoom = true;

    this.siteWaterChartDataProvider.forEach((item) => {
      if (item.soilProbe) {
        item.soilProbeGraphValue = item.soilProbe * this.SMProbeFactor + this.SMProbeOffset;
        const moistureRaw = Math.round(((item.soilProbeGraphValue - item.smTargetLow) * item.workingDepth) / 10.0) / 10;
        item.rawSoilProbeGraphValue = moistureRaw;
        this.SMProbeFactorString = this.SMProbeFactor.toFixed(1);
        this.SMProbeOffsetString = this.SMProbeOffset.toFixed(1);
      } else {
        item.soilProbeGraphValue = null;
        item.rawSoilProbeGraphValue = null;
      }
    });

    this.showSiteWaterChart();
  }

  public RemoveSMProbe(): void {
    this.SMProbeFactor = 1;
    this.SMProbeOffset = 0;
    this.SMProbeChanged = true;
    this.soilMoistureProbeChanged();
  }

  public ResetSMProbe(assetId: number): void {
    const data = {
      assetId: assetId,
    } as fuse.soilMoistureProbeDto;
    this._http
      .post(CommonHelper.getApiUrl('site/resetSMProbe'), JSON.stringify(data))
      .then((response) => {
        const result = response.data as fuse.soilMoistureProbeDto;
        this.SMProbeFactor = result.factor;
        this.SMProbeOffset = result.offset;
        this.SMProbeChanged = false;
        this.soilMoistureProbeChanged();
      })
      .catch(() => {
        this._languageService.error('AC.SITE.MSG.REQUEST_FAILED');
      });
  }

  public SaveSMProbe(assetId: number): void {
    const data = {
      assetId: assetId,
      factor: this.SMProbeFactor,
      offset: this.SMProbeOffset,
    } as fuse.soilMoistureProbeDto;
    this._http
      .post(CommonHelper.getApiUrl('site/updateSMProbe'), JSON.stringify(data))
      .then(() => {
        this.SMProbeChanged = false;
        this._languageService.info('COMMON.SUCCESS');
      })
      .catch(() => {
        this._languageService.whoops();
      });
  }

  private getAccountDetail(accountId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: accountId };
    this._http
      .get(CommonHelper.getApiUrl('site/getAccountInfo'), { params: params, timeout: this.httpCanceller.promise })
      .then(
        (response) => {
          if (!response.data) {
            this._languageService.whoops();
          } else {
            this.siteAccountInfo = response.data as fuse.siteAccountInfoDto;
            const currentDate = new Date();
            this.irrigationSeasonStart = new Date(
              this.siteAccountInfo.irrigationSeasonStartMonth - 1 > currentDate.getMonth()
                ? currentDate.getFullYear() - 1
                : currentDate.getFullYear(),
              this.siteAccountInfo.irrigationSeasonStartMonth - 1,
              1,
            );
          }
          defer.resolve();
        },
        () => {
          defer.reject();
        },
      );
    return defer.promise;
  }

  private getSiteIrrigationPlanInfo(siteId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._accountService.getSiteIrrigationInfo(siteId, this.httpCanceller.promise).then(
      (result) => {
        this.siteIrrigationPlanInfo = result;
        this.hasIrrigationPlan = this.siteIrrigationPlanInfo?.planStatus == 'Active';

        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );

    return defer.promise;
  }

  public scheduleApiEnableChanged(): void {
    if (this.permissionService.hasSubscription('Schedule Api') || this.permissionService.hasSubscription('Irrigation Control')) {
      this.asset_.EnableScheduleApi = this.isScheduleApiEnabled;
    } else {
      this.isScheduleApiEnabled = false;
      const alert = this._languageService
        .alert()
        .htmlContent('AC.SITE.MSG.API_ACCESS')
        .parent(angular.element(document.body))
        .targetEvent();
      this._languageService.show(alert);
    }
  }

  public gotoIrrigationPlan(planId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.planId = planId;
    });

    this._state.go('app.water.irrigation-plans.detail', { id: planId });
  }

  public toggleIrrigationLock(item: fuse.soilMoisturePrediction) {
    item.irrigationLocked = !item.irrigationLocked;

    const query = breeze.EntityQuery.from('SiteDailySummary').orderBy('dayNumber');
    const Obs = this.entityManager.executeQueryLocally(query) as SiteDailySummary[];

    if (this.siteWaterChartDataProvider.length) {
      Obs.forEach((dailySummary) => {
        if (dailySummary.dayNumber == item.dayNumber) {
          dailySummary.IrrigationLocked = item.irrigationLocked;
        }
      });
    }
  }

  private getSiteMaximumIrrigationValue(siteDailySummary: fuse.soilMoisturePrediction): number {
    const siteSettingWater = this.siteIrrigationPlanInfo.siteSettingWaters.find((a) => a.dayNumber <= siteDailySummary.dayNumber);

    if (!siteSettingWater) {
      return null
    };

    return siteSettingWater.maximumIrrigationValue;
  }

  public openCropGraphDialog() {
    this._mdDialog
      .show({
        controller: CropGraphDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/settingsTabs/crop/cropgraph-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          cropSettings: this.siteCrop,
        },
      } as angular.material.IDialogOptions)
      .then((returnData) => {
        if (returnData) {
          if (returnData.dataRefreshRequired) {
          }
        }
      });
  }

  public openSiteRolloverDialog() {
    this._mdDialog
      .show({
        controller: SiteRolloverDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/settingsTabs/crop/site-kcRollover-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        escapeToClose: true,
      })
      .then((returnData) => {
        if (returnData) {
          const settings = returnData as fuse.siteCropSettingsRolloverDto;
          const params = {
            name: this.asset_.Name,
            cropsoil: this._languageService.instant('AC.SETTINGS.ROLLOVER_CROP' + (settings.isWithWorkingDepth ? 'SOIL' : '')),
            start: DateUtils.Locale.asDateDefault(settings.fromDate),
            end: DateUtils.Locale.asDateDefault(settings.endDate),
            year: settings.targetYear.toString(),
            soiltext: settings.isWithWorkingDepth ? this._languageService.instant('AC.SETTINGS.ROLLOVER_SOIL_TEXT') : '',
          };
          const confirm = this._languageService
            .confirm()
            .title('COMMON.CONFIRM')
            .htmlContent('AC.SETTINGS.ROLLOVER_TEXT', params, false) // Disable escaping HTML characters in string interpolation.
            .parent(angular.element(document.body))
            .ok('COMMON.ROLLOVER')
            .cancel('COMMON.CANCEL');

          this._languageService.show(confirm.dialog).then(() => {
            this._http.post(CommonHelper.getApiUrl('site/siteCropSettingsRollover'), settings).then(
              () => {
                //Adjust the filter dates, so that the new settings wil be in range
                const newMin = new Date(settings.targetYear, settings.fromDate.getMonth(), settings.fromDate.getDate());
                const newMax = newMin.clone().addDays(settings.endDayNumber - settings.fromDayNumber);
                this._fetchSiteSettingsData(); //Reload site settings as they would have now changed.

                this.filterEffectiveTo = newMax;
                this.filterEffectiveFrom = newMin;
                this._languageService.success('AC.SITE.MSG.SETTINGS_ROLLED_OUT');
              },
              (returnData) => {
                this._languageService.handleError(returnData);
              },
            );
          });
        }
      });
  }

  public WaterBudgetMLChange(siteMonth: fuse.siteMonthDto) {
    if (siteMonth.water_KL <= 0) {
      siteMonth.createPlanFromBudget = false;
    }
  }

  public changeSiteSummary() {
    this.isSiteNameExist = false;
    if (this.asset_.Name != null) {
      if (this.imuSiteList.some((a) => a.id != this.asset_.AssetId && a.name.toLowerCase() == this.asset_.Name.toLowerCase())) {
        this.isSiteNameExist = true;
      }
    }
  }

  public editSiteArea(area: number, irrigatedArea: boolean, fertiliserArea: boolean) {
    if (!this.site_.Area) {
      let areaType = irrigatedArea ? 'IRRIGATED_AREA' : 'FERTILISER_AREA';
      areaType = this._languageService.instant('AC.SETTINGS.' + areaType);
      this._languageService.warning('AC.SITE.MSG.CANNOT_SET_SITE', 'COMMON.WARNING', { type: areaType });
      return;
    }

    this._mdDialog
      .show({
        controller: SiteAreaDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/settingsTabs/sitearea-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {
          irrigatedAreaSelected: irrigatedArea,
          fertiliserAreaSelected: fertiliserArea,
          area: area,
        },
      })
      .then((returnData) => {
        if (returnData) {
          const settings = returnData as fuse.SiteAreaDto;
          const promises: angular.IPromise<any>[] = [];

          if (settings.area > this.site_.Area) {
            promises.push(this.showConfirmAreaDialog(settings));
          }

          this._q.all(promises).then(() => {
            if (irrigatedArea) {
              const dayNumber = this._siteSettingService.siteWater.DayNumber;

              if (settings.applyToIrrigatedArea) {
                this._siteSettingService.siteWater.CropWaterArea = settings.area;

                if (settings.applyToFutureSettings) {
                  this.siteWater.forEach((settingWater) => {
                    if (settingWater.DayNumber > dayNumber) {
                      settingWater.CropWaterArea = settings.area;
                    }
                  });
                }
              }

              if (settings.applyToFertiliserArea) {
                const settingNutrient = this.siteNoots.find((a) => a.DayNumber == dayNumber);
                if (!settingNutrient) {
                  const nootsFromLatest = this.siteNoots.slice().reverse();
                  const setting = nootsFromLatest.find((a) => a.DayNumber < dayNumber) ?? nootsFromLatest[0];
                  const newNootSetting = this._groupSettingService.addNewSetting('NutrientSettings', setting) as SiteSettingsNutrients;

                  newNootSetting.localDate = this._siteSettingService.siteWater.localDate;
                  newNootSetting.DayNumber = dayNumber;
                  newNootSetting.CropNutrientArea = settings.area;

                  this.siteNoots.push(newNootSetting);
                  this.siteNoots = ArrayUtils.sortByNumberWithMutation(this.siteNoots, (x) => x.DayNumber);

                  this.setSiteNootsId(newNootSetting.Id);
                } else {
                  settingNutrient.CropNutrientArea = settings.area;
                }

                if (settings.applyToFutureSettings) {
                  this.siteNoots.forEach((settingNutrient) => {
                    if (settingNutrient.DayNumber > dayNumber) {
                      settingNutrient.CropNutrientArea = settings.area;
                    }
                  });
                }
              }
            } else if (fertiliserArea) {
              const dayNumber = this._siteSettingService.siteNoot.DayNumber;

              if (settings.applyToFertiliserArea) {
                this._siteSettingService.siteNoot.CropNutrientArea = settings.area;

                if (settings.applyToFutureSettings) {
                  this.siteNoots.forEach((settingNutrient) => {
                    if (settingNutrient.DayNumber > dayNumber) {
                      settingNutrient.CropNutrientArea = settings.area;
                    }
                  });
                }
              }

              if (settings.applyToIrrigatedArea) {
                const settingWater = this.siteWater.find((a) => a.DayNumber == dayNumber);

                if (!settingWater) {
                  const settingsFromLatest = this.siteWater.slice().reverse();
                  const lastSettingWater = settingsFromLatest.find((a) => a.DayNumber < dayNumber) ?? settingsFromLatest[0];
                  const newWaterSetting = this._groupSettingService.addNewSetting('WaterSettings', lastSettingWater) as SiteSettingsWater;

                  newWaterSetting.localDate = this._siteSettingService.siteNoot.localDate;
                  newWaterSetting.DayNumber = dayNumber;
                  newWaterSetting.CropWaterArea = settings.area;

                  this.siteWater.push(newWaterSetting);
                  this.siteWater = ArrayUtils.sortByNumberWithMutation(this.siteWater, (x) => x.DayNumber);

                  this.setSiteWaterId(newWaterSetting.Id);
                } else {
                  settingWater.CropWaterArea = settings.area;
                }

                if (settings.applyToFutureSettings) {
                  this.siteWater.forEach((settingWater) => {
                    if (settingWater.DayNumber > dayNumber) {
                      settingWater.CropWaterArea = settings.area;
                    }
                  });
                }
              }
            }
          });
        }
      });
  }

  public showConfirmAreaDialog(settings: fuse.SiteAreaDto): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { spec: this.areaUnit.fromBaseText(settings.area), mapped: this.areaUnit.fromBaseText(this.site_.Area) };
    const confirm = this._languageService
      .confirm()
      .title('COMMON.CONFIRM')
      .htmlContent('AC.SETTINGS.AREA_EXCEEDS_MAPPED', params)
      .ok('COMMON.YES')
      .cancel('COMMON.NO');
    this._languageService.show(confirm.dialog).then(
      () => {
        this._mdDialog.hide();
        defer.resolve();
      },
      () => {
        this._mdDialog.hide();
        defer.reject();
      },
    );
    return defer.promise;
  }

  private updateSiteWeatherStations(weatherStations: fuse.weatherStationDto[]): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const body = {
      siteId: this.siteId,
      weatherStations: weatherStations,
    };
    this._http.post(CommonHelper.getApiUrl('site/updateWeatherStations'), body).then(
      () => {
        this.isWeatherSettingChanged = false;
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private updateSiteKcviSettings(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const body = this.siteKcviChanges;
    this._http.post(CommonHelper.getApiUrl('site/updateKcviSettings'), body).then(
      () => {
        this.isKcviSettingChanged = false;
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  public onKcviChanged(kcviChanges: fuse.siteKcviChangesDto) {
    this.isKcviSettingChanged = true;
    this.siteKcviChanges = kcviChanges;
  }

  public onSiteSettingsWeatherChanged(weatherStations: fuse.weatherStationDto[]) {
    this.isWeatherSettingChanged = true;
    this.weatherStations = weatherStations;
  }

  private labelFunc(prefix, unitType, unitSize = unitSizes.normal) {
    return (graphDataItem, graph) => {
      const val = (graphDataItem.values as any).value;
      return this.valWithUnits(val, prefix, unitType, unitSize);
    };
  }

  private valWithUnits(val, prefix, unitType, unitSize = unitSizes.normal) {
    if (val) {
      if (unitType != '%') {
        unitType = this._unitOfMeasureService.getUnitLabel(unitType, unitSize);
      } else {
        val = val.toFixed(1);
      }
      return `${prefix}: ${val} ${unitType}`;
    } else {
      return '';
    }
  }

  private amWeatherChart() {
    return {
      type: 'serial',
      synchronizeGrid: true,
      chartScrollbar: {},
      startDuration: 0,
      legend: {
        useGraphSettings: true,
        position: 'top',
        valueAlign: 'left',
        horizontalGap: 5,
        spacing: 1,
        valueFunction: (graphDataItem, graph) => {
          if (graphDataItem.values) {
            const val = (graphDataItem.values as any).value;
            if (val) {
              return val.toFixed(1);
            }
          }
          return '';
        },
      },
      theme: 'light',
      addClassNames: true,
      autoMarginOffset: 10,
      autoMargins: true,
      marginTop: 5,
      marginRight: 15,
      mouseWheelZoomEnabled: false,
      valueAxes: [
        {
          id: 'degreeAxis',
          title: this.temperatureUnit,
          axisAlpha: 0,
          position: 'left',
        },
        {
          id: 'percentAxis',
          title: '%',
          axisAlpha: 0,
          position: 'left',
          offset: 50,
        },
        {
          id: 'mmAxis',
          title: this.fluidDepthUnit,
          axisAlpha: 0,
          position: 'right',
        },
        {
          id: 'srAxis',
          title: this.energyAreaDayUnit,
          axisAlpha: 0,
          position: 'right',
          offset: 50,
        },
        {
          id: 'windspeedAxis',
          title: this.velocityUnit,
          axisAlpha: 0,
          position: 'left',
          offset: 100,
        },
      ],
      graphs: [
        this.plot('Rainfall', 'COMMON.WEATHER_READINGS.RAINFALL', UnitTypes.FluidDepth, {
          markerType: 'square',
          valueAxis: 'mmAxis' as any,
          alphaField: 'alpha',
          fillAlphas: 0.8,
          lineColor: '#2196F3',
          type: 'column',
        }),
        this.plot('EToShort', 'COMMON.WEATHER_READINGS.ETO', UnitTypes.FluidDepth, {
          markerType: 'square',
          valueAxis: 'mmAxis' as any,
          alphaField: 'alpha',
          fillAlphas: 0.8,
          lineColor: '#A9548A',
          type: 'column',
          clustered: false,
          columnWidth: 0.5,
        }),
        this.plot('TemperatureMax', 'COMMON.WEATHER_READINGS.TEMP_MAX', UnitTypes.Temperature, {
          valueAxis: 'degreeAxis' as any,
          alphaField: 'alpha',
          lineColor: '#FFBC33',
          type: 'line',
        }),
        this.plot('TemperatureMean', 'COMMON.WEATHER_READINGS.TEMP_MEAN', UnitTypes.Temperature, {
          valueAxis: 'degreeAxis' as any,
          alphaField: 'alpha',
          fillAlphas: 0,
          lineColor: '#FFBC33',
          type: 'line',
          hidden: true,
        }),
        this.plot('TemperatureMin', 'COMMON.WEATHER_READINGS.TEMP_MIN', UnitTypes.Temperature, {
          valueAxis: 'degreeAxis' as any,
          alphaField: 'alpha',
          fillAlphas: 0.6,
          fillToGraph: 'temperatureMax' as any,
          lineColor: '#FFBC33',
          type: 'line',
        }),
        this.plot('HumidityMax', 'COMMON.WEATHER_READINGS.HUMIDITY_MAX', '%', {
          valueAxis: 'percentAxis',
          alphaField: 'alpha',
          fillAlphas: 0,
          lineColor: '#4484FF',
          type: 'line',
          hidden: true,
        }),
        this.plot('HumidityMean', 'COMMON.WEATHER_READINGS.HUMIDITY_MEAN', '%', {
          valueAxis: 'percentAxis',
          alphaField: 'alpha',
          fillAlphas: 0,
          lineColor: '#88BCFF',
          type: 'line',
          hidden: true,
        }),
        this.plot('HumidityMin', 'COMMON.WEATHER_READINGS.HUMIDITY_MIN', '%', {
          valueAxis: 'percentAxis',
          alphaField: 'alpha',
          fillAlphas: 0.2,
          fillToGraph: 'humiditymax',
          lineColor: '#4484FF',
          type: 'line',
          hidden: true,
        }),
        this.plot('WindSpeedMean', 'COMMON.WEATHER_READINGS.WINDSPEED_MEAN', UnitTypes.Velocity, {
          valueAxis: 'windspeedAxis',
          alphaField: 'alpha',
          fillAlphas: 0,
          lineColor: '#A9548A',
          lineThickness: 2,
          type: 'line',
          hidden: true,
        }),
        this.plot('SolarRadiation', 'COMMON.WEATHER_READINGS.SOLAR_RADIATION', UnitTypes.SolarRadiation, {
          valueAxis: 'srAxis',
          alphaField: 'alpha',
          fillAlphas: 0,
          lineColor: '#FFFF00',
          type: 'line',
          lineThickness: 2,
          hidden: true,
        }),
      ],
      chartCursor: {
        oneBalloonOnly: true,
        pan: false,
        zoomable: false,
        valueLineEnabled: true,
        valueLineBalloonEnabled: true,
        cursorAlpha: 0.2,
        valueLineAlpha: 0.2,
      },
      categoryField: 'dayDate',
      categoryAxis: {
        parseDates: true,
        title: this._languageService.instant('AC.SITE.OBSERVATION_DATES'),
        dashLength: 1,
        minorGridEnabled: true,
      },
    };
  }

  private plot(
    valueField: string,
    title: string,
    unitType: string,
    extra: {} = {},
    unitSize: unitSizes = unitSizes.normal,
  ): AmCharts.AmGraph {
    const translated = this._languageService.instant(title);
    const label = !!extra['labelTitle'] ? this._languageService.instant(extra['labelTitle']) : translated;
    let id = valueField;

    //Bit of a hack, but converts ids used for the RAW plot to use same show/hide variables in the % moisture plot
    if (id.startsWith('raw')) {
      if (id == 'raw') {
        id = 'soilMoisture';
      }
      if (id.startsWith('rawTarget')) {
        id = id.replace('raw', 'sm');
      } else {
        id = id.replace('raw', '');
      }
    }
    id = id[0].toLowerCase() + id.slice(1, id.length);

    // Hack so that soil probes are always selectable
    if ((this.waterChartParams[id] ?? true) || id === 'soilProbeGraphValue') {
      this.waterChartParams[id] = true;
    }

    const params = {
      id: id,
      valueField: valueField,
      title: translated,
      balloonFunction: this.labelFunc(label, unitType, unitSize),
      clustered: false,
      columnWidth: 0.9,
      alphaField: 'alpha',
      hidden: !this.waterChartParams[id],
    } as AmCharts.AmGraph;

    Object.keys(extra).forEach((key) => {
      params[key] = extra[key];
    });
    return params;
  }

  private defineWaterChartOption() {
    if (this.waterChartParams.soilMoistureUnit == '%') {
      const mmAxis = {
        id: 'mmAxis',
        dashLength: 1,
        position: 'left',
        title: this._languageService.instant('AC.SITE.IRRIGATION_RAIN_UNITS', { units: this.fluidDepthUnit.name }),
        minimum: 0,
      } as AmCharts.ValueAxis;

      const smAxis = {
        id: 'smAxis',
        dashLength: 1,
        position: 'right',
        title: this._languageService.instant('AC.SITE.SOIL_MOISTURE_PERCENT'),
        minimum: 0,
      } as AmCharts.ValueAxis;

      const saturationPoint = this.plot('saturationPoint', 'COMMON.SATURATION_POINT', '%', {
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: false,
        dashLength: 5,
        lineColor: '#824242',
        showBalloon: true,
      });

      const soilMoistureTargetHigh = this.plot('smTargetHigh', 'COMMON.ABOVE_TARGET_RANGE', '%', {
        labelTitle: 'COMMON.UPPER_TARGET',
        valueAxis: smAxis,
        type: 'line',
        lineColor: '#0084CA',
        lineAlpha: 0.1,
        fillColors: ['#ffffff', '#0084CA'],
        fillAlphas: 0.4,
        fillToGraph: saturationPoint,
        markerType: 'square',
      });

      const soilMoistureTargetRange = this.plot('smTargetLow', 'COMMON.TARGET_RANGE', '%', {
        valueAxis: smAxis,
        type: 'line',
        lineColor: '#3FA441',
        lineAlpha: 0.1,
        fillAlphas: 0.4,
        fillToGraph: soilMoistureTargetHigh,
        markerType: 'square',
      });

      const soilMoistureTargetLow = this.plot('smTargetBase', 'COMMON.BELOW_TARGET_RANGE', '%', {
        valueAxis: smAxis,
        type: 'line',
        lineColor: '#FFC107',
        lineAlpha: 0.1,
        fillColors: ['#FFC107', '#ffffff'],
        fillAlphas: 0.4,
        fillToGraph: soilMoistureTargetRange,
        markerType: 'square',
        showBalloon: false,
      });

      const rainfall = this.plot('rainfallObs', 'COMMON.WEATHER_READINGS.RAINFALL', UnitTypes.FluidDepth, {
        valueAxis: mmAxis,
        type: 'column',
        clustered: false,
        columnWidth: 0.9,
        alphaField: 'alpha',
        fillAlphas: 0.8,
        lineColor: '#2196F3',
        markerType: 'square',
      });

      const irrigation = this.plot('irrigation', 'COMMON.IRRIGATION', UnitTypes.FluidDepth, {
        valueAxis: mmAxis,
        type: 'column',
        clustered: false,
        columnWidth: 0.6,
        alphaField: 'alpha',
        fillAlphas: 1,
        lineColor: '#1A237E',
        markerType: 'square',
      });

      const irrigationPlanned = this.plot('irrigationPlanned', 'COMMON.PLANNED', UnitTypes.FluidDepth, {
        valueAxis: mmAxis,
        type: 'column',
        clustered: false,
        columnWidth: 0.4,
        alphaField: 'alpha',
        fillAlphas: 1,
        lineColor: '#BDB76B',
        markerType: 'square',
      });

      const irrigationBudget = this.plot('irrigationBudget', 'COMMON.BUDGET', UnitTypes.FluidDepth, {
        valueAxis: mmAxis,
        type: 'column',
        clustered: false,
        columnWidth: 0.2,
        alphaField: 'alpha',
        fillAlphas: 1,
        lineColor: '#008000',
        markerType: 'square',
      });

      const fieldCapacity = this.plot('fieldCapacity', 'COMMON.FIELD_CAPACITY', '%', {
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: false,
        dashLength: 8,
        lineColor: '#000000',
        showBalloon: true,
      });
      
      const wiltingPoint = this.plot('wiltingPoint', 'COMMON.PERMANENT_WILTING_POINT', '%', {
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: false,
        dashLength: 4,
        lineColor: '#900C3F',
        showBalloon: true,
      });

      const soilProbe = this.plot('soilProbeGraphValue', 'AC.SITE.SOIL_PROBE_READING', '%', {
        bullet: 'round',
        bulletBorderAlpha: 1,
        hideBulletsCount: 50,
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: false,
        bulletColor: '#FFFFFF',
        bulletBorderColor: '#10A6A6',
        lineColor: '#0d47a1',
        connect: false,
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          const avg = this._languageService.instant('AC.SITE.PROBE_AVG');
          const val = (graphDataItem.values as any).value;
          if (val) {
            let balloonText = this.valWithUnits(val, avg, '%');
            if (this.SMProbeOffset != 0 && this.SMProbeFactor != 1) {
              balloonText += this._languageService.instant('AC.SITE.OFFSET_MODULATED', { offset: this.SMProbeOffset.toFixed(1) });
            } else if (this.SMProbeOffset != 0) {
              balloonText += this._languageService.instant('AC.SITE.OFFSET', { offset: this.SMProbeOffset.toFixed(1) });
            } else if (this.SMProbeFactor != 1) {
              balloonText += this._languageService.instant('AC.SITE.MODULATED');
            }
            return balloonText;
          } else {
            return '';
          }
        },
        visibleInLegend: !!this.availableProbes.length,
      });

      const soilmoisture = this.plot('soilMoisture', 'AC.SITE.SOIL_MOIS_CALCD', '%', {
        bullet: 'round',
        bulletBorderAlpha: 1,
        hideBulletsCount: 50,
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: true,
        lineColor: '#824242',
        connect: false,
      });

      this.siteWaterChartOption = {
        type: 'serial',
        theme: 'light',
        marginTop: 5,
        marginRight: 15,
        marginLeft: 15,
        listeners: [
          {
            event: 'zoomed',
            method: (e) => this.amChartSDSZoomed(e),
          },
          {
            event: 'dataUpdated',
            method: (e) => this.amChartSDSDataUpdated(e),
          },
        ] as Object[],
        valueAxes: [mmAxis, smAxis],
        synchronizeGrid: false,
        chartScrollbar: {},
        startDuration: 0,
        zoomOutText: this._languageService.instant('COMMON.SHOW_ALL'),
        legend: {
          useGraphSettings: true,
          position: 'top',
          valueAlign: 'left',
          horizontalGap: 5,
          spacing: 1,
          valueFunction: (graphDataItem, graph) => {
            if (graphDataItem.values) {
              const val = graphDataItem.values.value;
              if ((graphDataItem as any).graph.valueAxis.id == 'mmAxis') {
                return val;
              } else {
                if (val) {
                  return val.toFixed(1);
                }
              }
            }
            return '';
          },
          listeners: [
            {
              event: 'hideItem',
              method: (e) => {
                const legendId = e.dataItem['id'];
                this.waterChartParams[legendId] = false;
                this._localStorageService.set('waterChartParams', this.waterChartParams);
              },
            },
            {
              event: 'showItem',
              method: (e) => {
                const legendId = e.dataItem['id'];
                this.waterChartParams[legendId] = true;
                this._localStorageService.set('waterChartParams', this.waterChartParams);
              },
            },
          ],
        } as any as AmCharts.AmLegend,
        addClassNames: true,
        autoMarginOffset: 5,
        autoMargins: true,
        mouseWheelZoomEnabled: false,
        graphs: [
          soilMoistureTargetHigh,
          soilMoistureTargetRange,
          soilMoistureTargetLow,
          wiltingPoint,
          fieldCapacity,
          saturationPoint,
          rainfall,
          irrigation,
          irrigationPlanned,
          irrigationBudget,
          soilProbe.hidden ? null : soilProbe,
          soilmoisture,
        ].filter((graph) => {
          return graph != null;
        }),
        chartCursor: {
          oneBalloonOnly: true,
          pan: false,
          zoomable: false,
          valueLineEnabled: true,
          valueLineBalloonEnabled: true,
          categoryBalloonDateFormat: 'EEE DD MMM, YYYY',
          cursorAlpha: 0.2,
          valueLineAlpha: 0.2,
        } as any as AmCharts.ChartCursor,
        categoryField: 'dayDate',
        categoryAxis: {
          parseDates: true,
          axisColor: '#DADADA',
          dashLength: 1,
          minorGridEnabled: false,
          markPeriodChange: false,
          dateFormats: [
            { period: 'DD', format: 'MMM DD' },
            { period: 'WW', format: 'MMM DD' },
            { period: 'MM', format: 'MMM' },
            { period: 'YYYY', format: 'YYYY' },
          ],
        } as any as AmCharts.CategoryAxis,
      } as any as AmCharts.AmSerialChart;
    } else if (this.waterChartParams.soilMoistureUnit != '%') {
      const mmAxis = {
        id: 'mmAxis',
        dashLength: 1,
        position: 'left',
        title: this._languageService.instant('AC.SITE.IRRIGATION_RAIN_UNITS', { units: this.fluidDepthUnit.name }),
        minimum: 0,
      } as AmCharts.ValueAxis;

      const smAxis = {
        id: 'smAxis',
        dashLength: 1,
        position: 'right',
        title: this._languageService.instant('AC.SITE.RAW_FULL_UNITS', { units: this.fluidDepthUnit.name }),
        precision: this.fluidDepthNormalUnitDecimal,
      } as AmCharts.ValueAxis;

      const saturationPointRaw = this.plot('rawSaturationPoint', 'COMMON.SATURATION_POINT', UnitTypes.FluidDepth, {
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: false,
        dashLength: 5,
        lineColor: '#824242',
        showBalloon: true,
      });

      const soilMoistureTargetHighRaw = this.plot('rawTargetHigh', 'COMMON.ABOVE_TARGET_RANGE', UnitTypes.FluidDepth, {
        valueAxis: smAxis,
        type: 'line',
        lineColor: '#0084CA',
        lineAlpha: 0.1,
        fillColors: ['#ffffff', '#0084CA'],
        fillAlphas: 0.4,
        fillToGraph: saturationPointRaw,
        markerType: 'square',
      });

      const soilMoistureTargetRangeRaw = this.plot('rawTargetLow', 'COMMON.TARGET_RANGE', UnitTypes.FluidDepth, {
        valueAxis: smAxis,
        type: 'line',
        lineColor: '#3FA441',
        lineAlpha: 0.1,
        fillAlphas: 0.4,
        markerType: 'square',
        fillToGraph: soilMoistureTargetHighRaw,
        showBalloon: false,
      });

      const soilMoistureTargetLowRaw = this.plot('rawTargetBase', 'COMMON.BELOW_TARGET_RANGE', UnitTypes.FluidDepth, {
        valueAxis: smAxis,
        type: 'line',
        lineColor: '#FFC107',
        lineAlpha: 0.1,
        fillColors: ['#FFC107', '#ffffff'],
        fillAlphas: 0.4,
        negativeFillColors: ['#FFC107', '#ffffff'],
        negativeFillAlphas: 0.4,
        markerType: 'square',
        showBalloon: false,
      });

      const wiltingPointRaw = this.plot('rawWiltingPoint', 'COMMON.PERMANENT_WILTING_POINT', UnitTypes.FluidDepth, {
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: false,
        dashLength: 4,
        lineColor: '#900C3F',
        showBalloon: true,
      });

      const rainfall = this.plot('rainfallObs', 'COMMON.WEATHER_READINGS.RAINFALL', UnitTypes.FluidDepth, {
        valueAxis: mmAxis,
        type: 'column',
        clustered: false,
        columnWidth: 0.9,
        alphaField: 'alpha',
        fillAlphas: 0.8,
        lineColor: '#2196F3',
        markerType: 'square',
      });

      const irrigation = this.plot('irrigation', 'COMMON.IRRIGATION', UnitTypes.FluidDepth, {
        valueAxis: mmAxis,
        type: 'column',
        clustered: false,
        columnWidth: 0.6,
        alphaField: 'alpha',
        fillAlphas: 1,
        lineColor: '#1A237E',
        markerType: 'square',
      });

      const irrigationPlanned = this.plot('irrigationPlanned', 'COMMON.PLANNED', UnitTypes.FluidDepth, {
        valueAxis: mmAxis,
        type: 'column',
        clustered: false,
        columnWidth: 0.4,
        alphaField: 'alpha',
        fillAlphas: 1,
        lineColor: '#BDB76B',
        markerType: 'square',
      });

      const irrigationBudget = this.plot('irrigationBudget', 'COMMON.BUDGET', UnitTypes.FluidDepth, {
        valueAxis: mmAxis,
        type: 'column',
        clustered: false,
        columnWidth: 0.2,
        alphaField: 'alpha',
        fillAlphas: 1,
        lineColor: '#008000',
        markerType: 'square',
      });

      const fieldCapacityRaw = this.plot('rawFieldCapacity', 'COMMON.FIELD_CAPACITY', UnitTypes.FluidDepth, {
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: false,
        dashLength: 8,
        lineColor: '#000000',
        showBalloon: true,
      });

      const soilProbeRaw = this.plot('rawSoilProbeGraphValue', 'AC.SITE.SOIL_PROBE_READING', UnitTypes.FluidDepth, {
        labelTitle: 'AC.SITE.PROBE_AVG',
        bullet: 'round',
        bulletBorderAlpha: 1,
        hideBulletsCount: 50,
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: false,
        bulletColor: '#FFFFFF',
        bulletBorderColor: '#10A6A6',
        lineColor: '#0d47a1',
        connect: false,
        visibleInLegend: !!this.availableProbes.length,
      });

      const soilmoistureRaw = this.plot('raw', 'AC.SITE.SOIL_MOIS_CALCD', UnitTypes.FluidDepth, {
        bullet: 'round',
        bulletBorderAlpha: 1,
        hideBulletsCount: 50,
        valueAxis: smAxis,
        type: 'line',
        useLineColorForBulletBorder: true,
        lineColor: '#824242',
        connect: false,
      });

      this.siteWaterChartOption = {
        type: 'serial',
        theme: 'light',
        marginTop: 5,
        marginRight: 15,
        marginLeft: 15,
        listeners: [
          {
            event: 'zoomed',
            method: (e) => this.amChartSDSZoomed(e),
          },
          {
            event: 'dataUpdated',
            method: (e) => this.amChartSDSDataUpdated(e),
          },
        ] as Object[],
        valueAxes: [mmAxis, smAxis],
        // synchronizeGrid: false,
        chartScrollbar: {},
        startDuration: 0,
        zoomOutText: this._languageService.instant('COMMON.SHOW_ALL'),
        legend: {
          useGraphSettings: true,
          position: 'top',
          valueAlign: 'left',
          horizontalGap: 5,
          spacing: 1,
          valueFunction: (graphDataItem, graph) => {
            if (graphDataItem.values) {
              const val = graphDataItem.values.value;
              if (val) {
                return val.toFixed(1);
              }
            }
            return '';
          },
          listeners: [
            {
              event: 'hideItem',
              method: (e) => {
                const legendId = e.dataItem['id'];
                this.waterChartParams[legendId] = false;
                this._localStorageService.set('waterChartParams', this.waterChartParams);
              },
            },
            {
              event: 'showItem',
              method: (e) => {
                const legendId = e.dataItem['id'];
                this.waterChartParams[legendId] = true;
                this._localStorageService.set('waterChartParams', this.waterChartParams);
              },
            },
          ],
        } as any as AmCharts.AmLegend,
        addClassNames: true,
        autoMarginOffset: 5,
        autoMargins: true,
        mouseWheelZoomEnabled: false,
        graphs: [
          soilMoistureTargetHighRaw,
          soilMoistureTargetRangeRaw,
          soilMoistureTargetLowRaw,
          wiltingPointRaw,
          fieldCapacityRaw,
          saturationPointRaw,
          rainfall,
          irrigation,
          irrigationPlanned,
          irrigationBudget,
          soilProbeRaw.hidden ? null : soilProbeRaw,
          soilmoistureRaw,
        ].filter((graph) => {
          return graph != null;
        }),
        chartCursor: {
          oneBalloonOnly: true,
          pan: false,
          zoomable: false,
          valueLineEnabled: true,
          valueLineBalloonEnabled: true,
          categoryBalloonDateFormat: 'EEE DD MMM, YYYY',
          cursorAlpha: 0.2,
          valueLineAlpha: 0.2,
        } as any as AmCharts.ChartCursor,
        categoryField: 'dayDate',
        categoryAxis: {
          parseDates: true,
          axisColor: '#DADADA',
          dashLength: 1,
          minorGridEnabled: false,
          markPeriodChange: false,
          dateFormats: [
            { period: 'DD', format: 'MMM DD' },
            { period: 'WW', format: 'MMM DD' },
            { period: 'MM', format: 'MMM' },
            { period: 'YYYY', format: 'YYYY' },
          ],
        } as any as AmCharts.CategoryAxis,
      } as any as AmCharts.AmSerialChart;
    }
  }

  public siteWaterChartUnitChanged(): void {
    this._localStorageService.set('waterChartParams', this.waterChartParams);
    this.keepUserZoom = true;
    this.defineWaterChartOption();
    this.createSiteWaterChart();
    this.showSiteWaterChart();
  }

  private createSiteWaterChart() {
    this.siteWaterChart = AmCharts.makeChart('site-water-chart', this.siteWaterChartOption) as AmCharts.AmSerialChart;
    this.addGuideToday(this.siteWaterChart);
  }

  private showSiteWaterChart() {
    if (!this.siteWaterChart) {
      return;
    }

    this.siteWaterChart.dataProvider = SiteWaterConverter.convert(
      this._unitOfMeasureService,
      this.siteWaterChartDataProvider,
      unitSizes.normal,
    );

    if (this.showChartTimer) {
      this._interval.cancel(this.showChartTimer);
    }

    this.showChartTimer = this._interval(() => {
      this.siteWaterChart.validateData();
      this._interval.cancel(this.showChartTimer);
      this.showChartTimer = null;
    }, 200);
  }

  public onTabSelected_WaterChart() {
    if (!this.isWaterChartInitialised) {
      const waterChartTimer = this._interval(() => {
        if (this.availableProbes) {
          this.defineWaterChartOption();
          this.siteWaterChart = AmCharts.makeChart('site-water-chart', this.siteWaterChartOption) as AmCharts.AmSerialChart;
          if (this.siteWaterChart['chartRendered']) {
            this._interval.cancel(waterChartTimer);
            this.getSiteDailySummaries().then(() => {
              this.keepUserZoom = true;
              this.addGuideToday(this.siteWaterChart);
              this.showSiteWaterChart();
              this.isWaterChartInitialised = true;
              this.updateSoilMoisturePredictions(false);
            });
          }
        }
      }, 500);
    }
  }

  private showAlert() {
    const alert = this._languageService
      .alert()
      .htmlContent('COMMON.PLEASE_FIX_ERROR')
      .parent(angular.element(document.body))
      .ok('COMMON.CLOSE')
      .targetEvent();
    this._languageService.show(alert);
  }

  public isSoilSettingFormValid(): boolean {
    if (this._groupSettingService.siteSoil) {
      if (
        this._groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm == null ||
        this._groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm == null ||
        this._groupSettingService.siteSoil.DrainageCoefficient == null ||
        this._groupSettingService.siteSoil.WorkingSoilDepthBottom_mm == null ||
        !!this._groupSettingService.siteSoil['depthsError']
      ) {
        return false;
      }
    }
    return true;
  }

  public isCropSettingFormValid(): boolean {
    if (this._siteSettingService.siteCrop) {
      if (this._siteSettingService.siteCrop.PhaseCropCoefficient == null) {
        return false;
      }
    }
    return true;
  }

  public isWaterSettingFormValid(): boolean {
    if (this._siteSettingService.siteWater) {
      if (
        this._siteSettingService.siteWater.SprinklerLossConstantA == null ||
        this._siteSettingService.siteWater.IrrigationApplicationOneHour_mm == null
      ) {
        return false;
      }
      if (this._siteSettingService.siteWater.IrrigationDays == 'I') {
        if (
          this._siteSettingService.siteWater.WaterIntervalDays == null ||
          this._siteSettingService.siteWater.WaterIntervalFromDayNumber == null
        ) {
          return false;
        }
      }
      if (
        this._siteSettingService.siteWater.IrrigationDays === 'D' &&
        !(
          this._siteSettingService.siteWater.WaterMonday ||
          this._siteSettingService.siteWater.WaterTuesday ||
          this._siteSettingService.siteWater.WaterWednesday ||
          this._siteSettingService.siteWater.WaterThursday ||
          this._siteSettingService.siteWater.WaterFriday ||
          this._siteSettingService.siteWater.WaterSaturday ||
          this._siteSettingService.siteWater.WaterSunday
        )
      ) {
        return false;
      }
    }
    return true;
  }

  public isNutrientSettingFormValid(): boolean {
    if (this._siteSettingService.siteNoot) {
      if (this._siteSettingService.selectedSamplePoints?.length) {
        if (this._siteSettingService.SampleTotPerce != null && this._siteSettingService.SampleTotPerce != 100) {
          return false;
        }
      }
    }
    return true;
  }

  public datepickerOpen(source: string, dayNumber: number): void {
    this.datepickerCurrentDayNumber = dayNumber;
  }

  public datepickerClose(): void {
    this.datepickerCurrentDayNumber = null;
  }

  public canEditIcon(row) {
    let select = 0;
    switch (row.entityType.shortName) {
      case 'SiteSettingsCrop': {
        if (this.siteCropIndex != -1) {
          select = this.siteCrop[this.siteCropIndex].Id;
          break;
        } else {
          return;
        }
      }
      case 'SiteSettingsSoil': {
        if (this.siteSoilIndex != -1) {
          select = this.siteSoil[this.siteSoilIndex].Id;
          break;
        } else {
          return;
        }
      }
      case 'SiteSettingsWater': {
        if (this.siteWaterIndex != -1) {
          select = this.siteWater[this.siteWaterIndex].Id;
          break;
        } else {
          return;
        }
      }
      case 'SiteSettingsNutrients': {
        if (this.siteNootsIndex != -1) {
          select = this.siteNoots[this.siteNootsIndex].Id;
          break;
        } else {
          return;
        }
      }
    }

    const active = row.Id == select;

    let colour = 'swanLightGrey';
    let icon = 'icon-no';

    if (this.canEditDate(row.localDate)) {
      icon = 'icon-checkbox-marked-circle';

      if (active) {
        colour = 'swanGreen';
      }
    } else if (active) {
      colour = 'swanRed';
    }
    return `${icon} ${colour}`;
  }

  public getWeatherCSVHeader() {
    return [
      this._languageService.instant('COMMON.DATE'),
      this._languageService.instant('COMMON.WEATHER_READINGS.ETO') + ' (' + this._fluidDepthNormalUnit + ')',
      this._languageService.instant('COMMON.WEATHER_READINGS.RAINFALL') + ' (' + this._fluidDepthNormalUnit + ')',
      this._languageService.instant('COMMON.WEATHER_READINGS.TEMP_MAX') + ' (' + this._temperatureNormalUnit + ')',
      this._languageService.instant('COMMON.WEATHER_READINGS.TEMP_MIN') + ' (' + this._temperatureNormalUnit + ')',
      this._languageService.instant('COMMON.WEATHER_READINGS.TEMP_MEAN') + ' (' + this._temperatureNormalUnit + ')',
      this._languageService.instant('COMMON.WEATHER_READINGS.HUMIDITY_MAX') + ' (%)',
      this._languageService.instant('COMMON.WEATHER_READINGS.HUMIDITY_MIN') + ' (%)',
      this._languageService.instant('COMMON.WEATHER_READINGS.HUMIDITY_MEAN') + ' (%)',
      this._languageService.instant('COMMON.WEATHER_READINGS.WIND_SPEED') + ' (' + this._velocityNormalUnit + ')',
      this._languageService.instant('COMMON.WEATHER_READINGS.SOLAR_RADIATION') + ' (' + this._energyAreaDayNormalUnit + ')',
    ];
  }

  public getColumns() {
    return [
      'dayDisplayYMD',
      'EToShort',
      'Rainfall',
      'TemperatureMax',
      'TemperatureMin',
      'TemperatureMean',
      'HumidityMax',
      'HumidityMin',
      'HumidityMean',
      'WindSpeedMean',
      'SolarRadiation',
    ];
  }

  public weatherMsg() {
    const acknowledgements = this._localStorageService.get('acknowledgements');
    this.acknowledgementMsg = '';

    if (acknowledgements && acknowledgements?.messages?.length) {
      if (acknowledgements?.messages?.length > acknowledgements.count + 1) {
        acknowledgements.count = acknowledgements.count + 1;
        this.acknowledgementMsg = this._sce.trustAsHtml(acknowledgements.messages[acknowledgements.count]);
        this._localStorageService.set('acknowledgements', acknowledgements);
      } else {
        acknowledgements.count = 0;
        this.acknowledgementMsg = this._sce.trustAsHtml(acknowledgements.messages[0]);
        this._localStorageService.set('acknowledgements', acknowledgements);
      }
    }
  }

  public getWaterPlanAppliedTranslation() {
    return this._languageService.instant('AC.SITE.DEPTH_AMEND_PLAN', { units: this._fluidDepthNormalUnit });
  }

  public changePlannedFertigation(item: fuse.soilMoisturePrediction) {
    const pred = breeze.Predicate.create('dayNumber', '==', item.dayNumber);
    const query = breeze.EntityQuery.from('SiteDailySummary').where(pred);
    const dailySummaryEntities = this.entityManager.executeQueryLocally(query) as SiteDailySummary[]; // query the cache (synchronous)

    if (dailySummaryEntities.length) {
      const dailySummary = dailySummaryEntities[0];
      dailySummary.FertigationPlanned = item.fertigationPlanned;
    }
    if (item.irrigationPlanned < item.fertigationPlanned) {
      this._languageService.error('AC.SITE.MSG.FERT_EXCEEDS_IRRIG');
    }
    this.checkDailySummaryTable();
  }

  private checkDailySummaryTable() {
    this.siteWaterChartDataProvider.forEach((dailyValue) => {
      if (dailyValue.irrigationPlanned < dailyValue.fertigationPlanned) {
        dailyValue.isValid = false;
      } else {
        dailyValue.isValid = true;
      }
    });
  }

  public activeStatusClass(status: string) {
    if (status === 'Active') {
      return 'icon-checkbox-marked-circle green-500-fg';
    }
    if (status === 'Suspended') {
      return 'icon-minus-circle orange-500-fg';
    }
    if (status === 'Archived') {
      return 'icon-cancel red-500-fg';
    }
  }

  private getEquipmentIcon(equipment: fuse.siteEquipmentDto) {
    if (!equipment) {
      return null;
    }

    const suff = equipment.shared ? '_foreign' : '';
    const icon = `assets/icons/mapicons/${equipment.assetClassId}${suff}.svg`;

    return icon;
  }
}

angular.module('app.account').controller('SiteController', SiteController);
