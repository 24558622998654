// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#site-settings-kcvi md-datepicker .md-datepicker-input {
  width: 160px;
}
#site-settings-kcvi table thead tr th {
  background-color: #666;
  color: #fff;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}
#site-settings-kcvi table tbody tr td {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/site/settingsTabs/kcvi/kcvi-settings.scss"],"names":[],"mappings":"AAEQ;EACI,YAAA;AADZ;AAOgB;EACI,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,wBAAA,EAAA,eAAA;EACA,gBAAA;EACA,MAAA;EACA,WAAA;AALpB;AAWgB;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;AATpB","sourcesContent":["#site-settings-kcvi {\r\n    md-datepicker {\r\n        .md-datepicker-input {\r\n            width: 160px;\r\n        }\r\n    }\r\n    table {\r\n        thead {\r\n            tr {\r\n                th {\r\n                    background-color: #666;\r\n                    color: #fff;\r\n                    text-align: center;\r\n                    padding-top: 2px;\r\n                    padding-bottom: 2px;\r\n                    position: -webkit-sticky; /* for Safari */\r\n                    position: sticky;\r\n                    top: 0;\r\n                    z-index: 10;\r\n                }\r\n            }\r\n        }\r\n        tbody {\r\n            tr {\r\n                td {\r\n                    text-align: center;\r\n                    padding-top: 2px;\r\n                    padding-bottom: 2px;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
