import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { IFertiliserApplication } from '@common/models/ProviderPackets';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

class SWANNutrientsReportsFertiliserApplicationComponent implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    startDate: '<',
    endDate: '<',
    refreshReport: '<',
  };
  controller = NutrientsReportsFertiliserApplicationController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/nutrients/reports.fertiliser-application.component.html';
}

class NutrientsReportsFertiliserApplicationController extends BaseController {
  private _http: angular.IHttpService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  public assetId: number;
  public isExportBtnDisabled: boolean = true;

  public weightAreaUnit: uomUnit;
  public volAreaUnit: uomUnit;
  public weightUnit: uomUnit;
  public volUnit: uomUnit;

  constructor(
    $http: angular.IHttpService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.weightAreaUnit = UnitOfMeasureService.getUnits('Weight/Area', unitSizes.normal);
    this.volAreaUnit = UnitOfMeasureService.getUnits('FertVolume/Area', unitSizes.normal);
    this.weightUnit = UnitOfMeasureService.getUnits('Weight', unitSizes.normal);
    this.volUnit = UnitOfMeasureService.getUnits('FertVolume', unitSizes.normal);
  }

  private startDate: Date;
  private endDate: Date;

  public reportStartDate: Date;
  public reportEndDate: Date;
  private localeToday: Date;
  public reportData: IFertiliserApplication[] = [];
  public reportDataExport = []; // specific format just for export
  public reportCSVhdr = [];
  public csvFileName: string;
  public statusMsg: string;
  public isGroup: boolean = false;

  $onInit() {
    this.localeToday = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);

    this.reportCSVhdr = [
      this._languageService.instant('COMMON.SITE_GROUP'),
      this._languageService.instant('COMMON.FERTILISER'),
      this._languageService.instant('COMMON.MANUFACTURER'),
      this._languageService.instant('COMMON.START_DATE_OF_WEEK'),
      this._languageService.instant('COMMON.PLANNED_TOTAL'),
      this._languageService.instant('COMMON.APPLIED_TOTAL'),
      this._languageService.instant('NUTR.RPT.TOTAL_UNITS'),
      this._languageService.instant('NUTR.RPT.PLANNED_PER_AREA'),
      this._languageService.instant('NUTR.RPT.APPLIED_PER_AREA'),
      this._languageService.instant('NUTR.RPT.PER_AREA_UNITS'),
    ];
    this.loadPage();
  }

  public setThisWeek() {
    this.reportStartDate = this.localeToday.clone();
    if (this.reportStartDate.getDay() !== 0) {
      this.reportStartDate.moveToDayOfWeek(0, -1);
    }
    this.reportEndDate = this.reportStartDate.clone().addDays(6);
    this.loadPage(true);
  }

  public setNextWeek() {
    this.reportStartDate = this.localeToday.clone();
    this.reportStartDate.moveToDayOfWeek(0, 1);
    this.reportEndDate = this.reportStartDate.clone().addDays(6);
    this.loadPage(true);
  }

  private loadPage(override: boolean = false) {
    const context = LocalStorageUtils.contextData;

    this.isGroup = isFinite(context.groupId);

    this.reportData = [];
    this.reportDataExport = [];

    this.isExportBtnDisabled = true;
    this.statusMsg = this._languageService.instant('NUTR.RPT.SEARCHING_4_APPS');

    if (!override) {
      this.reportStartDate = this.startDate;
      this.reportEndDate = this.endDate;
    }

    const params = {
      AssetId: this.assetId,
      dfr: this.reportStartDate.toString('yyyy-MM-dd'),
      dto: this.reportEndDate.toString('yyyy-MM-dd'),
    };

    this._http.get(CommonHelper.getApiUrl('user/getFertiliserApplications'), { params }).then((response) => {
      if (response.data) {
        this.reportData = response.data as IFertiliserApplication[];

        if (this.reportData.length) {
          this.reportDataExport = [];
          this.csvFileName = this.reportData[0].siteName;
          let unit: uomUnit;
          let perAreaUnit: uomUnit;
          // populate specific data for report
          this.reportData.forEach((data: IFertiliserApplication) => {
            if (data.units == 'kg') {
              unit = this.weightUnit;
              perAreaUnit = this.weightAreaUnit;
            } else {
              unit = this.volUnit;
              perAreaUnit = this.volAreaUnit;
            }

            this.reportDataExport.push({
              siteGroupName: data.siteName,
              fertiliserName: data.fertiliserName,
              manufacturer: data.manufacturer,
              weekStarting: data.weekStarting,
              fertiliserPlanned: unit.fromBaseRounded(data.fertiliserPlanned),
              fertiliserActual: unit.fromBaseRounded(data.fertiliserActual),
              unit: unit.name,
              plannedPerArea: perAreaUnit.fromBaseRounded(data.plannedPerha),
              actualPerArea: perAreaUnit.fromBaseRounded(data.actualPerha),
              perAreaUnit: perAreaUnit.name,
            });
          });
        }

        this.isExportBtnDisabled = false;
      }
      this.statusMsg = this._languageService.instant('AC.SETTINGS.THERE_ARE_NO_FERT_APP');
    });
  }
}

angular.module('app.account').component('swanNutrientsReportsFertiliserApplication', new SWANNutrientsReportsFertiliserApplicationComponent());
