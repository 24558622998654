import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DataEntityService } from '@services/data-entity.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { ReportService, chkdState, tankMix } from '@services/account/report.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { SiteWeeklyFertiliser } from 'src/app/_DBContext/SiteWeeklyFertiliser';
import { BaseController } from 'src/app/base.controller';

class SWANSiteNutrientsReportsTankMixComponent implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    assetType: '<',
    startDate: '<',
    endDate: '<',
    refreshReport: '<',
  };
  controller = SiteNutrientsReportsTankMixController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/nutrients/reports.tankmix.component.html';
}

class SiteNutrientsReportsTankMixController extends BaseController {
  private _q: angular.IQService;
  private _dayNumberService: DayNumberService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _reportService: ReportService;

  private startDate: Date;
  private endDate: Date;
  private siteWeeklyFertilisersByGroup: SiteWeeklyFertiliser[] = [];
  private tankMixes: tankMix[] = [];
  private filteredTankMixes: tankMix[] = [];
  private siteTankMixes: tankMix[] = [];
  private checkedTankMixes: chkdState[] = [];
  public arrLogsDataCSVhdr: string[];
  public tankmixCsv: any[] = [];
  public myBtnEnabled = 0;
  public siteName: string;

  public assetId: number;
  public assetType: string;

  public weightUnit: uomUnit;
  public volUnit: uomUnit;

  constructor(
    $q: angular.IQService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    ReportService: ReportService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._q = $q;
    this._dayNumberService = DayNumberService;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._reportService = ReportService;

    this.entityManager = DataEntityService.manager;
    this.weightUnit = UnitOfMeasureService.getUnits('Weight');
    this.volUnit = UnitOfMeasureService.getUnits('FertVolume');
  }

  $onChanges(changes) {
    if (changes.refreshReport?.currentValue) {
      this.fetchData();
    }
  }

  private fetchData() {
    this.arrLogsDataCSVhdr = [
      this._languageService.instant('COMMON.SITE'),
      this._languageService.instant('NUTR.COMMON.TANK_MIX'),
      this._languageService.instant('NUTR.COMMON.FERTILISER_NAME'),
      `${this._languageService.instant('NUTR.TANK.TOTAL_BATCH_VOLUME')} (${this.volUnit.name}):`,
      this._languageService.instant('COMMON.UNITS'),
      `${this._languageService.instant('NUTR.TANK.UNITS_PER_K')} ${this.volUnit.name}`,
      this._languageService.instant('COMMON.UNITS_BATCH'),
    ];

    const assetEntity = this.entityManager.getEntityByKey('Asset', this.assetId) as Asset;

    if (assetEntity) {
      this.siteName = assetEntity.Name;
    }

    this.getData();
  }

  private getData() {
    this.tankMixes = [];
    this.siteTankMixes = [];
    this.siteWeeklyFertilisersByGroup = [];
    this.filteredTankMixes = [];
    this.checkedTankMixes = [];
    this.tankmixCsv = [];

    this._reportService.fetchAllTankMixes(this.accountId).then((tankMixes) => {
      const selectedTankMixIds = tankMixes.map((tm) => tm.AssetId);
      const startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.startDate);
      const endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.endDate);
      const tankMixAssetLinkPromises = tankMixes.map((tm) => this._fertiliserService.fetchTankMixAssetLinks(this.accountId, tm.AssetId));

      this._reportService.fetchSiteWeeklyFertiliserTankMix(this.assetId, this.assetType, selectedTankMixIds, startDayNumber, endDayNumber).then((siteWeeklyFerilisers) => {
        this.tankMixes = [];
        this.siteWeeklyFertilisersByGroup = siteWeeklyFerilisers

        // execute after getting all of the tankmix child links
        this._q.all(tankMixAssetLinkPromises).then(() => {
          this._reportService.generateTankMixReportArrays(
            this.accountId,
            this.siteWeeklyFertilisersByGroup,
            this.tankMixes,
            this.siteTankMixes,
            this.tankmixCsv,
            this.volUnit,
            this.weightUnit,
          );
        });
      });
    });
  }

  public concPer1000FertVol(asset, mixVol) {
    return this._fertiliserService.concPer1000FertVol(asset.Coefficient, mixVol, asset.unit, this.volUnit);
  }

  public displayDetail(tankMixId: number) {
    const tankMix = this.checkedTankMixes.find((tm) => tm.tankMixId === tankMixId);

    if (tankMix) {
      if (tankMix.checked) {
        //Previously checked means now it is unchecked so remove item from list
        const index = this.checkedTankMixes.indexOf(tankMix);
        this.checkedTankMixes.splice(index, 1);
      }

      const dataToRemove = this.filteredTankMixes.filter((tm) => tm.tankmixId === tankMixId);

      dataToRemove.forEach((data) => {
        const index = this.filteredTankMixes.indexOf(data);
        this.filteredTankMixes.splice(index, 1);
      });
    } else {
      const result = this.siteTankMixes.filter((tm) => tm.tankmixId === tankMixId);

      result.forEach((data) => {
        this.filteredTankMixes.push(data);
        data.fert.Asset.ChildAssets.forEach((child) => this._fertiliserService.setUnits(child, this.weightUnit, this.volUnit));
      });

      this.checkedTankMixes.push({
        checked: true,
        tankMixId: tankMixId,
      } as chkdState);
    }

    this.filteredTankMixes = ArrayUtils.sortByNumber(this.filteredTankMixes, (x) => x.siteId);

    const noots = ['NO3_N', 'NH4_N', 'TotalN', 'P', 'K', 'S', 'Ca', 'Mg', 'Na', 'Cl', 'Cu', 'Fe', 'Mn', 'Zn', 'B', 'Mo'];

    this.filteredTankMixes.map((tm) => {
      noots.forEach((noot) => {
        tm.Noot[noot] = this._reportService.calculateAnalysisKg(noot, tm.fert.Asset.AssetId.toString());
      });
    });
  }
}

angular.module('app.account').component('swanSiteNutrientsReportsTankMix', new SWANSiteNutrientsReportsTankMixComponent());
