import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { INutrientUsage } from '@common/models/ProviderPackets';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { BaseController } from 'src/app/base.controller';

class SWANNutrientsReportsAnalytesComponent implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    startDate: '<',
    endDate: '<',
    reportType: '<',
    refreshReport: '<',
  };
  controller = NutrientsReportsAnalytesController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/nutrients/reports.analytes.component.html';
}

interface SiteDetails {
  site: Asset;
}

interface MyClusteredChart {
  Name: string;
  PlannedValue: number;
  MaxValue: number;
  AppliedValue: number;
}

interface AnalyteRecord {
  siteName: string;
  analyteName: string;
  planned: number;
  actual: number;
  max: number;
  difference: number;
}

class NutrientsReportsAnalytesController extends BaseController {
  private _http: angular.IHttpService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;

  public assetId: number; // site or group
  public arrDataCSVhd: any;
  public arrData: AnalyteRecord[] = []; // all the analyte records for the site / group
  public myBtnEnabled = 0;

  public weightAreaUnit: uomUnit;

  constructor(
    $http: angular.IHttpService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.weightAreaUnit = UnitOfMeasureService.getUnits('Weight/Area');
  }

  private startDate: Date;
  private endDate: Date;

  private reportData: INutrientUsage;
  public showAnalytes: string[] = []; // names of every analyte hidden from the graph
  public reportType: string = 'Site';
  public siteApplications: SiteDetails[] = [];
  private swanLogo: string;

  $onInit() {
    this._http.get('assets/images/logos/SWAN-Landscape-Logo-Colour.png', { responseType: 'blob' }).then((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.swanLogo = reader.result as string;
      };
      reader.readAsDataURL(res.data as Blob);
    });
    this.reportData = null;
  }

  $onChanges(changes) {
    if (changes.refreshReport?.currentValue) {
      this.loadPage();
    }
  }

  $onDestroy() {
    console.log('NutrientsReportsAnalytes closed.');
  }

  private loadPage() {
    this.myBtnEnabled = 0;
    this.fetchNutrientSettings();
  }

  private fetchNutrientSettings() {
    this.arrData = [];

    if (this.assetId != null) {
      let siteName: string = '';

      if (this.assetId) {
        const assetEntity = this.entityManager.getEntityByKey('Asset', this.assetId);
        const siteAsset = assetEntity as Asset;

        siteName = siteAsset?.Name;
      }

      const data = {
        AssetId: this.assetId,
        dfr: this.startDate.toString('yyyy-MM-dd'),
        dto: this.endDate.toString('yyyy-MM-dd'),
      };

      this.arrDataCSVhd = [
        this.reportType,
        this._languageService.instant('COMMON.ANALYTE'),
        `${this._languageService.instant('COMMON.MAX')} (${this.weightAreaUnit.name}/${this._languageService.instant('COMMON.YEAR')})`,
        `${this._languageService.instant('COMMON.PLANNED')} (${this.weightAreaUnit.name})`,
        `${this._languageService.instant('COMMON.APPLIED')} (${this.weightAreaUnit.name})`,
        `${this._languageService.instant('COMMON.DIFFERENCE')} (${this.weightAreaUnit.name})`,
      ];

      this._http.get(CommonHelper.getApiUrl('user/GetNutrientReportNew'), { params: data }).then(
        (response) => {
          if (response.data) {
            this.reportData = response.data as INutrientUsage;
            this.reportData.AnalytesReport.forEach((an) => (an['selected'] = true));

            for (let j = 0; j < this.reportData.AnalytesReport.length; j++) {
              this.reportData.AnalytesReport[j].max = this.weightAreaUnit.fromBase(this.reportData.AnalytesReport[j].max);
              this.reportData.AnalytesReport[j].planned = this.weightAreaUnit.fromBase(this.reportData.AnalytesReport[j].planned);
              this.reportData.AnalytesReport[j].actual = this.weightAreaUnit.fromBase(this.reportData.AnalytesReport[j].actual);

              // arrData has one record for each data value
              this.arrData.push({
                siteName: siteName ?? this.assetId.toString(),
                analyteName: this.reportData.AnalytesReport[j].name,
                max: this.reportData.AnalytesReport[j].max,
                planned: this.reportData.AnalytesReport[j].planned,
                actual: this.reportData.AnalytesReport[j].actual,
                difference: this.reportData.AnalytesReport[j].planned - this.reportData.AnalytesReport[j].actual,
              });

              this.myBtnEnabled = 1;
            }

            this.arrData = ArrayUtils.sortByString(this.arrData, (x) => x.analyteName);

            // listAnalytes has one record (containing name) for each analyte present
            this.showAnalytes = this.arrData.map((d) => d.analyteName);

            this.updateChart();
          }
        },
        (error) => {
          console.log(error);
        },
      );
    }
  }

  private updateChart() {
    let myData;

    let myPoint: MyClusteredChart;
    const myPoints: MyClusteredChart[] = [];
    const i = 0;
    let p: number;
    let t: number;

    let prevanalyte: string = '';
    let thisanalyte: string;
    let plannedSum: number = 0;
    let maxSum: number = 0;
    let appliedSum: number = 0;

    /*   (not required, causes doubling up in group, see following comment)
        //#IG 189 fix : - Grouping analyte together, this is more applicable in Group report than site report
        for (let i = 0; i < this.arrData.length; i++) {
        */

    const report = this.reportData.AnalytesReport;

    for (let j = 0; j < report.length; j++) {
      const reading = report[j];

      thisanalyte = reading.name;

      // ignore any hidden analytes
      if (this.showAnalytes.find((a) => a == thisanalyte)) {
        if (prevanalyte !== '' && prevanalyte !== thisanalyte) {
          myPoints.push({
            Name: prevanalyte,
            PlannedValue: plannedSum,
            AppliedValue: appliedSum,
            MaxValue: maxSum,
          } as MyClusteredChart);

          plannedSum = 0;
          maxSum = 0;
          appliedSum = 0;
          plannedSum += Number(reading.planned);
          maxSum += Number(reading.max);
          appliedSum += Number(reading.actual);
          prevanalyte = thisanalyte;
        } else {
          plannedSum += Number(reading.planned);
          maxSum += Number(reading.max);
          appliedSum += Number(reading.actual);
          prevanalyte = thisanalyte;
        }
      }
      if (j === report.length - 1) {
        myPoint = {} as MyClusteredChart;
        myPoint.Name = prevanalyte;
        myPoint.PlannedValue = plannedSum;
        myPoint.MaxValue = maxSum;
        myPoint.AppliedValue = appliedSum;
        myPoints.push(myPoint);
      }
    }

    const chart = AmCharts.makeChart('analyteGraph', {
      type: 'serial',
      theme: 'light',
      categoryField: 'Name',
      rotate: true,
      startDuration: 0,
      columnSpacing: 0,
      columnWidth: 0.8,
      categoryAxis: {
        gridPosition: 'start',
        position: 'left',
      },
      trendLines: [],
      graphs: [
        {
          fillAlphas: 0.8,
          id: 'AmGraph-1',
          lineAlpha: 0.2,
          lineColor: '#00b050',
          title: `${this._languageService.instant('COMMON.PLANNED')} ${this.weightAreaUnit.name}`,
          type: 'column',
          fillColors: '#00b050',
          valueField: 'PlannedValue',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.PLANNED') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(graphDataItem.category) === null
                    ? 3
                    : this._fertiliserService.nootDP(graphDataItem.category) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
        {
          fillAlphas: 0.8,
          id: 'AmGraph-2',
          lineAlpha: 0.2,
          lineColor: '#000000',
          title: `${this._languageService.instant('COMMON.MAX')} ${this.weightAreaUnit.name}/${this._languageService.instant(
            'COMMON.YEAR',
          )}`,
          type: 'column',
          fillColors: '#000000',
          valueField: 'MaxValue',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.MAX') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(graphDataItem.category) === null
                    ? 3
                    : this._fertiliserService.nootDP(graphDataItem.category) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
        {
          fillAlphas: 0.8,
          id: 'AmGraph-3',
          lineAlpha: 0.2,
          lineColor: '#FF9800',
          title: `${this._languageService.instant('COMMON.APPLIED')} ${this.weightAreaUnit.name}`,
          type: 'column',
          fillColors: '#FF9800',
          valueField: 'AppliedValue',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.APPLIED') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(graphDataItem.category) === null
                    ? 3
                    : this._fertiliserService.nootDP(graphDataItem.category) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
      ],
      guides: [],
      valueAxes: [
        {
          id: 'ValueAxis-1',
          position: 'top',
          axisAlpha: 0,
        },
      ],
      allLabels: [],
      balloon: {},
      titles: [],
      dataProvider: myPoints,
      legend: {
        useGraphSettings: true,
      },
    });
  }

  public ExportPDF(name, printdiv, theChartDiv) {
    //var xepOnline = (window as any).xepOnline || {};

    //var filename = name;

    //if (this.assetId != null) {
    //    filename = this.assetId + '-' + name;
    //}

    const charts = {};
    let charts_remaining = 1; //ids.length;

    for (let i = 0; i < 1; i++) {
      for (let x = 0; x < (AmCharts as any).charts.length; x++) {
        if ((AmCharts as any).charts[x].div) {
          if ((AmCharts as any).charts[x].div.id == theChartDiv) charts[theChartDiv] = (AmCharts as any).charts[x];
        }
      }
    }

    for (const y in charts) {
      if (charts.hasOwnProperty(y)) {
        var chart = charts[y];
        chart['export'].capture({}, () => {
          chart.toJPG({}, (data) => {
            // Save chart data into chart object itself
            chart.setup.chart.exportedImage = data;

            // Reduce the remaining counter
            charts_remaining--;

            // Check if we got all of the charts
            if (charts_remaining == 0) {
              // Yup, we got all of them
              // Let's proceed to putting PDF together

              let sitename: string = '';
              if (this.arrData.length) {
                const assetEntity = this.entityManager.getEntityByKey('Asset', this.assetId);
                const siteAsset = assetEntity as Asset;
                sitename = siteAsset.Name;
                if (siteAsset.AssetClass.Name === 'Site Group (CMU)') {
                  this.reportType = 'Group';
                }
              }

              // Initiliaze a PDF layout
              const layout = {
                // a string or { width: number, height: number }
                pageSize: 'A4',
                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',
                content: [],
              };

              layout.content.push({
                image: this.swanLogo,
                width: 120,
              });

              layout.content.push({
                text: ' ',
                fontSize: 20,
              });

              layout.content.push({
                text:
                  this.reportType +
                  ' (' +
                  this.reportData.title +
                  `) ${this._languageService.instant('NUTR.RPT.ANALYTE_FROM')} ` +
                  DateUtils.Locale.asDateDefault(this.startDate) +
                  this._languageService.instant('COMMON.TO') +
                  DateUtils.Locale.asDateDefault(this.endDate),
                fontSize: 10,
              });

              layout.content.push({
                text: ' ',
                fontSize: 12,
              });

              layout.content.push({
                image: charts[theChartDiv].exportedImage,
                fit: [723, 400],
                pageBreak: 'after',
              });

              const body = [];
              const widths = [];
              let outputFilename = '';

              outputFilename = 'AnalyteReport_';
              widths.push('auto', 'auto', 'auto', 'auto', 'auto', 'auto');

              // change to water usage table structure
              const hdr = [];
              hdr.push(this.reportType);
              hdr.push(this._languageService.instant('COMMON.ANALYTE'));
              hdr.push(
                `${this._languageService.instant('COMMON.MAX')} (${this.weightAreaUnit.name}/${this._languageService.instant(
                  'COMMON.YEAR',
                )})`,
              );
              hdr.push(`${this._languageService.instant('COMMON.PLANNED')} (${this.weightAreaUnit.name})`);
              hdr.push(`${this._languageService.instant('COMMON.APPLIED')} (${this.weightAreaUnit.name})`);
              hdr.push(`${this._languageService.instant('COMMON.DIFFERENCE')} (${this.weightAreaUnit.name})`);

              body.push(hdr);

              if (sitename !== '') {
                outputFilename = outputFilename + sitename + '.pdf';
              } else {
                outputFilename = outputFilename + '.pdf';
              }

              const grpName = this.reportData.title;

              const outputData: AnalyteRecord[] = ArrayUtils.sortByCustomComparer(
                this.arrData.map((x) => ({
                  ...x,
                  difference: x.planned - x.actual,
                })),
                (x, y) => {
                  let result: number;

                  // First by site name.
                  result = x.siteName.localeCompare(y.siteName);

                  // Prioritise the group name.
                  if (result != 0 && grpName !== '') {
                    // they're different and we do have a group
                    if (x.siteName == grpName) {
                      result = -1;
                    }

                    if (y.siteName == grpName) {
                      result = 1;
                    }
                  }

                  // Then by analyte name.
                  if (result === 0) {
                    result = x.analyteName.localeCompare(y.analyteName);
                  }

                  return result;
                },
              );

              for (let i = 0; i < outputData.length; i++) {
                const fila = []; // looks like a row
                const diff = outputData[i].actual - this.arrData[i].planned;

                fila.push(outputData[i].siteName);
                fila.push(outputData[i].analyteName);
                fila.push(outputData[i].max.toString());
                fila.push(outputData[i].planned.toString());
                fila.push(outputData[i].actual.toString());
                fila.push(diff.toString());

                body.push(fila);
              }

              if (sitename !== '') {
                layout.content.push({
                  text: this.reportType + ' : ' + sitename,
                  fontSize: 12,
                });

                layout.content.push({
                  text: ' ',
                  fontSize: 12,
                });
              }

              layout.content.push({
                text: `${this._languageService.instant('NUTR.RPT.ALL_UNITS_IN')} ` + this.weightAreaUnit.name,
                fontSize: 12,
              });

              layout.content.push({
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 1,
                  widths: widths,
                  body: body,
                },
              });

              // Trigger the generation and download of the PDF

              chart['export'].toPDF(layout, (data) => {
                chart.download(data, 'application/pdf', outputFilename);
              });
            }
          });
        });
      }
    }

    //return xepOnline.Formatter.Format(printdiv, { render: 'download', filename: filename, pageWidth: '210mm', pageHeight: '297mm' });
  }
}

angular.module('app.account').component('swanNutrientsReportsAnalytes', new SWANNutrientsReportsAnalytesComponent());
