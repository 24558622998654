// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nominate-health-assets-dialog {
  width: 800px;
}
#nominate-health-assets-dialog md-checkbox {
  margin-bottom: 0;
}
#nominate-health-assets-dialog md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#nominate-health-assets-dialog md-input-container .md-errors-spacer {
  min-height: 0px !important;
}
#nominate-health-assets-dialog md-input-container .md-input {
  color: white;
  border-color: white;
}
#nominate-health-assets-dialog table thead tr {
  height: 52px;
}
#nominate-health-assets-dialog table thead tr th {
  background-color: #666;
  color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}
#nominate-health-assets-dialog table tbody tr td {
  padding-top: 2px;
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/site/settingsTabs/kcvi/nominateHealthAssets-dialog.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,gBAAA;AAER;AAAI;EACI,eAAA;EACA,UAAA;AAER;AADQ;EAEI,0BAAA;AAEZ;AAAQ;EACI,YAAA;EACA,mBAAA;AAEZ;AACI;EACI,YAAA;AACR;AAAQ;EACI,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,wBAAA,EAAA,eAAA;EACA,gBAAA;EACA,MAAA;EACA,WAAA;AAEZ;AACI;EACI,gBAAA;EACA,mBAAA;AACR","sourcesContent":["#nominate-health-assets-dialog {\r\n    width: 800px;\r\n    md-checkbox {\r\n        margin-bottom: 0;\r\n    }\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }\r\n        .md-input {\r\n            color: white;\r\n            border-color: white;\r\n        }\r\n    }\r\n    table thead tr {\r\n        height: 52px;\r\n        th {\r\n            background-color: #666;\r\n            color: #fff;\r\n            padding-top: 2px;\r\n            padding-bottom: 2px;\r\n            position: -webkit-sticky; /* for Safari */\r\n            position: sticky;\r\n            top: 0;\r\n            z-index: 10;\r\n        }\r\n    }\r\n    table tbody tr td {\r\n        padding-top: 2px;\r\n        padding-bottom: 2px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
