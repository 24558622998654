import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { INutrientActualsFinal } from '@common/models/interfaces';
import { DataEntityService } from '@services/data-entity.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { BaseController } from 'src/app/base.controller';

class SWANSiteNutrientReportNutrientActuals implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    assetType: '<',
    startDate: '<',
    endDate: '<',
    refreshReport: '<',
  };
  controller = SiteNutrientReportNutrientActualsController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/nutrients/reports.nutrientactuals.component.html';
}

class SiteNutrientReportNutrientActualsController extends BaseController {
  private _http: angular.IHttpService;
  private _languageService: LanguageService;

  private NutrientActualFert = {};
  public NutrientActualFinal: INutrientActualsFinal[] = [];
  public filename: string;
  public _fertiliserService: FertiliserService;

  public startDate: Date;
  public endDate: Date;
  public drpValues: string[] = [
    'NO3_N',
    'OtherN',
    'TotalN',
    'P',
    'K',
    'S',
    'Ca',
    'Mg',
    'Na',
    'Cl',
    'Cu',
    'Fe',
    'Mn',
    'Zn',
    'B',
    'Mo',
  ];
  public selectedNutrient: string = 'TotalN';
  private swanLogo: string;
  public assetId: number;
  public assetType: number;

  public weightUnit: uomUnit;

  constructor(
    $http: angular.IHttpService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.weightUnit = UnitOfMeasureService.getUnits('Weight/Area');
  }

  $onInit() {
    this._http.get('assets/images/logos/SWAN-Landscape-Logo-Colour.png', { responseType: 'blob' }).then((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.swanLogo = reader.result as string;
      };
      reader.readAsDataURL(res.data as Blob);
    });
  }

  $onChanges(changes) {
    if (changes.refreshReport?.currentValue) {
      this.fetchData();
    }

    const assetEntity = this.entityManager.getEntityByKey('Asset', this.assetId);
    const siteAsset = assetEntity as Asset;
    this.filename = siteAsset.Name;
  }

  // user has selected a different nutrient
  public onNutrientChange() {
    if (this.selectedNutrient) {
      // we can use existing data instead of refreshing
      this.buildReport();
    } else {
      this.fetchData();
    }
  }

  private fetchData() {
    this.NutrientActualFinal = [];
    const data = {
      AssetId: this.assetId,
      dfr: this.startDate.toString('yyyy-MM-dd'),
      dto: this.endDate.toString('yyyy-MM-dd'),
    };

    this._http.get(CommonHelper.getApiUrl('user/getActualNutrientReport'), { params: data }).then(
      (response) => {
        if (response.data) {
          this.NutrientActualFert = response.data;
          this.convertUnits();
          this.buildReport();
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  private convertUnits() {
    angular.forEach(this.NutrientActualFert, (par) => {
      const item = par['NutrientField'];
      const ks = Object.keys(item);
      Object.keys(item).forEach((cat) => {
        Object.keys(item[cat]).forEach((noot) => {
          item[cat][noot] = this.weightUnit.fromBase(item[cat][noot]);
        });
      });
    });
  }

  private buildReport() {
    this.NutrientActualFinal = [];
    const selectedNutrient = this.selectedNutrient;
    angular.forEach(this.NutrientActualFert, (item, idx: number) => {
      const final = {} as INutrientActualsFinal;
      final.actualcumulative = 0;
      final.budgtedCumultive = 0;
      if (item['NutrientField']) {
        if (item['NutrientField']['TotalActual']) {
          final.actual = Number(this.getElementValue(selectedNutrient, item['NutrientField']['TotalActual']));
          final.actualcumulative = idx === 0 ? final.actual : this.NutrientActualFinal[idx - 1].actualcumulative + final.actual;
        }

        if (item['NutrientField']['TotalBudget']) {
          final.budgted = Number(this.getElementValue(selectedNutrient, item['NutrientField']['TotalBudget']));
          final.budgtedCumultive = idx === 0 ? final.budgted : this.NutrientActualFinal[idx - 1].budgtedCumultive + final.budgted;
        }
      }
      final.dateStr = item['DisplayDateFrom'];
      final.date = item['DateFrom'];
      final.nutName = selectedNutrient;
      this.NutrientActualFinal.push(final);
    });
    this.updateChart();
  }

  private getElementValue(name: string, nutrients: any): number {
    switch (name) {
      case 'NO3_N':
        return nutrients['NO3_N'];
      case 'OtherN':
        return nutrients['NH4_N'];
      case 'TotalN':
        return nutrients['NO3_N'] + nutrients['NH4_N'];
      default:
        return nutrients[name];
    }
  }

  private updateChart() {
    const myPoints: {}[] = [];

    // variables are not used elsewhere - somewhat of a hack to make John happy
    for (let i = 0; i < this.NutrientActualFinal.length; i++) {
      const dataPoint: INutrientActualsFinal = this.NutrientActualFinal[i];
      const myPoint: {} = new Object();
      myPoint[this._languageService.instant('COMMON.DATE')] = dataPoint.dateStr;
      myPoint[this._languageService.instant('COMMON.ACTUAL')] = dataPoint.actual;
      myPoint[this._languageService.instant('COMMON.BUDGETED')] = dataPoint.budgted;
      myPoint[this._languageService.instant('COMMON.ACTUAL_CUMULATIVE')] = dataPoint.actualcumulative;
      myPoint[this._languageService.instant('COMMON.BUDGETED_CUMULATIVE')] = dataPoint.budgtedCumultive;
      myPoints.push(myPoint);
    }

    const chart = AmCharts.makeChart('chartdivNutrientActual', {
      type: 'serial',
      theme: 'light',
      categoryField: this._languageService.instant('COMMON.DATE'),
      rotate: false,
      startDuration: 0,
      categoryAxis: {
        title: this._languageService.instant('COMMON.WEEK_COMMENCING'),
        parseDates: false,
        dashLength: 1,
        autoGridCount: true,
        minHorizontalGap: 50,
        labelRotation: 75,
      },
      trendLines: [],
      graphs: [
        {
          fillAlphas: 0.8,
          id: 'AmGraph-1',
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.BUDGETED'),
          type: 'column',
          fillColors: '#00b050',
          valueField: this._languageService.instant('COMMON.BUDGETED'),
          valueAxis: 'ValueAxis-1',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.BUDGETED') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(this.selectedNutrient) === null
                    ? 3
                    : this._fertiliserService.nootDP(this.selectedNutrient) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
        {
          fillAlphas: 0.8,
          id: 'AmGraph-2',
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.ACTUAL'),
          type: 'column',
          fillColors: '#e46c0a',
          valueField: this._languageService.instant('COMMON.ACTUAL'),
          valueAxis: 'ValueAxis-1',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.ACTUAL') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(this.selectedNutrient) === null
                    ? 3
                    : this._fertiliserService.nootDP(this.selectedNutrient) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
        {
          fillAlphas: 0,
          id: 'AmGraph-3',
          lineAlpha: 1,
          lineThickness: 2,
          bulletSize: 7,
          bullet: 'round',
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          useLineColorForBulletBorder: true,
          title: this._languageService.instant('COMMON.BUDGETED_CUMULATIVE'),
          fillColors: '#00b050',
          valueField: this._languageService.instant('COMMON.BUDGETED_CUMULATIVE'),
          valueAxis: 'ValueAxis-2',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.BUDGETED_CUMULATIVE') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(this.selectedNutrient) === null
                    ? 3
                    : this._fertiliserService.nootDP(this.selectedNutrient) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
        {
          fillAlphas: 0,
          id: 'AmGraph-4',
          lineAlpha: 1,
          lineThickness: 2,
          bulletSize: 7,
          bullet: 'round',
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          useLineColorForBulletBorder: true,
          title: this._languageService.instant('COMMON.ACTUAL_CUMULATIVE'),
          fillColors: '#e46c0a',
          valueField: this._languageService.instant('COMMON.ACTUAL_CUMULATIVE'),
          valueAxis: 'ValueAxis-2',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.ACTUAL_CUMULATIVE') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(this.selectedNutrient) === null
                    ? 3
                    : this._fertiliserService.nootDP(this.selectedNutrient) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
      ],
      guides: [],
      valueAxes: [
        {
          id: 'ValueAxis-1',
          position: 'left',
          axisAlpha: 0,
        },
        {
          id: 'ValueAxis-2',
          dashLength: 1,
          position: 'right',
          title: this._languageService.instant('COMMON.CUMULATIVE'),
        },
      ],
      allLabels: [],
      balloon: {},
      titles: [],
      dataProvider: myPoints,
      legend: {
        useGraphSettings: true,
      },
    });
  }
}

angular.module('app.account').component('swanSiteNutrientReportNutrientActuals', new SWANSiteNutrientReportNutrientActuals());
