// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#SiteAreaDialog {
  max-width: 400px;
  min-width: 300px;
  margin-left: 10px;
  margin-right: 10px;
}
#SiteAreaDialog md-input-container .md-errors-spacer {
  display: none;
}
#SiteAreaDialog md-toolbar {
  height: 64px;
  min-height: 64px;
}
#SiteAreaDialog md-toolbar md-toolbar .title {
  font-size: 17px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/site/settingsTabs/sitearea-dialog.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAAI;EACI,aAAA;AAER;AAAI;EACI,YAAA;EACA,gBAAA;AAER;AADQ;EACI,eAAA;AAGZ","sourcesContent":["#SiteAreaDialog {\r\n    max-width: 400px;\r\n    min-width: 300px;\r\n    margin-left: 10px;\r\n    margin-right: 10px;\r\n    md-input-container .md-errors-spacer {\r\n        display: none;\r\n    }\r\n    md-toolbar {\r\n        height: 64px;\r\n        min-height: 64px;\r\n        md-toolbar .title {\r\n            font-size: 17px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
