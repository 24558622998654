import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { DayNumberService } from '@services/day-number.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

class SWANSiteNutrientsReportsComponent implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    assetType: '@',
    assetName: '<',
  };
  controller = SiteNutrientsReportsController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/nutrients/reports.component.html';
}

class SiteNutrientsReportsController extends BaseController {
  private _dayNumberService: DayNumberService;

  public startDate: Date;
  public endDate: Date;
  public minDate = SWANConstants.MinDate;
  public assetId: number;
  public assetName: string;
  public assetType: string;
  public isReportShown: boolean = false;

  public reportTypes = ['Budget vs Actuals', 'Tank Mix', 'Fertiliser Applications', 'Analytes', 'Nutrient Actuals'];
  public selectedReportType = '';

  constructor(
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._dayNumberService = DayNumberService;
  }

  public showReport() {
    this.isReportShown = true;
  }

  $onInit() {
    const localeToday = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);
    const localMonthDay1 = new Date(localeToday.getFullYear(), localeToday.getMonth(), 1);
    this.startDate = localMonthDay1.clone();
    if (this.startDate.getDay() != 0) {
      this.startDate.moveToDayOfWeek(0, -1);
    }
    this.endDate = localMonthDay1.clone().addMonths(1);
    if (this.endDate.getDay() != 6) {
      this.endDate.moveToDayOfWeek(6, 1);
    }
  }

  public isSunday(date: Date) {
    return date.getDay() === 0;
  }

  public isSaturday(date: Date) {
    return date.getDay() === 6;
  }
}

angular.module('app.account').component('swanSiteNutrientsReports', new SWANSiteNutrientsReportsComponent());
