import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { IIdNameItem } from '@common/models/interfaces';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { AboutKcviDialogController } from './aboutKcvi-dialog.controller';
import { AddAlgorithmDialogController } from './addAlgorithm-dialog.controller';
import { AddKcviUseDialogController } from './addKcviUse-dialog.controller';
import { NominateHealthAssetsDialogController } from './nominateHealthAssets-dialog.controller';
import { BaseController } from 'src/app/base.controller';

class KcviSettingsComponent implements angular.IComponentOptions {
  bindings = {
    accountInfo: '<',
    siteId: '<',
    reloadCount: '<',
    onChange: '&',
  };
  controller = KcviSettingsController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/settingsTabs/kcvi/kcvi-settings.html';
}

class KcviSettingsController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;

  private siteId: number;
  public accountInfo: fuse.siteAccountInfoDto;
  private onChange: Function;
  public healthAssets = [] as fuse.siteHealthAssetProfileDto[];
  public siteAlgorithms = [] as fuse.siteAlgorithmDto[];
  public siteKcviUses = [] as fuse.siteKcviUseDto[];
  public kcviDataProviderId: number;
  public kcviEffectiveDate: Date;
  public isEffectiveDataShown = false;
  public minimumEffectiveDate: Date;
  public maximumEffectiveDate: Date;
  public valueIndexes = [] as IIdNameItem[];
  public isKcviLoaded = false;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;

    this.valueIndexes = SWANConstants.AlgorithmValueFunctions;

    const adjustedLocaleDate = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    this.minimumEffectiveDate = adjustedLocaleDate.clone().addDays(-180);
    this.maximumEffectiveDate = adjustedLocaleDate.clone();
  }

  $onChanges(changes) {
    if (changes.reloadCount?.currentValue > 0) {
      this.isKcviLoaded = false;
      this.getSiteKcviOptions(this.siteId);
    }
  }

  private getSiteKcviOptions(siteId: number) {
    const params = { siteId: siteId };
    this._http.get(CommonHelper.getApiUrl('site/getSiteKcviOptions'), { params: params }).then(
      (result) => {
        const siteKcviOptions = result.data as fuse.siteKcviOptionsDto;
        this.kcviDataProviderId = siteKcviOptions.dataProviderId;
        this.healthAssets = siteKcviOptions.healthAssets;
        this.siteAlgorithms = siteKcviOptions.algorithms;
        this.siteAlgorithms.forEach((algorithm) => {
          algorithm.localeDate = this._dayNumberService.convertDayNumberToLocaleDate(algorithm.dayNumber);
        });
        this.siteKcviUses = siteKcviOptions.kcviUses;
        this.siteKcviUses.forEach((kcviUse) => {
          kcviUse.fromLocaleDate = this._dayNumberService.convertDayNumberToLocaleDate(kcviUse.fromDayNumber);
          kcviUse.endLocaleDate = this._dayNumberService.convertDayNumberToLocaleDate(kcviUse.endDayNumber);
        });
        this.isEffectiveDataShown = false;
        this.isKcviLoaded = true;
      },
      (error) => {
        console.error('Error: Could not get site KCVI settings (' + error.message + ')');
      },
    );
  }

  public openNominateHealthAssetsDialog() {
    this._mdDialog
      .show({
        controller: NominateHealthAssetsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/settingsTabs/kcvi/nominateHealthAssets-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          unselectedHealthAssets: this.healthAssets.filter((a) => a.isSelected != true),
          selectedHealthAssets: this.healthAssets.filter((a) => a.isSelected),
        },
      })
      .then((result) => {
        if (result) {
          const newSelectedHealthAssets = result.selectedHealthAssets as fuse.siteHealthAssetProfileDto[];
          this.healthAssets.forEach((healthAsset) => {
            if (newSelectedHealthAssets.some((a) => a.assetId == healthAsset.assetId)) {
              healthAsset.isSelected = true;
            } else {
              healthAsset.isSelected = false;
            }
          });
          this.kcviSettingsChanged();
          this.isEffectiveDataShown = true;
        }
      });
  }

  public removeHealthAssetFromSite(item: fuse.siteHealthAssetProfileDto) {
    item.isSelected = false;
    this.kcviSettingsChanged();
    if (item.status == 'Active') {
      this.isEffectiveDataShown = true;
    }
  }

  public kcviImageryProviderChanged() {
    this.kcviSettingsChanged();
    this.isEffectiveDataShown = true;
  }

  public openAddAlgorithmDialog() {
    this._mdDialog
      .show({
        controller: AddAlgorithmDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/settingsTabs/kcvi/addAlgorithm-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          algorithms: this.accountInfo.algorithms,
          siteAlgorithms: this.siteAlgorithms,
        },
      })
      .then((result) => {
        if (result) {
          const newAlgorithm = result.newAlgorithm as fuse.siteAlgorithmDto;
          this.siteAlgorithms.push(newAlgorithm);
          this.isEffectiveDataShown = true;
          this.kcviSettingsChanged();
        }
      });
  }

  public removeSiteAlgorithm(item: fuse.siteAlgorithmDto) {
    this.siteAlgorithms = this.siteAlgorithms.filter((a) => a != item);
    this.isEffectiveDataShown = true;
    this.kcviSettingsChanged();
  }

  public openAddKcviUseDialog() {
    this._mdDialog
      .show({
        controller: AddKcviUseDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/settingsTabs/kcvi/addKcviUse-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          siteKcviUses: this.siteKcviUses,
        },
      })
      .then((result) => {
        if (result) {
          const newKcviUse = result.newKcviUse as fuse.siteKcviUseDto;
          this.siteKcviUses.push(newKcviUse);
          this.isEffectiveDataShown = true;
          this.kcviSettingsChanged();
        }
      });
  }

  public removeSiteKcviUse(item: fuse.siteKcviUseDto) {
    this.siteKcviUses = this.siteKcviUses.filter((a) => a != item);
    this.isEffectiveDataShown = true;
    this.kcviSettingsChanged();
  }

  public kcviSettingsChanged() {
    const kcviChanges = {
      siteId: this.siteId,
      dataProviderId: this.kcviDataProviderId,
      effectiveFromYMD: this.kcviEffectiveDate == undefined ? null : this.kcviEffectiveDate.toString('yyyy-MM-dd'),
      effectiveFromDayNumber:
        this.kcviEffectiveDate == undefined
          ? null
          : this._dayNumberService.convertCalendarDateToLocaleDayNumber(this.kcviEffectiveDate),
      healthAssets: this.healthAssets,
      siteAlgorithms: this.siteAlgorithms,
      siteKcviUses: this.siteKcviUses,
    } as fuse.siteKcviChangesDto;
    this.onChange({ kcviChanges: kcviChanges });
  }

  public openAboutKcviDialog() {
    this._mdDialog.show({
      controller: AboutKcviDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/account/views/site/settingsTabs/kcvi/aboutKcvi-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
    });
  }
}

angular.module('app.account').component('kcviSettings', new KcviSettingsComponent());
