import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { unitSizes, UnitTypes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';
import { AddWeatherStationDialogController } from './addWeatherStation-dialog.controller';
import { CopyWeatherDialogController } from './copy-weather-dialog.controller';

class SiteSettingsWeatherComponent implements angular.IComponentOptions {
  bindings = {
    siteId: '<',
    reloadCount: '<',
    hasChanges: '<',
    onChange: '&',
  };
  controller = SiteSettingsWeatherController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/settingsTabs/weather/site-settings-weather.html';
}

interface IobsWeatherDatePriority {
  date: Date;
  priority: number;
}

class SiteSettingsWeatherController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  private siteId: number;
  public hasChanges: boolean;
  private onChange: Function;
  public weatherDataInputs = [] as fuse.dashboardDataInputDto[];
  public weatherStations = [] as fuse.weatherStationDto[];
  public weatherSettingWarningMessages: string[];
  private adjustedTodayDayNumber: number;
  public rainfallNormalUnit: string;
  public etoNormalUnit: string;
  public temperatureNormalUnit: string;
  public windspeedNormalUnit: string;
  public solarRadiationNormalUnit: string;
  public lengthLargeUnit: uomUnit;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._http = $http;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.adjustedTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.rainfallNormalUnit = UnitOfMeasureService.getUnitLabel(UnitTypes.FluidDepth, unitSizes.normal);
    this.etoNormalUnit = UnitOfMeasureService.getUnitLabel(UnitTypes.FluidDepth, unitSizes.normal);
    this.temperatureNormalUnit = UnitOfMeasureService.getUnitLabel(UnitTypes.Temperature, unitSizes.normal);
    this.windspeedNormalUnit = UnitOfMeasureService.getUnitLabel(UnitTypes.Velocity, unitSizes.normal);
    this.solarRadiationNormalUnit = UnitOfMeasureService.getUnitLabel(UnitTypes.SolarRadiation, unitSizes.normal);
    this.lengthLargeUnit = UnitOfMeasureService.getUnits(UnitTypes.Length, unitSizes.large);
  }

  $onInit() {
    this.getWeatherDataInputs();
  }

  $onChanges(changes) {
    if (changes.reloadCount?.currentValue > 0) {
      this.getSiteWeatherStations(this.siteId);
    }
  }

  private getSiteWeatherStations(siteId: number) {
    const params = { siteId: siteId };
    this._http.get(CommonHelper.getApiUrl('site/siteWeatherStations'), { params: params }).then((response) => {
      if (response.data == null) {
        this._languageService.whoops();
      } else {
        this.weatherStations = response.data as fuse.weatherStationDto[];
        this.weatherStations.forEach((weatherStation) => {
          if (weatherStation.latestObsWeatherDayNumber != null) {
            weatherStation.latestObsWeatherDate = this._dayNumberService.convertDayNumberToLocaleDate(
              weatherStation.latestObsWeatherDayNumber,
            );
            if (this.adjustedTodayDayNumber - weatherStation.latestObsWeatherDayNumber < 4) {
              weatherStation.isValidObsWeather = true;
            }
          }
          if (weatherStation.latestObsEtoDayNumber != null) {
            weatherStation.latestObsEtoDate = this._dayNumberService.convertDayNumberToLocaleDate(
              weatherStation.latestObsEtoDayNumber,
            );
          } else {
            // weatherStation.obsETOPriority = null;
          }
          if (weatherStation.latestObsHumidityDayNumber != null) {
            weatherStation.latestObsHumidityDate = this._dayNumberService.convertDayNumberToLocaleDate(
              weatherStation.latestObsHumidityDayNumber,
            );
          } else {
            // weatherStation.obsHumidityPriority = null;
          }
          if (weatherStation.latestObsRainfallDayNumber != null) {
            weatherStation.latestObsRainfallDate = this._dayNumberService.convertDayNumberToLocaleDate(
              weatherStation.latestObsRainfallDayNumber,
            );
          } else {
            // weatherStation.obsRainfallPriority = null;
          }
          if (weatherStation.latestObsSolarRadiationDayNumber != null) {
            weatherStation.latestObsSolarRadiationDate = this._dayNumberService.convertDayNumberToLocaleDate(
              weatherStation.latestObsSolarRadiationDayNumber,
            );
          } else {
            // weatherStation.obsSolarRadiationPriority = null;
          }
          if (weatherStation.latestObsTemperatureDayNumber != null) {
            weatherStation.latestObsTemperatureDate = this._dayNumberService.convertDayNumberToLocaleDate(
              weatherStation.latestObsTemperatureDayNumber,
            );
          } else {
            // weatherStation.obsTemperaturePriority = null;
          }
          if (weatherStation.latestObsWindspeedDayNumber != null) {
            weatherStation.latestObsWindspeedDate = this._dayNumberService.convertDayNumberToLocaleDate(
              weatherStation.latestObsWindspeedDayNumber,
            );
          } else {
            // weatherStation.obsWindspeedPriority = null;
          }
          weatherStation.obsWeatherPriority = this.getObsWeatherPriority(weatherStation);
        });
        this.checkWeatherStationPriority();
      }
    });
  }

  private getObsWeatherPriority(weatherStation: fuse.weatherStationDto): number {
    const priorities = [
      { date: weatherStation.latestObsEtoDate, priority: weatherStation.obsETOPriority },
      { date: weatherStation.latestObsHumidityDate, priority: weatherStation.obsHumidityPriority },
      { date: weatherStation.latestObsRainfallDate, priority: weatherStation.obsRainfallPriority },
      { date: weatherStation.latestObsSolarRadiationDate, priority: weatherStation.obsSolarRadiationPriority },
      { date: weatherStation.latestObsTemperatureDate, priority: weatherStation.obsTemperaturePriority },
      { date: weatherStation.latestObsWindspeedDate, priority: weatherStation.obsWindspeedPriority },
    ] as IobsWeatherDatePriority[];
    const validPriorities = priorities.filter((a) => a.date != null);
    const distinctPriorities = validPriorities.map((a) => a.priority).filter((v, i, a) => a.indexOf(v) == i);

    if (distinctPriorities.length == 1) {
      return distinctPriorities[0];
    }

    if (!distinctPriorities.length) {
      // the station may is just added
      const distinctPriorities_1 = priorities.map((a) => a.priority).filter((v, i, a) => a.indexOf(v) == i);

      if (distinctPriorities_1.length == 1) {
        return distinctPriorities_1[0];
      }
    }

    return null;
  }

  private getWeatherDataInputs(): void {
    // get information about the available weather stations
    this._http.get(CommonHelper.getApiUrl('site/weatherDataInputs')).then((response) => {
      if (response.data == null) {
        this._languageService.whoops();
      } else {
        this.weatherDataInputs = response.data as fuse.dashboardDataInputDto[];
      }
    });
  }

  public getWeatherStationPriority(ws: fuse.weatherStationDto, wdi: fuse.weatherDataInputDto): number {
    if (wdi.name == 'Weather') {
      if (ws.latestObsWeatherDate != null) return ws.obsWeatherPriority;
      else return null;
    } else if (wdi.name == 'ETO') {
      if (ws.latestObsEtoDate != null) return ws.obsETOPriority;
      else return null;
    } else if (wdi.name == 'Humidity') {
      if (ws.latestObsHumidityDate != null) return ws.obsHumidityPriority;
      else return null;
    } else if (wdi.name == 'Rainfall') {
      if (ws.latestObsRainfallDate != null) return ws.obsRainfallPriority;
      else return null;
    } else if (wdi.name == 'SolarRadiation') {
      if (ws.latestObsSolarRadiationDate != null) return ws.obsSolarRadiationPriority;
      else return null;
    } else if (wdi.name == 'Temperature') {
      if (ws.latestObsTemperatureDate != null) return ws.obsTemperaturePriority;
      else return null;
    } else if (wdi.name == 'Windspeed') {
      if (ws.latestObsWindspeedDate != null) return ws.obsWindspeedPriority;
      else return null;
    }
  }

  public hasWeatherData(ws: fuse.weatherStationDto, wdi: fuse.weatherDataInputDto): boolean {
    if (ws.latestObsWeatherDate == null) {
      //the weather station may is just added
      return true;
    }
    if (wdi.name == 'Weather') {
      if (ws.latestObsWeatherDate != null) return true;
    } else if (wdi.name == 'ETO') {
      if (ws.latestObsEtoDate != null) return true;
    } else if (wdi.name == 'Humidity') {
      if (ws.latestObsHumidityDate != null) return true;
    } else if (wdi.name == 'Rainfall') {
      if (ws.latestObsRainfallDate != null) return true;
    } else if (wdi.name == 'SolarRadiation') {
      if (ws.latestObsSolarRadiationDate != null) return true;
    } else if (wdi.name == 'Temperature') {
      if (ws.latestObsTemperatureDate != null) return true;
    } else if (wdi.name == 'Windspeed') {
      if (ws.latestObsWindspeedDate != null) return true;
    }
    return false;
  }

  public changeWeatherStationPriority(ws: fuse.weatherStationDto, wdi: fuse.weatherDataInputDto) {
    if (ws['obs' + wdi.name + 'Priority'] == 0) ws['obs' + wdi.name + 'Priority'] = null;
    if (wdi.name == 'Weather') {
      if (ws.latestObsWeatherDate == null || ws.latestObsEtoDate != null) ws.obsETOPriority = ws.obsWeatherPriority;
      if (ws.latestObsWeatherDate == null || ws.latestObsHumidityDate != null) ws.obsHumidityPriority = ws.obsWeatherPriority;
      if (ws.latestObsWeatherDate == null || ws.latestObsRainfallDate != null) ws.obsRainfallPriority = ws.obsWeatherPriority;
      if (ws.latestObsWeatherDate == null || ws.latestObsSolarRadiationDate != null)
        ws.obsSolarRadiationPriority = ws.obsWeatherPriority;
      if (ws.latestObsWeatherDate == null || ws.latestObsTemperatureDate != null)
        ws.obsTemperaturePriority = ws.obsWeatherPriority;
      if (ws.latestObsWeatherDate == null || ws.latestObsWindspeedDate != null) ws.obsWindspeedPriority = ws.obsWeatherPriority;
    } else {
      ws.obsWeatherPriority = this.getObsWeatherPriority(ws);
    }
    this.checkWeatherStationPriority();
    this.weatherStationsChanged();
  }

  private checkWeatherStationPriority() {
    if (this.weatherDataInputs != null && this.weatherStations != null) {
      this.weatherStations.forEach((ws) => {
        this.weatherDataInputs.forEach((wdi) => {
          if (wdi.name != 'Weather') {
            const priority = ws['obs' + wdi.name + 'Priority'];
            if (priority != null) {
              if (this.weatherStations.filter((a) => a['obs' + wdi.name + 'Priority'] == priority).length > 1) {
                ws['is' + wdi.name + 'PriorityConflict'] = true;
              } else {
                ws['is' + wdi.name + 'PriorityConflict'] = false;
              }
            } else {
              ws['is' + wdi.name + 'PriorityConflict'] = false;
            }
          }
        });
      });
    }
  }

  public removeWeatherStation(weatherStationAssetId: number) {
    this.weatherStations.splice(
      this.weatherStations.findIndex((a) => a.assetId == weatherStationAssetId),
      1,
    );
    this.checkWeatherStationPriority();
    this.weatherStationsChanged();
  }

  public openAddWeatherStationDialog() {
    this._mdDialog
      .show({
        controller: AddWeatherStationDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/settingsTabs/weather/addWeatherStation-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          siteId: this.siteId,
          existWeatherStationIds: this.weatherStations.map((a) => a.assetId),
        },
      })
      .then((result) => {
        if (result) {
          // Note: do not override the existing values in weatherstations.
          this.weatherStations = ArrayUtils.sortByNumber(this.weatherStations.concat(result.weatherStations as fuse.weatherStationDto[]), (x) => x.distance);

          this.weatherStationsChanged();
        }
      });
  }

  public isWeatherSettingsValid(): boolean {
    let isWeatherSettingValid = true;

    this.weatherSettingWarningMessages = [] as string[];

    if (!this.weatherStations.length) {
      return;
    }

    this.weatherStations.forEach((station) => {
      if (
        station.obsETOPriority == null &&
        station.obsHumidityPriority == null &&
        station.obsRainfallPriority == null &&
        station.obsSolarRadiationPriority == null &&
        station.obsTemperaturePriority == null &&
        station.obsWindspeedPriority == null
      ) {
        this.weatherSettingWarningMessages.push(
          this._languageService.instant('AC.SITE.STATION_HAS_NO_PREFS', { name: station.name }),
        );
        isWeatherSettingValid = false;
      }
    });

    const forecastStations = this.weatherStations.filter((a) => a.type == 'Forecast');
    const forecastEtoPriority = Math.min(
      ...forecastStations.filter((a) => a.obsETOPriority != null).map((b) => b.obsETOPriority),
    );
    const forecastHumidityPriority = Math.min(
      ...forecastStations.filter((a) => a.obsHumidityPriority != null).map((b) => b.obsHumidityPriority),
    );
    const forecastRainfallPriority = Math.min(
      ...forecastStations.filter((a) => a.obsRainfallPriority != null).map((b) => b.obsRainfallPriority),
    );
    const forecastSolarRadiationPriority = Math.min(
      ...forecastStations.filter((a) => a.obsSolarRadiationPriority != null).map((b) => b.obsSolarRadiationPriority),
    );
    const forecastTemperaturePriority = Math.min(
      ...forecastStations.filter((a) => a.obsTemperaturePriority != null).map((b) => b.obsTemperaturePriority),
    );
    const forecastWindspeedPriority = Math.min(
      ...forecastStations.filter((a) => a.obsWindspeedPriority != null).map((b) => b.obsWindspeedPriority),
    );
    const missedForecastPreferences = [] as string[];
    if (forecastEtoPriority == Infinity && forecastSolarRadiationPriority == Infinity) {
      missedForecastPreferences.push('BOTH_ETO_SR');
    }
    if (forecastHumidityPriority == Infinity) {
      missedForecastPreferences.push('HUMIDITY');
    }
    if (forecastRainfallPriority == Infinity) {
      missedForecastPreferences.push('RAINFALL');
    }
    if (forecastTemperaturePriority == Infinity) {
      missedForecastPreferences.push('TEMPERATURE');
    }
    if (forecastWindspeedPriority == Infinity) {
      missedForecastPreferences.push('WINDSPEED');
    }
    if (missedForecastPreferences.length) {
      isWeatherSettingValid = false;
      const missed = missedForecastPreferences
        .map((a) => this._languageService.instant('COMMON.WEATHER_READINGS.' + a))
        .join(', ');
      const message = this._languageService.instant('AC.SITE.NO_PREF_FOR_FORECAST', { missed: missed });
      this.weatherSettingWarningMessages.push(message);
    }
    const actualStations = this.weatherStations.filter((a) => a.type == 'Actual');
    const actualEtoPriority = Math.max(...actualStations.filter((a) => a.obsETOPriority != null).map((b) => b.obsETOPriority));
    const actualHumidityPriority = Math.max(
      ...actualStations.filter((a) => a.obsHumidityPriority != null).map((b) => b.obsHumidityPriority),
    );
    const actualRainfallPriority = Math.max(
      ...actualStations.filter((a) => a.obsRainfallPriority != null).map((b) => b.obsRainfallPriority),
    );
    const actualSolarRadiationPriority = Math.max(
      ...actualStations.filter((a) => a.obsSolarRadiationPriority != null).map((b) => b.obsSolarRadiationPriority),
    );
    const actualTemperaturePriority = Math.max(
      ...actualStations.filter((a) => a.obsTemperaturePriority != null).map((b) => b.obsTemperaturePriority),
    );
    const actualWindspeedPriority = Math.max(
      ...actualStations.filter((a) => a.obsWindspeedPriority != null).map((b) => b.obsWindspeedPriority),
    );
    const missedActualPreferences = [] as string[];
    if (actualEtoPriority == -Infinity && actualSolarRadiationPriority == -Infinity) {
      missedActualPreferences.push('BOTH_ETO_SR');
    }
    if (actualHumidityPriority == -Infinity) {
      missedActualPreferences.push('HUMIDITY');
    }
    if (actualRainfallPriority == -Infinity) {
      missedActualPreferences.push('RAINFALL');
    }
    if (actualTemperaturePriority == -Infinity) {
      missedActualPreferences.push('TEMPERATURE');
    }
    if (actualWindspeedPriority == -Infinity) {
      missedActualPreferences.push('WINDSPEED');
    }
    if (missedActualPreferences.length) {
      isWeatherSettingValid = false;
      const missed = missedActualPreferences.map((a) => this._languageService.instant('COMMON.WEATHER_READINGS.' + a)).join(', ');
      const message = this._languageService.instant('AC.SITE.NO_PREF_FOR_ACTUAL', { missed: missed });
      this.weatherSettingWarningMessages.push(message);
    }
    if (forecastEtoPriority != Infinity && actualEtoPriority != -Infinity && forecastEtoPriority < actualEtoPriority) {
      isWeatherSettingValid = false;
      const message = this._languageService.instant('AC.SITE.FORECAST_PREF_ETO');
      this.weatherSettingWarningMessages.push(message);
    }
    if (
      forecastHumidityPriority != Infinity &&
      actualHumidityPriority != -Infinity &&
      forecastHumidityPriority < actualHumidityPriority
    ) {
      isWeatherSettingValid = false;
      const message = this._languageService.instant('AC.SITE.FORECAST_PREF_HUMIDITY');
      this.weatherSettingWarningMessages.push(message);
    }
    if (
      forecastRainfallPriority != Infinity &&
      actualRainfallPriority != -Infinity &&
      forecastRainfallPriority < actualRainfallPriority
    ) {
      isWeatherSettingValid = false;
      const message = this._languageService.instant('AC.SITE.FORECAST_PREF_RAINFALL');
      this.weatherSettingWarningMessages.push(message);
    }
    if (
      forecastSolarRadiationPriority != Infinity &&
      actualSolarRadiationPriority != -Infinity &&
      forecastSolarRadiationPriority < actualSolarRadiationPriority
    ) {
      isWeatherSettingValid = false;
      const message = this._languageService.instant('AC.SITE.FORECAST_PREF_SOLARRADIATION');
      this.weatherSettingWarningMessages.push(message);
    }
    if (
      forecastTemperaturePriority != Infinity &&
      actualTemperaturePriority != -Infinity &&
      forecastTemperaturePriority < actualTemperaturePriority
    ) {
      isWeatherSettingValid = false;
      const message = this._languageService.instant('AC.SITE.FORECAST_PREF_TEMPERATURE');
      this.weatherSettingWarningMessages.push(message);
    }
    if (
      forecastWindspeedPriority != Infinity &&
      actualWindspeedPriority != -Infinity &&
      forecastWindspeedPriority < actualWindspeedPriority
    ) {
      isWeatherSettingValid = false;
      const message = this._languageService.instant('AC.SITE.FORECAST_PREF_WINDSPEED');
      this.weatherSettingWarningMessages.push(message);
    }

    return isWeatherSettingValid;
  }

  public openCopyWeatherSettingsDialog() {
    this._mdDialog
      .show({
        controller: CopyWeatherDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/site/settingsTabs/weather/copy-weather-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          siteId: this.siteId,
        },
      })
      .then((result) => {
        if (result) {
          const settings = result as fuse.replaceWeatherPreferenceDto;
          settings.recalculateDate = new Date(
            Date.UTC(
              settings.recalculateDate.getFullYear(),
              settings.recalculateDate.getMonth(),
              settings.recalculateDate.getDate(),
            ),
          );
          this._http.post(CommonHelper.getApiUrl(`accounts/${this.accountId}/replaceWeatherPreferences`), settings).then(
            () => {
              this._languageService.success('AC.SITE.MSG.WEATHER_PREF_SET', 'AC.SITE.MSG.WEATHER_PREF_COPY');
            },
            (e) => {
              this._languageService.error(e.data, 'AC.SITE.MSG.WEATHER_PREF_COPY_FAILED');
            },
          );
        }
      });
  }

  private weatherStationsChanged() {
    this.onChange({ weatherStations: this.weatherStations });
  }
}

angular.module('app.account').component('siteSettingsWeather', new SiteSettingsWeatherComponent());
