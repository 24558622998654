import * as angular from 'angular';
import * as moment from 'moment';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { SiteSettingsCrop } from 'src/app/_DBContext/SiteSettingsCrop';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';

interface cropSettingChartData {
  date: Date;
  cropCoefficient: number;
  cropName: string;
}

export class CropGraphDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _timeout: angular.ITimeoutService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  private _cropSettings: SiteSettingsCrop[];
  private _cropCoefficientChart: AmCharts.AmSerialChart;
  private _chartData: cropSettingChartData[];
  private _today: Date;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $timeout: angular.ITimeoutService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    cropSettings: SiteSettingsCrop[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._timeout = $timeout;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this._cropSettings = cropSettings;
  }

  $onInit() {
    this._today = new Date();
    this._today.setHours(0, 0, 0, 0);

    this.buildChartData();
    this._timeout(() => {
      this.displayChart();
    }, 500);
  }

  private buildChartData() {
    this._chartData = [];
    this._cropSettings.forEach((cs, i, a) => {
      let nextSetting = null as SiteSettingsCrop;
      if (a.length > i + 1) {
        nextSetting = a[i + 1];
      }
      const startKc = cs.PhaseCropCoefficient;
      const endKC =
        cs.SlopeOption == null || cs.SlopeOption == 'F' || nextSetting == null ? startKc : nextSetting.PhaseCropCoefficient;
      const startDate = moment(cs.localDate).toDate();
      const endDate = nextSetting == null ? moment(cs.localDate).toDate().addDays(365) : moment(nextSetting.localDate).toDate();
      const duration = moment(endDate).diff(moment(startDate), 'days', true);

      for (let d = 0; d < duration; d++) {
        const dayKc = startKc + d * ((endKC - startKc) / duration);
        this._chartData.push({ date: moment(startDate).toDate().addDays(d), cropCoefficient: dayKc, cropName: cs.Crop.Name });
      }
    });
  }

  private displayChart() {
    const chartOptions = {
      theme: 'light',
      type: 'serial',
      dataProvider: this._chartData,
      zoomOutText: this._languageService.instant('COMMON.SHOW_ALL'),
      balloon: {
        animationDuration: 0,
        fadeOutDuration: 0,
      },
      categoryField: 'date',
      categoryAxis: {
        parseDates: true,
        autoGridCount: true,
        labelRotation: 90,
        markPeriodChange: true,
        equalSpacing: false,
        minorGridEnable: false,
        minPeriod: 'DD',
        groupToPeriods: 'WW',
        startOnAxis: true,
      },
      chartScrollbar: {
        resizeEnabled: true,
      },
      graphs: [
        {
          //"balloonText": "Planned [[category]] to date: <b>val kg/ha</b>",
          type: 'line',
          id: 'cropcoefficient',
          bullet: 'round',
          bulletAlpha: 0,
          fillAlphas: 0.4,
          lineAlpha: 1,
          lineColor: '#27aae0',
          lineThickness: 3,
          title: this._languageService.instant('COMMON.CROP_COEFFICIENT'),
          valueField: 'cropCoefficient',
          connect: true,
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.dataContext as cropSettingChartData;
            if (val) {
              return `${this._languageService.instant(
                'COMMON.DATE',
              )}: ${DateUtils.Locale.asDateDefault(val.date)}<br/><br/>${this._languageService.instant('COMMON.CROP')}: ${
                val.cropName
              }<br/><b>${this._languageService.instant('COMMON.KC')}: ${val.cropCoefficient.toFixed(2)}</b>`;
            } else {
              return '';
            }
          },
        },
      ],
      valueAxes: [
        {
          id: 'yAxis',
          axisAlpha: 0,
          minimum: 0,
        },
      ],
    };
    this._cropCoefficientChart = AmCharts.makeChart('crop-coefficient-chart', chartOptions) as AmCharts.AmSerialChart;
    this.addGuideToday(this._cropCoefficientChart);
    this._cropCoefficientChart.zoomToDates(new Date().addDays(-183), new Date().addDays(183));
    this._cropCoefficientChart.validateNow();
  }

  private addGuideToday(chart: AmCharts.AmSerialChart) {
    if (!chart) {
      return;
    }

    const guideLine: AmCharts.Guide = new AmCharts.Guide();
    guideLine.date = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);
    guideLine.above = true;
    guideLine.lineColor = '#444444';
    guideLine.lineAlpha = 0.4;
    guideLine.inside = true;
    guideLine.label = DateUtils.Locale.asDateMedium(guideLine.date);
    guideLine.labelRotation = 90;
    guideLine.position = 'top';
    guideLine.dashLength = 0;

    if (chart.categoryAxis.addGuide !== undefined) {
      // remove any existing guides
      chart.categoryAxis.addGuide(guideLine);
    }
    chart.validateNow();
  }

  public closeDialog() {
    this._mdDialog.hide({ dataRefreshRequired: false });
  }
}

angular.module('app.account').controller('CropGraphDialogController', CropGraphDialogController);
