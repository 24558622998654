import * as angular from 'angular';
import * as moment from 'moment';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { GroupSettingService } from '@services/account/group-setting.service';
import { SiteSettingService } from '@services/account/site-setting.service';
import { DayNumberService } from '@services/day-number.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { Crop } from 'src/app/_DBContext/Crop';
import { CropCoefficient } from 'src/app/_DBContext/CropCoefficient';
import { BaseController } from 'src/app/base.controller';
import { SiteSettingsSoil } from 'src/app/_DBContext/SiteSettingsSoil';
import { ISiteCropCoeff, IWaterDays, SelectedSamplePointRow } from '../../AccountConstants';

class SiteSettingsController extends BaseController {
  public longDayEnglishNames = DateUtils.Locale.getWeekdayNamesLong(undefined, 'en');
  public longDayNames: string[] = DateUtils.Locale.getWeekdayNamesLong();


  public groupSettingService: GroupSettingService; // IMPORTANT: Must be public - referenced inside html (e.g., `sc.groupSettingService.*`).

  public siteId: number;
  public rangeViews: any;
  public rangeView: number = 0;

  public soilReadonly: boolean;
  public cropReadonly: boolean;
  public waterReadonly: boolean;
  public nootReadonly: boolean;

  // slider config - soil
  public sliderSoilMoistureTarget: any;
  public sliderDrainage = {};
  public sliderWorkingDepth = {};

  // Water
  public sliderWaterBudgetTarget: any;
  public sliderSystemEfficiencyCoefficient: any;
  public sliderEmitterLosses: any;
  public sliderWaterWettedArea: any;
  public sliderWaterWettedAreaDefault: number = 100;
  public wateringWeekdaysText: string;

  // Crop
  public sliderWSC;
  public sliderDSC;
  public sliderLAT;
  public sliderHAT;

  public maxPenRainValue_min: number;
  public minPenRainValue_max: number;

  // UI button / rollup controls
  public ShowingSoilMoistureInfo: boolean = false;
  public ShowingCropTypeHistory: boolean = false;
  public ShowingGrowthPhase: boolean = false;
  public ShowingCropCoeff: boolean = false;
  public ShowingCropCoeffHistory: boolean = false;
  public ShowingSprinklerInfo: boolean = false;

  // Water
  public imuWaterDays: IWaterDays[] = [];
  public imuWaterIntervalStart: Date;
  public imuWaterIntervalMaxDate: Date;

  // Crop
  public siteCropCoeffsCurrent: ISiteCropCoeff[] = []; // transposed siteCropCoeffs[0]

  public crops = [] as Crop[];
  private cropCoeffs: CropCoefficient[] = []; // cropCoefficient
  public healthAssets = [] as fuse.siteHealthAssetProfileDto[];
  public siteAlgorithms = [] as fuse.siteAlgorithmDto[];

  //Nutrients
  public samplePoints = [] as Asset[];
  public selectedSamplePoints: SelectedSamplePointRow[] = [];

  private adjustedTodayDayNumber: number;
  private adjustedToday: Date;
  public associatedHealthAssetIds: number[];
  public searchTerm: string;

  // public workingSoilDepth_mm: number;

  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;
  private _siteSettingService: SiteSettingService;

  private _shortDayNames: string[] = DateUtils.Locale.getWeekdayNamesShort();

  constructor(
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    GroupSettingService: GroupSettingService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    SiteSettingService: SiteSettingService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.SiteFull);

    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;
    this._siteSettingService = SiteSettingService;

    this.entityManager = DataEntityService.manager;
    this.groupSettingService = GroupSettingService;

    this.setDefaultWaterDays();
  }

  public get soilSettings(): SiteSettingsSoil {
    return this.groupSettingService.siteSoil;
  }

  public set soilSettings(value: SiteSettingsSoil) {
    this.groupSettingService.siteSoil = value;
  }

  private setDefaultWaterDays(): void {
    this.imuWaterDays = this.longDayNames.map((day) => ({ day: day, isSelected: false }));

    const waterDaysTranslation = this._languageService.instant('AC.SETTINGS.WATER_WEEK_DAYS');
    const wateringDaysRange = `(${this._shortDayNames[0]} - ${this._shortDayNames[6]})`;

    this.wateringWeekdaysText = `${waterDaysTranslation} ${wateringDaysRange}`;
  }

  $onInit() {
    const context = LocalStorageUtils.contextData;

    this.siteId = context.siteId;
    this.adjustedTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.adjustedToday = this._dayNumberService.convertDayNumberToDate(this.adjustedTodayDayNumber);
    this.groupSettingService.siteSoil = null;

    this._notifyingService.subscribe(NotifyEvents.Site.Settings.Soil.Base, this.scope, (event, data) => {
      this.groupSettingService.siteSoil = data.setting;
      this.soilReadonly = data.readonly;
      this.refreshSiteSoilSetting();
    });

    this._notifyingService.subscribe(NotifyEvents.Site.Settings.Crop.Base, this.scope, (event, data) => {
      this._siteSettingService.siteCrop = data.setting;
      this.cropReadonly = data.readonly;
      this.initSiteCropStressSliders();
      this.initialiseCropCoefficients();
    });

    this._notifyingService.subscribe(NotifyEvents.Site.Settings.Water.Base, this.scope, (event, data) => {
      this._siteSettingService.siteWater = data.setting;
      this.waterReadonly = data.readonly;
      this.refreshSiteWaterSetting();
    });

    this._notifyingService.subscribe(NotifyEvents.Site.Settings.Nutirients.Base, this.scope, (event, data) => {
      try {
        this._siteSettingService.siteNoot = data.setting;
        this.nootReadonly = data.readonly;
        this.bindSiteNootSetting(this._siteSettingService.siteNoot.AssetId, this._siteSettingService.siteNoot.SamplePointList);
      } catch (err) {
        // will be an error on load a site - just ignore as triggered now from within settings.
      }
    });

    const unbindWatcherWater = this.scope.$watch('vm._siteSettingService.siteWater', (setting) => {
      if (setting != null) {
        this.refreshSiteWaterSetting();
        // Once the site Water Setting load is complete,
        // there's no more need to watch the change
        // in the model value.
        unbindWatcherWater();
      }
    });

    const unbindWatcherSoil = this.scope.$watch('vm.groupSettingService.siteSoil', (setting) => {
      if (setting != null) {
        this.refreshSiteSoilSetting();
        unbindWatcherSoil();
      }
    });
  }

  private refreshSiteSoilSetting() {
    this.initSiteSoilMoistureSliders();
    this.initSiteDrainageCoefficientSlider();
    this.initSiteWorkingDepthSlider();
    this.initPenetratingRainFallRange();
    this.checkSoilDepth();
  }

  private refreshSiteWaterSetting() {
    this.initSiteWaterSliders();
    this.initWaterDays();
    this.initWaterInterval();
    this.initSystemEfficiencyCoefficientSlider();
  }

  private bindSiteNootSetting(AssetId: number, siteNootList: string) {
    this._siteSettingService.selectedSamplePoints = [];
    this._siteSettingService.SampleTotPerce = 0;
    this.selectedSamplePoints.length = 0;
    if (siteNootList && siteNootList != '') {
      const arrList: any[] = JSON.parse(siteNootList);
      arrList.forEach((data) => {
        this._siteSettingService.selectedSamplePoints.push(data.SamplePoint.AssetId);
        this._siteSettingService.SampleTotPerce += data.Percentage;
        const newSelectedSPRow: SelectedSamplePointRow = {} as SelectedSamplePointRow;
        newSelectedSPRow.Percentage = data.Percentage;
        newSelectedSPRow.SamplePoint = data.SamplePoint as Asset;
        this.selectedSamplePoints.push(newSelectedSPRow);
      });
    }
  }

  public checkSoilDepth() {
    this.groupSettingService.updateWorkingDepths();
  }

  public createSamplePoints() {
    const samplepoints: any[] = [];
    this._siteSettingService.SampleTotPerce = 0;
    this.selectedSamplePoints.forEach((s) => {
      this._siteSettingService.SampleTotPerce += s.Percentage;
      samplepoints.push(s);
    });
    this._siteSettingService.siteNoot.SamplePointList = angular.toJson(samplepoints);
  }

  public AddSamplePointToSite() {
    this._siteSettingService.selectedSamplePoints.forEach((selected: number, value) => {
      const exists = this.selectedSamplePoints.filter((s) => s.SamplePoint.AssetId === selected);
      const asset = this.samplePoints.filter((s) => s.AssetId === selected);
      if (!exists?.length) {
        if (asset?.length) {
          const newSelectedSPRow: SelectedSamplePointRow = {} as SelectedSamplePointRow;
          newSelectedSPRow.Percentage = 0;
          const newAsset: Asset = {} as Asset;
          newAsset.AssetId = selected;
          newAsset.Name = asset[0].Name;
          newSelectedSPRow.SamplePoint = newAsset;
          this.selectedSamplePoints.push(newSelectedSPRow);
        }
      }
    });
    const samplepoints: any[] = [];
    this.selectedSamplePoints = this.selectedSamplePoints.filter(
      (ss) => !!this._siteSettingService.selectedSamplePoints.filter((id) => ss.SamplePoint.AssetId === id).length,
    );
    //this will add the selected sample point to the JSON
    this.selectedSamplePoints.forEach((obj: SelectedSamplePointRow, value) => {
      samplepoints.push({
        Percentage: obj.Percentage,
        SamplePoint: obj.SamplePoint,
      } as SelectedSamplePointRow);
    });

    const strSamplePoints = JSON.stringify(samplepoints);
    const siteNootSamplePoints = JSON.parse(this._siteSettingService.siteNoot.SamplePointList);
    const strSiteNootSamplePoints = angular.toJson(siteNootSamplePoints);
    if (strSiteNootSamplePoints !== strSamplePoints) {
      this._siteSettingService.siteNoot.SamplePointList = strSamplePoints;
    }
  }

  private initSiteSoilMoistureSliders() {
    this.groupSettingService.initRangeArray();
    this.groupSettingService.initSoilMoistureSlider(this.soilReadonly || !this.apf.hasSiteSettingsSoilFull);
  }

  private initSiteWaterSliders() {
    if (!this._siteSettingService.siteWater.WaterBudgetLowerTarget_percent)
      this._siteSettingService.siteWater.WaterBudgetLowerTarget_percent = 70;

    if (!this._siteSettingService.siteWater.WaterBudgetUpperTarget_percent)
      this._siteSettingService.siteWater.WaterBudgetUpperTarget_percent = 100;

    this.sliderWaterBudgetTarget = {
      maxValue: this._siteSettingService.siteWater.WaterBudgetUpperTarget_percent,
      minValue: this._siteSettingService.siteWater.WaterBudgetLowerTarget_percent,
      options: {
        ceil: 200,
        disabled: this.waterReadonly || !this.apf.hasSiteSettingsWaterFull,
        floor: 0,
        hideLimitLabels: true,
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'model':
              this._siteSettingService.siteWater.WaterBudgetLowerTarget_percent = value;
              return `<b>${this._languageService.instant('COMMON.UNDER_BUDGET')}:</b> ${value}%`;
            case 'high':
              this._siteSettingService.siteWater.WaterBudgetUpperTarget_percent = value;
              return `<b>${this._languageService.instant('COMMON.OVER_BUDGET')}:</b> ${value}%`;
            default:
              return value + '%';
          }
        },
      },
    };

    if (!this._siteSettingService.siteWater.CropWettedArea_perc) {
      this._siteSettingService.siteWater.CropWettedArea_perc = 100;
    }

    this.sliderWaterWettedArea = {
      options: {
        ceil: 100,
        disabled: this.waterReadonly || !this.apf.hasSiteSettingsWaterWettedAreaFull,
        floor: 1,
        hideLimitLabels: true,
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'model':
              this._siteSettingService.siteWater.CropWettedArea_perc = value;
              //this.sliderWaterWettedAreaDefault = value;
              return value + '%';
            default:
              return value + '%';
          }
        },
      },
      value: 100,
    };

    this.sliderSystemEfficiencyCoefficient = {
      options: {
        ceil: 2,
        disabled: this.waterReadonly || !this.apf.hasSiteSettingsCropCoefficientFull,
        floor: 0,
        hideLimitLabels: false,
        precision: 2 /* decimal places ? */,
        showTicks: 0.25,
        step: 0.01,
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'model':
              this._siteSettingService.siteWater.SystemEfficiencyCoefficient = value;
              return `<b>${this._languageService.instant('COMMON.EFFICIENCY')}:</b> ${(100 * value).toFixed(0)}%`;
            default:
              return (100 * value).toFixed(0) + '%';
          }
        },
      },
      value: this._siteSettingService.siteWater.SystemEfficiencyCoefficient,
    };

    this.sliderEmitterLosses = {
      options: {
        ceil: 2,
        disabled: this.waterReadonly || !this.apf.hasSiteSettingsWaterEmitterLossesFull,
        floor: 0,
        id: 'slider-EmitterLoss',
        precision: 2,
        step: 0.01,
        translate: (value) => {
          if (value <= 0.1) return this._languageService.instant('AC.SETTINGS.LOSS.SUB_DRIP');
          if (value <= 0.33) return this._languageService.instant('AC.SETTINGS.LOSS.SURFACE_DRIP');
          if (value <= 0.65) return this._languageService.instant('AC.SETTINGS.LOSS.POPUP_LG');
          if (value <= 0.95) return this._languageService.instant('AC.SETTINGS.LOSS.POPUP_SM');
          if (value <= 1.25) return this._languageService.instant('AC.SETTINGS.LOSS.PIVOT_LG');
          if (value <= 1.75) return this._languageService.instant('AC.SETTINGS.LOSS.PIVOT_SM');
          return this._languageService.instant('AC.SETTINGS.LOSS.MIST');
        },
      },
      value: this._siteSettingService.siteWater.SprinklerLossConstantA,
    };
  }

  public DisableIrrigatedArea(): boolean {
    const disabled =
      (!this._siteSettingService.isGroupSetting && !this.apf.hasSiteSettingsWaterIrrigatedAreaFull) ||
      (this._siteSettingService.isGroupSetting && !this.apf.hasGroupSettingsWaterIrrigatedAreaFull) ||
      this.waterReadonly;
    return disabled;
  }

  private initSiteDrainageCoefficientSlider() {
    const initValue = this.groupSettingService.siteSoil.DrainageCoefficient ? this.groupSettingService.siteSoil.DrainageCoefficient * 100 : 0.5;

    this.sliderDrainage = {
      options: {
        ceil: 100,
        disabled:  this.soilReadonly || !this.apf.hasSiteSettingsWaterFull,
        floor: 1,
        id: 'slider-DRG',
        precision: 1,
        showSelectionBar: true,
        step: 1,
        onChange: (id, value) => {
          this.groupSettingService.siteSoil.DrainageCoefficient = value / 100;
        },
        translate: (value) => {
          if (value <= 10) return this._languageService.instant('AC.SETTINGS.DRAINAGE.SLOW');
          if (value <= 30) return this._languageService.instant('AC.SETTINGS.DRAINAGE.COMPACT_CLAY');
          if (value <= 33) return this._languageService.instant('AC.SETTINGS.DRAINAGE.HEAVY_CLAY');
          if (value <= 35) return this._languageService.instant('AC.SETTINGS.DRAINAGE.CLAY');
          if (value <= 40) return this._languageService.instant('AC.SETTINGS.DRAINAGE.LOAMY_CLAY');
          if (value <= 50) return this._languageService.instant('AC.SETTINGS.DRAINAGE.LOAM');
          if (value <= 60) return this._languageService.instant('AC.SETTINGS.DRAINAGE.SANDY_LOAM');
          if (value <= 70) return this._languageService.instant('AC.SETTINGS.DRAINAGE.SAND');
          if (value <= 80) return this._languageService.instant('AC.SETTINGS.DRAINAGE.GRAVEL_SAND');
          if (value <= 95) return this._languageService.instant('AC.SETTINGS.DRAINAGE.GRAVEL');
          return this._languageService.instant('AC.SETTINGS.DRAINAGE.RAPID');
        },
      },
      value: initValue,
    };
  }

  private initPenetratingRainFallRange() {
    this.maxPenRainValue_min = this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm
      ? this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm + 1
      : 1;
    this.minPenRainValue_max = this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm
      ? this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm - 1
      : null;
    this.scope.$watch('vm.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm', () => {
      this.maxPenRainValue_min = this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm
        ? this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm + 1
        : 1;
      this.minPenRainValue_max = this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm
        ? this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm - 1
        : null;
    });
    this.scope.$watch('vm.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm', () => {
      this.maxPenRainValue_min = this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm
        ? this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm + 1
        : 1;
      this.minPenRainValue_max = this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm
        ? this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm - 1
        : null;
    });
  }

  private initSiteWorkingDepthSlider() {
    // SOIL MOISTURE working depth slider
    const isDisabled = this.soilReadonly || !this.apf.hasSiteSettingsWaterFull;

    this.sliderWorkingDepth = this.groupSettingService.initWorkingDepthSlider(this.groupSettingService.siteSoil, isDisabled);
  }

  public onChangeIrrigationDay(selectedWaterDay: IWaterDays): void {
    if (this.imuWaterDays.every((s) => !s.isSelected)) {
      this._languageService.warning('AC.SETTINGS.MUST_SELECT_DAY');
    }
    if (this._siteSettingService.siteWater && this._siteSettingService.siteWater) {
      switch (selectedWaterDay.day) {
        case DateUtils.Locale.Weekdays.Long.Monday: {
          this._siteSettingService.siteWater.WaterMonday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Tuesday: {
          this._siteSettingService.siteWater.WaterTuesday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Wednesday: {
          this._siteSettingService.siteWater.WaterWednesday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Thursday: {
          this._siteSettingService.siteWater.WaterThursday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Friday: {
          this._siteSettingService.siteWater.WaterFriday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Saturday: {
          this._siteSettingService.siteWater.WaterSaturday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Sunday: {
          this._siteSettingService.siteWater.WaterSunday = selectedWaterDay.isSelected;
          break;
        }
      }
    }
  }

  private initWaterDays() {
    this.imuWaterDays.forEach((x) => {
      switch (x.day) {
        case DateUtils.Locale.Weekdays.Long.Monday: {
          x.isSelected = this._siteSettingService.siteWater.WaterMonday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Tuesday: {
          x.isSelected = this._siteSettingService.siteWater.WaterTuesday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Wednesday: {
          x.isSelected = this._siteSettingService.siteWater.WaterWednesday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Thursday: {
          x.isSelected = this._siteSettingService.siteWater.WaterThursday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Friday: {
          x.isSelected = this._siteSettingService.siteWater.WaterFriday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Saturday: {
          x.isSelected = this._siteSettingService.siteWater.WaterSaturday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Sunday: {
          x.isSelected = this._siteSettingService.siteWater.WaterSunday;
          break;
        }
      }
    });
  }

  private initWaterInterval() {
    if (this._siteSettingService.siteWater.WaterIntervalFromDayNumber != null) {
      this.imuWaterIntervalStart = this._dayNumberService.convertDayNumberToDate(
        this._siteSettingService.siteWater.WaterIntervalFromDayNumber,
      );
    } else {
      this.imuWaterIntervalStart = null;
    }
    this.imuWaterIntervalMaxDate = moment().toDate();
  }

  // end region

  private initSystemEfficiencyCoefficientSlider() {
    if (!this._siteSettingService.siteWater.SystemEfficiencyCoefficient) {
      this._siteSettingService.siteWater.SystemEfficiencyCoefficient = 1.0; // 0.8;
    }
    if (!this._siteSettingService.siteWater.IrrigationApplicationOneHour_mm) {
      this._siteSettingService.siteWater.IrrigationApplicationOneHour_mm = 50;
    }
  }

  public intervalTypeChanged(): void {
    this._notifyingService.notify('SettingController.siteWater.IrrigationDays', this._siteSettingService.siteWater.IrrigationDays);
  }

  public imuWaterIntervalDateChanged(): void {
    if (this.imuWaterIntervalStart != null) {
      this._siteSettingService.siteWater.WaterIntervalFromDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(
        this.imuWaterIntervalStart,
      );
    } else {
      this._siteSettingService.siteWater.WaterIntervalFromDayNumber = null;
    }
  }

  public updateSiteCropCoeffSettings(name: string, value: number, slopeOption: string): void {
    if (!value) {
      return;
    }

    this._siteSettingService.siteCrop.PhaseName = name;
    this._siteSettingService.siteCrop.PhaseCropCoefficient = value;
    this._siteSettingService.siteCrop.SlopeOption = slopeOption;
    
    this._notifyingService.notify(NotifyEvents.Site.Settings.Crop.DataChanges);
  }

  private initSiteCropStressSliders() {
    // 0=Poor, 1=Average, 2=Good
    this.sliderWSC = {
      options: {
        ceil: 2,
        disabled: this.cropReadonly || !this.apf.hasSiteSettingsCropFull,
        floor: 0,
        hideLimitLabels: true,
        id: 'sliderWSC',
        showTicksValues: true,
        translate: this.groupSettingService.translateTolerance,
        getPointerColor: (value: number) => {
          if (value <= 0) return 'red';
          if (value > 1) return '#2AE02A';
          return '#0DB9F0';
        },
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          if (this._siteSettingService.siteCrop.TolWetSoil != modelValue) this._siteSettingService.siteCrop.TolWetSoil = modelValue;
        },
      },
      value: this._siteSettingService.siteCrop.TolWetSoil,
    };

    this.sliderDSC = {
      options: {
        ceil: 2,
        disabled: this.cropReadonly || !this.apf.hasSiteSettingsCropFull,
        floor: 0,
        hideLimitLabels: true,
        id: 'sliderDSC',
        showTicksValues: true,
        translate: this.groupSettingService.translateTolerance,
        getPointerColor: (value: number) => {
          if (value <= 0) return 'red';
          if (value > 1) return '#2AE02A';
          return '#0DB9F0';
        },
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          if (this._siteSettingService.siteCrop.TolDrySoil != modelValue) this._siteSettingService.siteCrop.TolDrySoil = modelValue;
        },
      },
      value: this._siteSettingService.siteCrop.TolDrySoil,
    };

    this.sliderLAT = {
      options: {
        ceil: 2,
        disabled: this.cropReadonly || !this.apf.hasSiteSettingsCropFull,
        floor: 0,
        hideLimitLabels: true,
        id: 'sliderLAT',
        showTicksValues: true,
        translate: this.groupSettingService.translateTolerance,
        getPointerColor: (value: number) => {
          if (value <= 0) return 'red';
          if (value > 1) return '#2AE02A';
          return '#0DB9F0';
        },
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          if (this._siteSettingService.siteCrop.TolLowTemp != modelValue) this._siteSettingService.siteCrop.TolLowTemp = modelValue;
        },
      },
      value: this._siteSettingService.siteCrop.TolLowTemp,
    };

    this.sliderHAT = {
      options: {
        ceil: 2,
        disabled: this.cropReadonly || !this.apf.hasSiteSettingsCropFull,
        floor: 0,
        hideLimitLabels: true,
        id: 'sliderHAT',
        showTicksValues: true,
        translate: this.groupSettingService.translateTolerance,
        getPointerColor: (value: number) => {
          if (value <= 0) return 'red';
          if (value > 1) return '#2AE02A';
          return '#0DB9F0';
        },
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          if (this._siteSettingService.siteCrop.TolHighTemp != modelValue) this._siteSettingService.siteCrop.TolHighTemp = modelValue;
        },
      },
      value: this._siteSettingService.siteCrop.TolHighTemp,
    };
  }

  private getCropCoefficients(cropId: number) {
    const qryCropCoeffs = new breeze.EntityQuery('CropCoefficient').withParameters({ CropId: cropId });

    return this.entityManager.executeQuery(qryCropCoeffs).then(
      (data) => {
        this.cropCoeffs = data.results as CropCoefficient[];
        this.cropCoeffs = ArrayUtils.sortByNumber(this.cropCoeffs, (x) => x.GrowthPhase);
      },
      (error) => {
        console.error('Error: Could not load CropCoefficients entity (' + error.message + ')');
      },
    );
  }

  private initialiseCropCoefficients() {
    this.getCropCoefficients(this._siteSettingService.siteCrop.CropId).then(() => {
      const tempArray: ISiteCropCoeff[] = [];
      const crop = this.crops.find((c) => c.Id == this._siteSettingService.siteCrop.CropId);
      const budgetStart = crop != null && crop.BudgetStartDate != null ? moment(crop.BudgetStartDate).toDate() : null;
      const phaseStart: Date =
        crop != null && crop.BudgetStartDate != null
          ? new Date(budgetStart.getUTCFullYear(), budgetStart.getUTCMonth(), budgetStart.getUTCDate())
          : null;
      for (let idx = 0; idx < this.cropCoeffs.length; idx++) {
        let phaseStr: string = null;
        if (phaseStart != null && this.cropCoeffs[idx].GrowthPhaseDuration > 0) {
          const phaseDates: { from: Date; to: Date } = {
            from: moment(phaseStart).toDate(),
            to: moment(phaseStart)
              .add(this.cropCoeffs[idx].GrowthPhaseDuration - 1, 'days')
              .toDate(),
          };
          phaseStr = moment(phaseDates.from).isSame(phaseDates.to)
            ? moment(phaseDates.from).format('D MMM')
            : moment(phaseDates.from).format('D MMM') + ' - ' + moment(phaseDates.to).format('D MMM');
          phaseStart.addDays(this.cropCoeffs[idx].GrowthPhaseDuration);
        }
        const sccRec: ISiteCropCoeff = {
          id: this.cropCoeffs[idx].GrowthPhase,
          name: this.cropCoeffs[idx].Description,
          value: this.cropCoeffs[idx].WaterCoefficient,
          duration: this.cropCoeffs[idx].GrowthPhaseDuration,
          slopeOption: this.cropCoeffs[idx].SlopeOption == null ? 'F' : this.cropCoeffs[idx].SlopeOption,
          phaseDates: phaseStr,
        };
        tempArray.push(sccRec);
      }
      this.siteCropCoeffsCurrent = ArrayUtils.sortByNumber(tempArray, (x) => x.id);
    });
  }

  public siteCropChanged(setDefault: boolean) {
    this._siteSettingService.siteCrop.CropGrowthPhase = 0;
    // get the default crop coeffs
    this.initialiseCropCoefficients();
    if (setDefault) {
      // get the default stress factors
      const cropDetail = this.crops.find((a) => a.Id == this._siteSettingService.siteCrop.CropId);
      this._siteSettingService.siteCrop.TolWetSoil = cropDetail.TolWet;
      this._siteSettingService.siteCrop.TolDrySoil = cropDetail.TolDry;
      this._siteSettingService.siteCrop.TolLowTemp = cropDetail.TolCold;
      this._siteSettingService.siteCrop.TolHighTemp = cropDetail.TolHeat;

      this._siteSettingService.siteCrop.PhaseName =
        cropDetail.CropCoefficients.length ? cropDetail.CropCoefficients[0].Description.toString() : '';
      this._siteSettingService.siteCrop.SlopeOption =
        cropDetail.CropCoefficients.length ? cropDetail.CropCoefficients[0].SlopeOption : 'F';
      this._siteSettingService.siteCrop.PhaseCropCoefficient =
        cropDetail.CropCoefficients.length ? cropDetail.CropCoefficients[0].WaterCoefficient : null;
    }
  }

  public convertJSOnToArray(jsonstring: string): string {
    if (!jsonstring || jsonstring === '') return '';
    let temp: any[] = [];
    let result: string = '';
    // only used for html display, so wrap in a try - finally
    // to allow unexpected data to return without console errors
    try {
      temp = angular.fromJson(jsonstring);
      //            let i: number = 1;
      temp.forEach((entry) => {
        if (result != '') {
          result += ', ';
        }
        if ('SamplePoint' in entry && 'Percentage' in entry) {
          result += entry.SamplePoint.Name + ' - ' + entry.Percentage;
        } else {
          result += entry.name + ' - ' + entry.value;
        }
      });
      return result;
    } catch {
      return '';
    }
  }

  // If only 1 effective date record, it cannot be changed to greater than Todays date.
  // an 'effective date record' is one that starts today or earlier
  public maxSettingDate(row, ssArray): Date {
    // ssArray is one of siteCrop, siteSoil. etc.
    // row is the record in the array which is to be edited
    if (ssArray == undefined || ssArray == null) {
      return null;
    }

    const candidateRecs: number[] = [];
    for (let idx = 0; idx < ssArray.length; idx++) {
      if (ssArray[idx].localDate < this.adjustedToday) {
        candidateRecs.push(idx); // build an array of the (index of the) rows which may become effective
      }
    }

    // if there is only one candidate effective row, AND its the row of interest - dont allow a future date to be set
    if (candidateRecs.length == 1 && ssArray[candidateRecs[0]].Id == row.Id) {
      return this.adjustedToday;
    } else {
      return null; // no effective max-date limit
    }
  }

  public onTabSelected_SettingsCrop() {
    if (!this.crops.length) {
      const qryCrops = new breeze.EntityQuery('Crop').withParameters({ accountId: this.accountId }).expand('CropCoefficients');
      this.entityManager.executeQuery(qryCrops).then(
        (data) => {
          this.crops = data.results as Crop[];
        },
        (error) => {
          console.error('Error: Could not load Crops entity (' + error.message + ')');
        },
      );
    }
  }

  public onTabSelected_SettingsNutrients() {
    if (!this.samplePoints.length) {
      const pred = breeze.Predicate.create('AssetClassId', breeze.FilterQueryOp.Equals, 13).and(
        'Status',
        breeze.FilterQueryOp.Equals,
        'Active',
      );

      breeze.EntityQuery.from('AccountAssets')
        .withParameters({ accountId: this.accountId })
        .where(pred)
        .select(['Name', 'AssetId'])
        .using(this.entityManager)
        .execute()
        .then(
          (data) => {
            this.samplePoints = data.results as Asset[];
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }
}

angular.module('app.account').controller('SiteSettingsController', SiteSettingsController);
