import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class SiteAreaDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public area: number;
  public applyToIrrigatedArea: boolean;
  public applyToFertiliserArea: boolean;
  public applyToFutureSettings: boolean = null;
  public areaNormalUnit: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    area: number,
    irrigatedAreaSelected: boolean,
    fertiliserAreaSelected: boolean,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.area = area;
    this.applyToIrrigatedArea = irrigatedAreaSelected;
    this.applyToFertiliserArea = fertiliserAreaSelected;
    this.areaNormalUnit = UnitOfMeasureService.getUnitLabel('Area', unitSizes.normal);
  }

  public applySettings() {
    const settings = {
      applyToFertiliserArea: this.applyToFertiliserArea,
      applyToIrrigatedArea: this.applyToIrrigatedArea,
      area: this.area,
      applyToFutureSettings: this.applyToFutureSettings,
    } as fuse.SiteAreaDto;
    this._mdDialog.hide(settings);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public setEnabled(): boolean {
    return this.applyToIrrigatedArea || this.applyToFertiliserArea;
  }
}

angular.module('app.account').controller('SiteAreaDialogController', SiteAreaDialogController);
