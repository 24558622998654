import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { IIdValueItem } from '@common/models/interfaces';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { uomUnit, UnitOfMeasureService } from '@services/unit-of-measure.service';
import { WaterConstants } from 'src/app/pages/water/WaterConstants';
import { BaseController } from 'src/app/base.controller';

export class AddWeatherStationDialogController extends BaseController {
  public weatherStations = [] as fuse.weatherStationDto[];
  public maxStationDistance: number;
  public lengthLargeUnit: uomUnit;
  public weatherStationDistances: IIdValueItem[];
  public milesDisplayValues = WaterConstants.milesDisplayValues;
  public metricDisplayValues = WaterConstants.metricDisplayValues;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  private siteId: number;
  private existWeatherStationIds: number[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    siteId: number,
    existWeatherStationIds: number[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._dayNumberService = DayNumberService;

    this.siteId = siteId;
    this.existWeatherStationIds = existWeatherStationIds;

    this.lengthLargeUnit = UnitOfMeasureService.getUnits('Length', unitSizes.large);
  }

  $onInit() {
    this.weatherStationDistances = [];

    if (this.lengthLargeUnit.name === 'mi') {
      this.milesDisplayValues.forEach((val) => this.weatherStationDistances.push({ id: val, value: this.lengthLargeUnit.toBase(val) }));
    } else if (this.lengthLargeUnit.name === 'km') {
      this.metricDisplayValues.forEach((val) => this.weatherStationDistances.push({ id: val, value: val }));
    } else {
      this.metricDisplayValues.forEach((val) =>
        this.weatherStationDistances.push({ id: this.lengthLargeUnit.fromBase(val), value: val }),
      );
    }

    this.maxStationDistance = this.weatherStationDistances[0].value;

    this.refreshWeatherStations();
  }

  public refreshWeatherStations() {
    const adjustedTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    const body = {
      siteId: this.siteId,
      maxStationDistance: Math.round(this.maxStationDistance),
    } as fuse.selectWeatherStationDto;

    this._http.put(CommonHelper.getApiUrl('site/getNearbyWeatherStations'), body).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.weatherStations = response.data as fuse.weatherStationDto[];
          this.weatherStations.forEach((weatherStation) => {
            if (weatherStation.latestObsWeatherDayNumber != null) {
              weatherStation.latestObsWeatherDate = this._dayNumberService.convertDayNumberToLocaleDate(
                weatherStation.latestObsWeatherDayNumber,
              );

              if (adjustedTodayDayNumber - weatherStation.latestObsWeatherDayNumber < 4) {
                weatherStation.isValidObsWeather = true;
              }
            }

            if (weatherStation.latestObsEtoDayNumber != null) {
              weatherStation.latestObsEtoDate = this._dayNumberService.convertDayNumberToLocaleDate(
                weatherStation.latestObsEtoDayNumber,
              );

              if (adjustedTodayDayNumber - weatherStation.latestObsEtoDayNumber < 8) {
                weatherStation.isValidObsEto = true;
              }
            }

            if (weatherStation.latestObsHumidityDayNumber != null) {
              weatherStation.latestObsHumidityDate = this._dayNumberService.convertDayNumberToLocaleDate(
                weatherStation.latestObsHumidityDayNumber,
              );

              if (adjustedTodayDayNumber - weatherStation.latestObsHumidityDayNumber < 8) {
                weatherStation.isValidObsHumidity = true;
              }
            }

            if (weatherStation.latestObsSolarRadiationDayNumber != null) {
              weatherStation.latestObsSolarRadiationDate = this._dayNumberService.convertDayNumberToLocaleDate(
                weatherStation.latestObsSolarRadiationDayNumber,
              );

              if (adjustedTodayDayNumber - weatherStation.latestObsSolarRadiationDayNumber < 8) {
                weatherStation.isValidObsSolarRadiation = true;
              }
            }

            if (weatherStation.latestObsRainfallDayNumber != null) {
              weatherStation.latestObsRainfallDate = this._dayNumberService.convertDayNumberToLocaleDate(
                weatherStation.latestObsRainfallDayNumber,
              );

              if (adjustedTodayDayNumber - weatherStation.latestObsRainfallDayNumber < 8) {
                weatherStation.isValidObsRainfall = true;
              }
            }

            if (weatherStation.latestObsTemperatureDayNumber != null) {
              weatherStation.latestObsTemperatureDate = this._dayNumberService.convertDayNumberToLocaleDate(
                weatherStation.latestObsTemperatureDayNumber,
              );

              if (adjustedTodayDayNumber - weatherStation.latestObsTemperatureDayNumber < 8) {
                weatherStation.isValidObsTemperature = true;
              }
            }

            if (weatherStation.latestObsWindspeedDayNumber != null) {
              weatherStation.latestObsWindspeedDate = this._dayNumberService.convertDayNumberToLocaleDate(
                weatherStation.latestObsWindspeedDayNumber,
              );

              if (adjustedTodayDayNumber - weatherStation.latestObsWindspeedDayNumber < 8) {
                weatherStation.isValidObsWindspeed = true;
              }
            }

            if (this.existWeatherStationIds.some((a) => a == weatherStation.assetId)) {
              weatherStation.isExisted = true;
              weatherStation.isSelected = true;
            }
          });

          if (!this.weatherStations.length) {
            this._languageService.warning('AC.SITE.MSG.NO_STATION_FOUND');
          }
        }
      },
      () => {},
    );
  }

  public updateWeatherStations() {
    this._mdDialog.hide({ weatherStations: this.weatherStations.filter((a) => a.isExisted == false && a.isSelected == true) });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.account').controller('AddWeatherStationDialogController', AddWeatherStationDialogController);
