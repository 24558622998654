// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#site-settings-weather table tbody tr:nth-child(odd) {
  background-color: aliceblue;
}
#site-settings-weather table {
  table-layout: fixed;
  min-width: 1000px;
}
#site-settings-weather table thead th {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
#site-settings-weather table tbody td {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
#site-settings-weather .station-name {
  width: 25%;
}
#site-settings-weather .station-value {
  width: 100px;
}
#site-settings-weather md-input-container {
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}
#site-settings-weather md-input-container .md-errors-spacer {
  min-height: 0px !important;
}
#site-settings-weather .input-textbox {
  width: 50px;
  text-align: center;
  min-width: 40px;
  align-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/site/settingsTabs/weather/site-settings-weather.scss"],"names":[],"mappings":"AAGY;EACI,2BAAA;AAFhB;AAMI;EACI,mBAAA;EACA,iBAAA;AAJR;AAMY;EACI,SAAA;EACA,eAAA;EACA,gBAAA;AAJhB;AAQY;EACI,SAAA;EACA,eAAA;EACA,gBAAA;AANhB;AAUI;EACI,UAAA;AARR;AAUI;EACI,YAAA;AARR;AAUI;EACI,SAAA;EACA,UAAA;EACA,sBAAA;AARR;AASQ;EAEI,0BAAA;AARZ;AAWI;EACI,WAAA;EACA,kBAAA;EACA,eAAA;EACA,qBAAA;AATR","sourcesContent":["#site-settings-weather {\r\n    table {\r\n        tbody {\r\n            tr:nth-child(odd) {\r\n                background-color: aliceblue;\r\n            }\r\n        }\r\n    }\r\n    table {\r\n        table-layout: fixed;\r\n        min-width: 1000px;\r\n        thead {\r\n            th {\r\n                margin: 0;\r\n                padding-left: 0;\r\n                padding-right: 0;\r\n            }\r\n        }\r\n        tbody {\r\n            td {\r\n                margin: 0;\r\n                padding-left: 0;\r\n                padding-right: 0;\r\n            }\r\n        }\r\n    }\r\n    .station-name {\r\n        width: 25%;\r\n    }\r\n    .station-value {\r\n        width: 100px;\r\n    }\r\n    md-input-container {\r\n        margin: 0;\r\n        padding: 0;\r\n        vertical-align: bottom;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }\r\n    }\r\n    .input-textbox {\r\n        width: 50px;\r\n        text-align: center;\r\n        min-width: 40px;\r\n        align-content: center;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
