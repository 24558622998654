import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class NominateHealthAssetsDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public selectedAssets: fuse.siteHealthAssetProfileDto[] = [];
  public unselectedAssets: fuse.siteHealthAssetProfileDto[] = [];
  public searchTerm = '';
  public isAllUnselectedAssetsChecked = false;
  public isAllSelectedAssetsChecked = false;
  public isAssetMoved = false;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    selectedHealthAssets: fuse.siteHealthAssetProfileDto[],
    unselectedHealthAssets: fuse.siteHealthAssetProfileDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.selectedAssets = angular.copy(selectedHealthAssets);
    this.unselectedAssets = angular.copy(unselectedHealthAssets);
  }

  public pushSelectedAssets(): void {
    const movedAssets = this.unselectedAssets.filter(
      (a) => a.isChecked && a.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
    );

    if (movedAssets.length) {
      this.unselectedAssets = this.unselectedAssets.filter((a) => movedAssets.some((b) => b.name == a.name) == false);
      movedAssets.forEach((a) => (a.isChecked = false));
      this.selectedAssets.push(...movedAssets);
      this.itemChanged();
      this.isAssetMoved = true;
    }
  }

  public popSelectedAssets(): void {
    const movedAssets = this.selectedAssets.filter((a) => a.isChecked);
    if (movedAssets.length) {
      this.selectedAssets = this.selectedAssets.filter((a) => a.isChecked == false);
      movedAssets.forEach((a) => (a.isChecked = false));
      this.unselectedAssets.push(...movedAssets);
      this.itemChanged();
      this.isAssetMoved = true;
    }
  }

  public selectAllUnselected(): void {
    this.unselectedAssets.forEach((a) => (a.isChecked = true));
    this.itemChanged();
  }

  public selectAllSelected(): void {
    this.selectedAssets.forEach((a) => (a.isChecked = true));
  }

  public updateHealthAssets() {
    this._mdDialog.hide({ selectedHealthAssets: this.selectedAssets });
    this.itemChanged();
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public itemChanged(): void {
    if (this.selectedAssets.length) {
      this.isAllSelectedAssetsChecked = this.selectedAssets.some((a) => a.isChecked != true) == false;
    } else {
      this.isAllSelectedAssetsChecked = false;
    }
    if (this.unselectedAssets.length) {
      this.isAllUnselectedAssetsChecked = this.unselectedAssets.some((a) => a.isChecked != true) == false;
    } else {
      this.isAllUnselectedAssetsChecked = false;
    }
  }
}

angular.module('app.account').controller('NominateHealthAssetsDialogController', NominateHealthAssetsDialogController);
