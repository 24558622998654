import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';

export class AddKcviUseDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  public siteKcviUses: fuse.siteKcviUseDto[];
  public fromDate: Date;
  public endDate: Date;
  public minDate = SWANConstants.MinDate;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    siteKcviUses: fuse.siteKcviUseDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.siteKcviUses = siteKcviUses;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public addKcviUse() {
    const fromDayNumber = this._dayNumberService.convertCalendarDateToLocaleDayNumber(this.fromDate);
    const endDayNumber = this._dayNumberService.convertCalendarDateToLocaleDayNumber(this.endDate);
    for (let i = fromDayNumber; i <= endDayNumber; i++) {
      if (this.siteKcviUses.some((a) => a.fromDayNumber <= i && i <= a.endDayNumber)) {
        this._languageService.error('AC.SITE.MSG.DATE_INTERVAL_OVERLAP');
        return;
      }
    }
    const newKcviUse = {
      fromDayNumber: fromDayNumber,
      fromLocaleDate: this.fromDate,
      endDayNumber: endDayNumber,
      endLocaleDate: this.endDate,
    } as fuse.siteKcviUseDto;
    this._mdDialog.hide({ newKcviUse: newKcviUse });
  }
}

angular.module('app.account').controller('AddKcviUseController', AddKcviUseDialogController);
