import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AboutKcviDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.account').controller('AboutKcviDialogController', AboutKcviDialogController);
