import * as angular from 'angular';
import * as moment from 'moment';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { SWANConstants } from '@common/SWANConstants';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';

export interface KCRolloverSettings {
  dateFrom: Date;
  dateTo: Date;
  targetYear: number;
  rolloverWorkingDepths: boolean;
}

export class SiteRolloverDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _localStarageService: LocalStorageService;

  public rolloverFromDate: Date;
  public rolloverEndDate: Date;
  public rolloverMaximumDate: Date;
  public targetYear: number;
  public isWithWorkingDepth: boolean;
  public minimumYear: number;
  public minDate = SWANConstants.MinDate;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._localStarageService = LocalStorageService;

    this.minimumYear = new Date().getFullYear() - 1;
  }

  public fromSelected() {
    if (this.rolloverEndDate != null && this.rolloverEndDate <= this.rolloverFromDate) {
      this.rolloverEndDate = moment(this.rolloverFromDate).toDate().addDays(1);
    }
    this.rolloverMaximumDate = moment(this.rolloverFromDate).toDate().addYears(1).addDays(-1);

    if (this.rolloverEndDate != null && this.rolloverEndDate > this.rolloverMaximumDate) {
      this.rolloverEndDate = this.rolloverMaximumDate;
    }
  }

  public rolloverEnabled(): boolean {
    return (
      this.rolloverFromDate != null &&
      this.rolloverEndDate != null &&
      this.targetYear != this.rolloverFromDate.getFullYear() &&
      this.targetYear != null &&
      this.targetYear >= this.minimumYear
    );
  }

  public rollover() {
    const fromDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber(this.rolloverFromDate);
    const endDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber(this.rolloverEndDate);

    const context = LocalStorageUtils.contextData;
    const settings = {
      fromDayNumber: fromDayNumber,
      fromDate: this.rolloverFromDate,
      endDayNumber: endDayNumber,
      endDate: this.rolloverEndDate,
      targetYear: this.targetYear,
      isWithWorkingDepth: this.isWithWorkingDepth,
      siteId: context.siteId,
    } as fuse.siteCropSettingsRolloverDto;

    this._mdDialog.hide(settings);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.account').controller('SiteRolloverDialogController', SiteRolloverDialogController);
